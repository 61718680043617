import { Route, Routes } from "react-router-dom";
import { useAdvancedMode } from "../../common/hooks";
import { Error404Page } from "../../components/Error404Page/Error404Page";
import { Automate } from "./botifyAnalytics/automate";
import { ImportCrawlData } from "./botifyAnalytics/import";
import { CSVUrls } from "./csv/Urls";
import { LinksDiscoveryUrls } from "./linksdiscovery/Urls";
import { UpdateInputList } from "./list";
import { TextListUrls } from "./list/Urls";
import { SitemapUrls } from "./sitemap/Urls";

export function InputsRoutes() {
  const { isAdvancedMode } = useAdvancedMode();

  return (
    <Routes>
      <Route
        path="botifyAnalytics/*"
        element={<StandardModeInputsRoutes />}
      ></Route>
      {isAdvancedMode ? (
        <Route path="/*" element={<AdvanceModeInputsRoutes />} />
      ) : (
        <Route path="*" element={<Error404Page />}></Route>
      )}
    </Routes>
  );
}

function StandardModeInputsRoutes() {
  return (
    <Routes>
      <Route path="import" element={<ImportCrawlData />} />
      <Route path="automate" element={<Automate />} />
      <Route path="*" element={<Error404Page />}></Route>
    </Routes>
  );
}

function AdvanceModeInputsRoutes() {
  return (
    <Routes>
      <Route path="lists/:inputId" element={<UpdateInputList />} />
      <Route
        path="linksDiscovery/:inputId/urls"
        element={<LinksDiscoveryUrls />}
      />
      <Route path="textList/:inputId/urls" element={<TextListUrls />} />
      <Route path="sitemap/:inputId/urls" element={<SitemapUrls />} />
      <Route path="csv/:inputId/urls" element={<CSVUrls />} />
    </Routes>
  );
}
