import { useMutation, useQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import { useNavigate, useParams } from "react-router-dom";
import { excludeNullItems } from "../../common/typings";
import {
  GET_INPUT_TEXT_LIST,
  UpdateInputTextListMutation,
  UpdateInputTextListMutationVariables,
  UPDATE_INPUT_TEXT_LIST,
  WebsiteInputTextListQuery,
  WebsiteInputTextListQueryVariables,
} from "../../gql/inputs";
import { ErrorType, InputTextListType, Maybe } from "../../gql/types.generated";
import { ErrorBanner } from "../components/feedback";
import { UrlsListFormGroup } from "../components/form";
import { BreadcrumbTitle } from "../components/header/Title";
import { InputIdParams, WebsiteIdParams } from "../types/routeParams";

export function UpdateInputList() {
  const { websiteId, inputId } = useParams<
    WebsiteIdParams & InputIdParams
  >() as WebsiteIdParams & InputIdParams;
  const navigate = useNavigate();
  const {
    loading: loadingQuery,
    error: queryError,
    data,
  } = useQuery<WebsiteInputTextListQuery, WebsiteInputTextListQueryVariables>(
    GET_INPUT_TEXT_LIST,
    {
      variables: { id: inputId },
    }
  );

  const inputTextList = data?.inputTextList;

  const [
    updateSettings,
    { loading: loadingUpdate, error: updateError, data: updatedData },
  ] = useMutation<
    UpdateInputTextListMutation,
    UpdateInputTextListMutationVariables
  >(UPDATE_INPUT_TEXT_LIST, {
    update(_, { data: updatedData }) {
      if (!inputTextList || updatedData?.updateInputTextList?.errors?.length) {
        return;
      }

      window.scrollTo(0, 0);
      navigate(
        {
          pathname: `/website/${websiteId}`,
        },
        {
          state: {
            message: {
              value: `Source List ${inputTextList.name} saved`,
              status: "success",
            },
          },
        }
      );
    },
  });

  const handleSubmit: IUrlsListForm["onSubmit"] = ({ name, urls }) => {
    updateSettings({
      variables: {
        input: { id: inputId, name, urls },
      },
    });
  };

  const { error, errorMessage } = handleErrors({
    queryError,
    updateError,
    dataErrors: updatedData?.updateInputTextList?.errors,
  });
  const showForm = !queryError && !loadingQuery && !!inputTextList?.website;

  return (
    <div>
      {inputTextList && (
        <BreadcrumbTitle
          website={inputTextList.website}
          steps={["Sources", "Lists", inputTextList.name]}
        />
      )}
      {loadingQuery && <Spinner animation="border" />}
      {error && <ErrorBanner error={error} message={errorMessage} />}
      {showForm && (
        <UrlsListForm
          initialValue={inputTextList}
          onSubmit={handleSubmit}
          loading={loadingUpdate}
        />
      )}
    </div>
  );
}

interface IInputTextListFields
  extends Pick<InputTextListType, "name" | "urls"> {}

interface IUrlsListForm {
  initialValue: IInputTextListFields;
  onSubmit: (inputTextList: IInputTextListFields) => void;
  loading: boolean;
}

const UrlsListForm = ({ initialValue, onSubmit, loading }: IUrlsListForm) => {
  const [inputTextList, setInputTextList] = useState(initialValue);

  return (
    <UncontrolledUrlsListForm
      inputTextList={inputTextList}
      onChange={setInputTextList}
      onSubmit={() => onSubmit(inputTextList)}
      loading={loading}
    />
  );
};

interface IUncontrolledUrlsListForm {
  inputTextList: IInputTextListFields;
  onChange: (inputTextList: IInputTextListFields) => void;
  onSubmit: () => void;
  loading: boolean;
}

const UncontrolledUrlsListForm = ({
  inputTextList,
  onChange,
  onSubmit,
  loading,
}: IUncontrolledUrlsListForm) => {
  const { urls: listUrls, name: listName } = inputTextList;
  const [isUrlsListValid, setIsUrlsListValid] = useState(listUrls !== "");
  const isValid = listName !== "" && isUrlsListValid;

  const handleChange =
    (key: keyof typeof inputTextList) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onChange({ ...inputTextList, [key]: event.target.value });

  return (
    <Card>
      <Card.Header>Main Informations</Card.Header>
      <Card.Body>
        <Form.Group controlId="listName">
          <Form.Label>Source Name</Form.Label>
          <Form.Control
            type="text"
            value={listName}
            onChange={handleChange("name")}
            placeholder="e.g. Product Urls"
            required
          />
        </Form.Group>

        <UrlsListFormGroup
          id="urlsList"
          label="URLs"
          value={listUrls}
          onChange={(event, errors) => {
            handleChange("urls")(event);
            setIsUrlsListValid(errors.length === 0);
          }}
          required
        />

        <Button
          type="submit"
          className="float-right"
          onClick={onSubmit}
          disabled={!isValid || loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
        </Button>
      </Card.Body>
    </Card>
  );
};

interface IHandleErrorsOptions {
  queryError?: ApolloError;
  createError?: ApolloError;
  updateError?: ApolloError;
  dataErrors?: Maybe<Maybe<ErrorType>[]>;
}

const handleErrors = ({
  queryError,
  createError,
  updateError,
  dataErrors,
}: IHandleErrorsOptions) => {
  const apolloError = queryError ?? createError ?? updateError;
  const filteredDataErrors = excludeNullItems(dataErrors);
  const sanitizedDataErrors = filteredDataErrors?.length
    ? filteredDataErrors
    : undefined;
  const error = apolloError ?? sanitizedDataErrors;

  const getActionType = () => {
    if (queryError) return "querying";
    if (createError) return "creating";
    return "updating";
  };

  const errorMessage = error ? `Error ${getActionType()} input list` : "";

  return {
    error,
    errorMessage,
  };
};
