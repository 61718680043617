import { useQuery, type ApolloError } from "@apollo/client";
import { useParams } from "react-router-dom";
import { JsCodeType } from "../../../gql/types.generated";
import { GET_WEBSITE_SCRIPTS } from "../../../gql/websites/scripts/getWebsiteScripts";
import {
  WebsiteScriptsQuery,
  WebsiteScriptsQueryVariables,
} from "../../../gql/websites/scripts/getWebsiteScripts.generated";
import { WebsiteIdParams } from "../../types/routeParams";

export const useScriptsQuery = ({
  onError,
}: { onError?: (error: ApolloError) => void } = {}) => {
  const websiteId = useParams<WebsiteIdParams>().websiteId!;
  const { data, error, loading } = useQuery<
    WebsiteScriptsQuery,
    WebsiteScriptsQueryVariables
  >(GET_WEBSITE_SCRIPTS, { variables: { websiteId }, onError });

  const website = data?.website;
  const scripts = (website?.draftVersion?.jsCodes ?? []) as JsCodeType[];
  const luConfigs = data?.liveupdate.configurations ?? [];

  return {
    website,
    scripts,
    luConfigs,
    error,
    loading,
  };
};

export const useScriptByIdQuery = (scriptId: JsCodeType["id"]) => {
  const { scripts, ...rest } = useScriptsQuery();
  const script = scripts.find(({ id }) => id === scriptId) ?? null;
  return { script, ...rest };
};
