export const botsRegex = {
  allShards: [
    "amazonbot/",
    "anthropic-ai",
    "applebot/",
    "baiduspider/",
    "baiduspider+",
    "bingbot/",
    "botify-bot-sw-",
    "bytespider",
    "ccbot/",
    "chatgpt-user/",
    "claude-web",
    "claudebot/",
    "facebookbot/",
    "facebookcatalog/",
    "facebookexternalhit/",
    "googlebot/",
    "googlebot-image/",
    "google-inspectiontool/",
    "googleother",
    "gptbot/",
    "oai-searchbot/",
    "perplexitybot/",
    "prerender",
    "yandexbot/",
    "yandexmobilebot/",
    "yeti/",
    "youbot/",
  ],
  /** Joins all shards into a single regex string */
  fromShards(shards: string[]) {
    return shards.join("|");
  },
  /** Split a single regex string into multiple shards */
  toShards(regex?: string) {
    if (!regex) return [];
    return regex.split("|");
  },
};
