export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  BigIntStr: { input: string; output: string };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Decimal: { input: string; output: string };
  GenericScalar: { input: unknown; output: unknown };
  JSONString: { input: string; output: string };
  Time: { input: any; output: any };
  UUID: { input: any; output: any };
};

export type AdnConfigStatusType = {
  __typename?: "ADNConfigStatusType";
  adnConfig: AdnConfigType;
  author?: Maybe<UserType>;
  comment: Scalars["String"]["output"];
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  environment: AdnEnvironmentChoices;
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  previousAdnConfigStatus?: Maybe<AdnConfigStatusType>;
  status: AdnConfigStatusStatus;
  statusMessage: Scalars["String"]["output"];
};

export type AdnConfigStatusTypePreviousAdnConfigStatusArgs = {
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type AdnConfigStatusesByEnvType = {
  __typename?: "ADNConfigStatusesByEnvType";
  adnConfigStatuses?: Maybe<Array<AdnConfigStatusType>>;
  environment: AdnEnvironment;
};

export type AdnConfigType = {
  __typename?: "ADNConfigType";
  adnConfigStatuses: Array<AdnConfigStatusType>;
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  customerOriginPort?: Maybe<Scalars["Int"]["output"]>;
  /** Timeout in milliseconds */
  customerOriginTimeout: Scalars["Int"]["output"];
  /** The origin URL field has to be filled when the request isn't sent to the customer CDN but to the customer origin directly.  */
  customerOriginUrl: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  deployTests?: Maybe<Array<Scalars["String"]["output"]>>;
  failoverHostHeader: Scalars["String"]["output"];
  failoverRequestHeaders: Scalars["String"]["output"];
  failoverRequestHeadersFilters: Scalars["String"]["output"];
  failoverResponseHeaders: Scalars["String"]["output"];
  /** The User-Agent to use when the request is sent back to the customer CDN (the request should not be intercepted by SpeedWorkers or we will end up with an infinite loop). No User-Agent is necessary if the fallback request is sent directly to the customer origin (because in this case, the request doesn't go back to the customer CDN). */
  failoverUserAgent: Scalars["String"]["output"];
  gdprModeEnabled: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  /** Ignore paths that match the pattern (the matching is done on the path, not on the querystring) */
  ignoredPathsRegex: Scalars["String"]["output"];
  interceptedBotsRegex: Scalars["String"]["output"];
  isDraft: Scalars["Boolean"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  swOriginRewrite: Scalars["String"]["output"];
  /** Timeout in milliseconds */
  swOriginTimeout: Scalars["Int"]["output"];
  swRealOrigin: Scalars["String"]["output"];
  /** The absolute URL to use to test the lightweight CDN setup. */
  urlCacheHit: Scalars["String"]["output"];
  verifiedBotsEnabled: Scalars["Boolean"]["output"];
  website: WebsiteType;
  workerVersion: Scalars["String"]["output"];
};

export enum AdnEnvironmentChoices {
  /** Production */
  Production = "PRODUCTION",
  /** Staging */
  Staging = "STAGING",
  /** Test */
  Test = "TEST",
}

export type AdnVersionsType = {
  __typename?: "ADNVersionsType";
  version: Scalars["String"]["output"];
};

/** An enumeration. */
export enum ActionChoices {
  DoNothing = "DO_NOTHING",
  Html = "HTML",
  Js = "JS",
}

export enum ActionTypeEnum {
  Add = "ADD",
  Delete = "DELETE",
}

export enum AdnConfigStatusStatus {
  /** Deployed */
  Deployed = "DEPLOYED",
  /** Deploying */
  Deploying = "DEPLOYING",
  /** Early failed */
  EarlyFailed = "EARLY_FAILED",
  /** Failed */
  Failed = "FAILED",
  /** Ready to deploy */
  ReadyToDeploy = "READY_TO_DEPLOY",
  /** Rolling back */
  RollingBack = "ROLLING_BACK",
}

/** An enumeration. */
export enum AdnEnvironment {
  Production = "production",
  Staging = "staging",
  Test = "test",
}

export type AllTimeConfigType = {
  __typename?: "AllTimeConfigType";
  name?: Maybe<Scalars["String"]["output"]>;
  stableId?: Maybe<Scalars["String"]["output"]>;
};

export type AllTimeInputType = {
  __typename?: "AllTimeInputType";
  inputType?: Maybe<Scalars["String"]["output"]>;
  lastVersion?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  stableId?: Maybe<Scalars["String"]["output"]>;
};

export type AllTimeSectionType = {
  __typename?: "AllTimeSectionType";
  lastVersion?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  stableId?: Maybe<Scalars["String"]["output"]>;
};

export type AnalyzeSectionRefreshImpactMutation = {
  __typename?: "AnalyzeSectionRefreshImpactMutation";
  errors?: Maybe<Array<ForceRefreshErrorType>>;
  estimatedDelayRecoveryDuration?: Maybe<Scalars["Int"]["output"]>;
  estimatedNbOfDelayedPages?: Maybe<Scalars["Int"]["output"]>;
  estimatedRefreshDuration?: Maybe<Scalars["Int"]["output"]>;
  /** Token which allows to call the forceSectionRefresh mutation. Provided when impact level is low enough. */
  forceRefreshToken?: Maybe<Scalars["String"]["output"]>;
  impactLevel?: Maybe<ImpactLevel>;
  maxAllowedRefreshDuration?: Maybe<Scalars["Int"]["output"]>;
  maxAllowedUrlsToRefresh?: Maybe<Scalars["Int"]["output"]>;
  nbOfUrlsToRefresh?: Maybe<Scalars["Int"]["output"]>;
  totalInventoryUrls?: Maybe<Scalars["Int"]["output"]>;
};

export type AnalyzeUrLsRefreshImpactMutation = {
  __typename?: "AnalyzeURLsRefreshImpactMutation";
  errors?: Maybe<Array<ForceRefreshErrorType>>;
  estimatedDelayRecoveryDuration?: Maybe<Scalars["Int"]["output"]>;
  estimatedNbOfDelayedPages?: Maybe<Scalars["Int"]["output"]>;
  estimatedRefreshDuration?: Maybe<Scalars["Int"]["output"]>;
  /** Token which allows to call the forceSectionRefresh mutation. Provided when impact level is low enough. */
  forceRefreshToken?: Maybe<Scalars["String"]["output"]>;
  impactLevel?: Maybe<ImpactLevel>;
  maxAllowedUrlsToRefresh?: Maybe<Scalars["Int"]["output"]>;
  nbOfUrlsToRefresh?: Maybe<Scalars["Int"]["output"]>;
  totalInventoryUrls?: Maybe<Scalars["Int"]["output"]>;
};

export type AnnotationType = {
  __typename?: "AnnotationType";
  active: Scalars["Boolean"]["output"];
  createdBy: UserType;
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  date: Scalars["Date"]["output"];
  dateCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  website: WebsiteType;
};

/**
 * `ArchiveModuleMutation` archives a module, identified by its `stable_id`, by:
 * - Setting the `version_status` of the production version from `production` to `archived`.
 * - Removing the existing draft version.
 */
export type ArchiveModuleMutation = {
  __typename?: "ArchiveModuleMutation";
  error?: Maybe<Scalars["String"]["output"]>;
  module?: Maybe<ModuleType>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type BotType = {
  __typename?: "BotType";
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type BotifyAnalyticsInputType = {
  __typename?: "BotifyAnalyticsInputType";
  automationId?: Maybe<Scalars["BigIntStr"]["output"]>;
  automationProject: Scalars["String"]["output"];
  automationUsername: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  device?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  inputType?: Maybe<Scalars["String"]["output"]>;
  storageUri: Scalars["String"]["output"];
  website: WebsiteType;
};

export enum BotsDiscoveryInputBot {
  /** Apple Desktop */
  AppleDesktop = "APPLE_DESKTOP",
  /** Apple Mobile */
  AppleMobile = "APPLE_MOBILE",
  /** Baidu Desktop */
  BaiduDesktop = "BAIDU_DESKTOP",
  /** Baidu Mobile */
  BaiduMobile = "BAIDU_MOBILE",
  /** Bing Ads Desktop */
  BingAdsDesktop = "BING_ADS_DESKTOP",
  /** Bing Ads Mobile */
  BingAdsMobile = "BING_ADS_MOBILE",
  /** Bing Desktop */
  BingDesktop = "BING_DESKTOP",
  /** Bing Mobile */
  BingMobile = "BING_MOBILE",
  /** Google Ads Desktop */
  GoogleAdsDesktop = "GOOGLE_ADS_DESKTOP",
  /** Google Ads Mobile */
  GoogleAdsMobile = "GOOGLE_ADS_MOBILE",
  /** Google Desktop */
  GoogleDesktop = "GOOGLE_DESKTOP",
  /** Google Inspection Desktop */
  GoogleInspectionDesktop = "GOOGLE_INSPECTION_DESKTOP",
  /** Google Inspection Mobile */
  GoogleInspectionMobile = "GOOGLE_INSPECTION_MOBILE",
  /** Google Mobile */
  GoogleMobile = "GOOGLE_MOBILE",
  /** Google Other */
  GoogleOther = "GOOGLE_OTHER",
  /** Naver Desktop */
  NaverDesktop = "NAVER_DESKTOP",
  /** Yandex Desktop */
  YandexDesktop = "YANDEX_DESKTOP",
  /** Yandex Mobile */
  YandexMobile = "YANDEX_MOBILE",
}

export enum BotsDiscoveryInputSearchEngine {
  /** Apple */
  Apple = "APPLE",
  /** Baidu */
  Baidu = "BAIDU",
  /** Bing */
  Bing = "BING",
  /** Google */
  Google = "GOOGLE",
  /** Naver */
  Naver = "NAVER",
  /** Yandex */
  Yandex = "YANDEX",
}

export type ChangedByType = {
  __typename?: "ChangedByType";
  id: Scalars["String"]["output"];
  source: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export enum ChangesStatus {
  Created = "CREATED",
  Deleted = "DELETED",
  Updated = "UPDATED",
}

export type CheckSetupMutation = {
  __typename?: "CheckSetupMutation";
  checkText?: Maybe<Scalars["String"]["output"]>;
  errorReason?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  headers?: Maybe<Array<HeaderType>>;
  html?: Maybe<Scalars["String"]["output"]>;
  httpCode?: Maybe<Scalars["Int"]["output"]>;
  options?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userAgent: Scalars["String"]["output"];
  website: Scalars["String"]["output"];
};

export type CheckSetupMutationInput = {
  checkText?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
  userAgent: Scalars["String"]["input"];
  website: Scalars["String"]["input"];
};

/** An enumeration. */
export enum ClusterCloud {
  /** AWS */
  Aws = "AWS",
  /** AWS (Infra) */
  AwsInfra = "AWS_INFRA",
  /** GCP */
  Gcp = "GCP",
}

export type ClusterType = {
  __typename?: "ClusterType";
  cloud: ClusterCloud;
  comments: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  /** Default value should be {cluster}.speedworkers.com */
  domain: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  jobs: Array<JobType>;
  name: Scalars["String"]["output"];
  overrideBigqueryDataset: Scalars["String"]["output"];
  overrideBigqueryProject: Scalars["String"]["output"];
  region?: Maybe<Scalars["String"]["output"]>;
  /** For metrology costs */
  salesforceAccountId: Scalars["String"]["output"];
  websites: Array<WebsiteType>;
};

export type ClusterTypeJobsArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filterDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterType?: InputMaybe<JobTypeEnum>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ConfigMutation = {
  __typename?: "ConfigMutation";
  config?: Maybe<ConfigType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type ConfigMutationInput = {
  action: ActionChoices;
  emulationDevice: EmulationDeviceChoices;
  extraHeaders?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Max Number of Pages / Sec */
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  renderingRules?: InputMaybe<Scalars["String"]["input"]>;
  /** Timeout in milliseconds */
  timeout: Scalars["Int"]["input"];
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

export type ConfigType = {
  __typename?: "ConfigType";
  action: ActionChoices;
  emulationDevice: EmulationDeviceChoices;
  extraHeaders: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  /** Max Number of Pages / Sec */
  maxSpeed?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  renderingRules: Scalars["String"]["output"];
  /** Timeout in milliseconds */
  timeout: Scalars["Int"]["output"];
  userAgent: Scalars["String"]["output"];
};

/** An enumeration. */
export enum CrawlStatsInfoEnum {
  InvalidRefreshFrequency = "INVALID_REFRESH_FREQUENCY",
  NoConfigJs = "NO_CONFIG_JS",
  NoPagesInInventory = "NO_PAGES_IN_INVENTORY",
  NoWebsiteMaxSpeed = "NO_WEBSITE_MAX_SPEED",
  Success = "SUCCESS",
}

export type CrawlStatsSectionType = {
  __typename?: "CrawlStatsSectionType";
  load?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type CrawlStatsType = {
  __typename?: "CrawlStatsType";
  info: CrawlStatsInfoEnum;
  load?: Maybe<Scalars["Float"]["output"]>;
  maxPagesRefresh?: Maybe<Scalars["Int"]["output"]>;
  maxSpeed?: Maybe<Scalars["Float"]["output"]>;
  pagesInInventory?: Maybe<Scalars["Int"]["output"]>;
  requiredPagesRefresh?: Maybe<Scalars["Int"]["output"]>;
  requiredSpeed?: Maybe<Scalars["Float"]["output"]>;
  sections?: Maybe<Array<Maybe<CrawlStatsSectionType>>>;
};

export type CreateAdnConfigMutation = {
  __typename?: "CreateADNConfigMutation";
  adnConfig?: Maybe<AdnConfigType>;
  errors: Array<ErrorType>;
};

export type CreateAdnConfigMutationInput = {
  customerOriginPort?: InputMaybe<Scalars["Int"]["input"]>;
  /** Timeout in milliseconds */
  customerOriginTimeout: Scalars["Int"]["input"];
  /** The origin URL field has to be filled when the request isn't sent to the customer CDN but to the customer origin directly. */
  customerOriginUrl?: InputMaybe<Scalars["String"]["input"]>;
  deployTests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  failoverHostHeader?: InputMaybe<Scalars["String"]["input"]>;
  failoverRequestHeaders?: InputMaybe<Scalars["String"]["input"]>;
  failoverRequestHeadersFilters?: InputMaybe<Scalars["String"]["input"]>;
  failoverResponseHeaders?: InputMaybe<Scalars["String"]["input"]>;
  /** The User-Agent to use when the request is sent back to the customer CDN (the request should not be intercepted by SpeedWorkers or we will end up with an infinite loop). No User-Agent is necessary if the fallback request is sent directly to the customer origin (because in this case, the request doesn't go back to the customer CDN). */
  failoverUserAgent?: InputMaybe<Scalars["String"]["input"]>;
  gdprModeEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Ignore paths that match the pattern (the matching is done on the path, not on the querystring) */
  ignoredPathsRegex: Scalars["String"]["input"];
  interceptedBotsRegex: Scalars["String"]["input"];
  swOriginRewrite?: InputMaybe<Scalars["String"]["input"]>;
  /** Timeout in milliseconds */
  swOriginTimeout: Scalars["Int"]["input"];
  swRealOrigin?: InputMaybe<Scalars["String"]["input"]>;
  /** The absolute URL to use to test the lightweight CDN setup. */
  urlCacheHit?: InputMaybe<Scalars["String"]["input"]>;
  verifiedBotsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  website: Scalars["ID"]["input"];
  workerVersion: Scalars["String"]["input"];
};

export type CreateBotifyAnalyticsImportMutation = {
  __typename?: "CreateBotifyAnalyticsImportMutation";
  crawl: Scalars["String"]["output"];
  device: Scalars["String"]["output"];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  jobId?: Maybe<Scalars["Int"]["output"]>;
  project: Scalars["String"]["output"];
  success?: Maybe<Scalars["Boolean"]["output"]>;
  username: Scalars["String"]["output"];
  website: Scalars["String"]["output"];
};

export type CreateBotifyAnalyticsImportMutationInput = {
  crawl: Scalars["String"]["input"];
  device: Scalars["String"]["input"];
  project: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
  website: Scalars["String"]["input"];
};

/** Generate a signed url to download the optimization result. */
export type CreateDownloadLinkHtmlHeadOptimization = {
  __typename?: "CreateDownloadLinkHtmlHeadOptimization";
  downloadLink?: Maybe<Scalars["String"]["output"]>;
};

/** Create a new Html Head optimization and launch the preview generation */
export type CreateHtmlHeadOptimizationMutation = {
  __typename?: "CreateHtmlHeadOptimizationMutation";
  htmlHeadOptimization?: Maybe<HtmlHeadOptimizationType>;
};

export type CreateImportJobMutation = {
  __typename?: "CreateImportJobMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  importJob?: Maybe<ImportJobType>;
};

export type CreateImportJobMutationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  importType: ImportTypeChoices;
  uri: Scalars["String"]["input"];
  website: Scalars["ID"]["input"];
};

export type CreateInputBotsDiscoveryMutation = {
  __typename?: "CreateInputBotsDiscoveryMutation";
  botsDiscoveryInput?: Maybe<InputBotsDiscoveryType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateInputBotsDiscoveryMutationInput = {
  /** Bot ID */
  bot?: InputMaybe<BotsDiscoveryInputBot>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  minCrawlsInPeriod: Scalars["Int"]["input"];
  nbDays: Scalars["Int"]["input"];
  /** Search Engine ID */
  searchEngine?: InputMaybe<BotsDiscoveryInputSearchEngine>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateInputCsvMutation = {
  __typename?: "CreateInputCSVMutation";
  csvInput?: Maybe<InputCsvType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateInputCsvMutationInput = {
  /** Column Number for URL (starts to 0) */
  columnNumber?: InputMaybe<Scalars["Int"]["input"]>;
  commentCharacter?: InputMaybe<Scalars["String"]["input"]>;
  downloadStrategy: DownloadStrategyChoices;
  /** Valid start date for strategy urlPrefixAllNewFiles */
  downloadStrategyAllNewFilesStartDatetime?: InputMaybe<
    Scalars["DateTime"]["input"]
  >;
  escapeFirstLines?: InputMaybe<Scalars["Int"]["input"]>;
  extraHeaders?: InputMaybe<Scalars["String"]["input"]>;
  /** In minutes */
  frequency: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  mode: ModeChoices;
  name: Scalars["String"]["input"];
  /** Number of days in the past to filter in case mode is incremental */
  nbDays?: InputMaybe<Scalars["Int"]["input"]>;
  separator?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
  userAgent: Scalars["String"]["input"];
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateInputLinksDiscoveryMutation = {
  __typename?: "CreateInputLinksDiscoveryMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  linksDiscoveryInput?: Maybe<InputLinksDiscoveryType>;
};

export type CreateInputLinksDiscoveryMutationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  nbDays: Scalars["Int"]["input"];
  sections?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateInputManualUploadMutation = {
  __typename?: "CreateInputManualUploadMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  manualUploadInput?: Maybe<InputManualUploadType>;
};

export type CreateInputManualUploadMutationInput = {
  fileName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateInputSitemapMutation = {
  __typename?: "CreateInputSitemapMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  sitemapInput?: Maybe<InputSitemapType>;
};

export type CreateInputSitemapMutationInput = {
  downloadStrategy: DownloadStrategyChoices;
  /** Valid start date for strategy urlPrefixAllNewFiles */
  downloadStrategyAllNewFilesStartDatetime?: InputMaybe<
    Scalars["DateTime"]["input"]
  >;
  extraHeaders?: InputMaybe<Scalars["String"]["input"]>;
  /** In minutes */
  frequency: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  mode: ModeChoices;
  name: Scalars["String"]["input"];
  /** Number of days in the past to filter in case mode is incremental */
  nbDays?: InputMaybe<Scalars["Int"]["input"]>;
  url: Scalars["String"]["input"];
  userAgent: Scalars["String"]["input"];
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateInputTextListMutation = {
  __typename?: "CreateInputTextListMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  textListInput?: Maybe<InputTextListType>;
};

export type CreateInputTextListMutationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  urls: Scalars["String"]["input"];
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateJsCodeMutation = {
  __typename?: "CreateJSCodeMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  jsCode?: Maybe<JsCodeType>;
};

export type CreateJsCodeMutationInput = {
  active: Scalars["Boolean"]["input"];
  applyOnWebsite?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  onActionCode?: InputMaybe<Scalars["String"]["input"]>;
  onDomContentLoadedCode?: InputMaybe<Scalars["String"]["input"]>;
  onDoneCode?: InputMaybe<Scalars["String"]["input"]>;
  onInitCode?: InputMaybe<Scalars["String"]["input"]>;
  onLoadCode?: InputMaybe<Scalars["String"]["input"]>;
  onPreBeamResponseCode?: InputMaybe<Scalars["String"]["input"]>;
  onSetupBeforeRenderCode?: InputMaybe<Scalars["String"]["input"]>;
  onWaitForCode?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  websiteVersion: Scalars["ID"]["input"];
};

export type CreateModuleMutation = {
  __typename?: "CreateModuleMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  module?: Maybe<ModuleType>;
};

export type CreateModuleMutationInput = {
  config: Scalars["String"]["input"];
  /** The dataset against which we validate the entries (Only necessary when we send entries) */
  dataset?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** The URLs the module would be applied to (in by url modules) and it also contains the data associated to each URL */
  entries?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  moduleType: ModulesTypesChoices;
  name: Scalars["String"]["input"];
  /** If set, this field controls the size (in percentage) of the variant group used for split testing. If left NULL, split testing won't be enabled for that module. */
  splitTestingVariantGroupSizePercentage?: InputMaybe<Scalars["Int"]["input"]>;
  website: Scalars["ID"]["input"];
};

/** Create a new Module Tag */
export type CreateModuleTagMutation = {
  __typename?: "CreateModuleTagMutation";
  moduleTag?: Maybe<ModuleTagType>;
};

/** Generate a link for LUD export */
export type CreateModuleUrlsExportLinkMutation = {
  __typename?: "CreateModuleUrlsExportLinkMutation";
  link?: Maybe<Scalars["String"]["output"]>;
};

/** Create a LUD dataset increment (Add/Edit/Remove) */
export type CreateModuleUrlsIncrementMutation = {
  __typename?: "CreateModuleUrlsIncrementMutation";
  status?: Maybe<Scalars["String"]["output"]>;
};

export type CreateSectionMutation = {
  __typename?: "CreateSectionMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  section?: Maybe<SectionType>;
};

export type CreateSectionMutationInput = {
  customRefreshRules?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  desktopConfig?: InputMaybe<Scalars["ID"]["input"]>;
  /** Content Types split by comma */
  htmlContentTypes?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Parameters split by comma */
  ignoreParameters?: InputMaybe<Scalars["String"]["input"]>;
  ignoreParametersCascading?: InputMaybe<SectionIgnoreParametersCascading>;
  /** Replace bad links/rel to final URL */
  ignoreParametersReplaceLinks?: InputMaybe<Scalars["Boolean"]["input"]>;
  ignoreParametersStrategy?: InputMaybe<SectionIgnoreParametersStrategy>;
  inheritedRefreshRulesOverrides?: InputMaybe<Scalars["String"]["input"]>;
  /** Set a redirect http code when pushState or window.location is called */
  jsRedirectStatusCode?: InputMaybe<SectionJsRedirectStatusCode>;
  /** Max stale to serve pages in case it was not refreshed in time */
  maxStale?: InputMaybe<Scalars["Int"]["input"]>;
  mobileConfig?: InputMaybe<Scalars["ID"]["input"]>;
  mode: SectionMode;
  /** HTML Content to return */
  modeCustomHtmlHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeCustomHtmlStatusCode?: InputMaybe<Scalars["Int"]["input"]>;
  modeNotFoundHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeNotFoundStatusCode?: InputMaybe<SectionModeNotFoundStatusCode>;
  modeRedirectJs?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectLocation?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectStatusCode?: InputMaybe<SectionModeRedirectStatusCode>;
  modeRedirectStrategy?: InputMaybe<SectionModeRedirectStrategy>;
  name: Scalars["String"]["input"];
  /** When set to TRUE, the section will override the render configs of its parent, not default to it */
  overrideRenderConfigs?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Refresh Pages every N minutes */
  refreshFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  /** Smallest is the most prioritary */
  refreshPriority?: InputMaybe<Scalars["Int"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateSignedUrlMutation = {
  __typename?: "CreateSignedUrlMutation";
  extraHeaders?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  filename?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CreateSmartLinkOptimizationMutation = {
  __typename?: "CreateSmartLinkOptimizationMutation";
  smartlinkOptimization?: Maybe<SmartLinkOptimizationType>;
};

/** Generate a signed url to download the optimization result. */
export type CreateSmartLinkResultDownloadLink = {
  __typename?: "CreateSmartLinkResultDownloadLink";
  downloadLink?: Maybe<Scalars["String"]["output"]>;
};

export type CreateSmartLinkSignedUrlForUpload = {
  __typename?: "CreateSmartLinkSignedUrlForUpload";
  extraHeaders?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  filename?: Maybe<Scalars["String"]["output"]>;
  gsUrl?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CreateWebsiteMutation = {
  __typename?: "CreateWebsiteMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  website?: Maybe<WebsiteType>;
};

export type CreateWebsiteMutationInput = {
  cluster: Scalars["ID"]["input"];
  hosts: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  pageworkersCluster?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CsvDataType = {
  __typename?: "CsvDataType";
  count?: Maybe<Scalars["Int"]["output"]>;
  dateFirstSeen?: Maybe<Scalars["DateTime"]["output"]>;
  dateLastSeen?: Maybe<Scalars["DateTime"]["output"]>;
  sample?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type DatasetType = {
  __typename?: "DatasetType";
  name: Scalars["String"]["output"];
  schema: Scalars["String"]["output"];
};

export type DeleteBotsDiscoveryInputMutation = {
  __typename?: "DeleteBotsDiscoveryInputMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  input: InputBotsDiscoveryType;
};

export type DeleteCsvInputMutation = {
  __typename?: "DeleteCSVInputMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  input: InputCsvType;
};

export type DeleteHtmlHeadOptimization = {
  __typename?: "DeleteHtmlHeadOptimization";
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteInterceptorCheckerMutation = {
  __typename?: "DeleteInterceptorCheckerMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type DeleteLinksDiscoveryInputMutation = {
  __typename?: "DeleteLinksDiscoveryInputMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  input: InputLinksDiscoveryType;
};

export type DeleteManualUploadInputMutation = {
  __typename?: "DeleteManualUploadInputMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  input: InputManualUploadType;
};

/** Delete an existing Module Tag */
export type DeleteModuleTagMutation = {
  __typename?: "DeleteModuleTagMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type DeleteQualityControlMutation = {
  __typename?: "DeleteQualityControlMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  qualityControl: QualityControlType;
};

export type DeleteSectionMutation = {
  __typename?: "DeleteSectionMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  section: SectionType;
};

export type DeleteSitemapInputMutation = {
  __typename?: "DeleteSitemapInputMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  input: InputSitemapType;
};

export type DeleteSmartLinkOptimization = {
  __typename?: "DeleteSmartLinkOptimization";
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeleteTextListInputMutation = {
  __typename?: "DeleteTextListInputMutation";
  id?: Maybe<Scalars["ID"]["output"]>;
  input: InputTextListType;
};

export type DeployAdnConfigMutation = {
  __typename?: "DeployADNConfigMutation";
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

/** An enumeration. */
export enum Device {
  Auto = "auto",
  Desktop = "desktop",
  Mobile = "mobile",
}

export enum DeviceEnum {
  /** Desktop */
  Desktop = "DESKTOP",
  /** Desktop and mobile */
  DesktopAndMobile = "DESKTOP_AND_MOBILE",
  /** Mobile */
  Mobile = "MOBILE",
}

export type DistantInputHeaderType = {
  __typename?: "DistantInputHeaderType";
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

/** An enumeration. */
export enum DownloadStrategyChoices {
  Matchesurl = "MATCHESURL",
  Urlprefixallnewfiles = "URLPREFIXALLNEWFILES",
  Urlprefixlastmodified = "URLPREFIXLASTMODIFIED",
}

export type Experimental_ReportingMetricsType = {
  __typename?: "EXPERIMENTAL_ReportingMetricsType";
  avgClicks7d?: Maybe<Scalars["Float"]["output"]>;
  avgClicksPerUrl?: Maybe<Scalars["Float"]["output"]>;
  avgImpressions7d?: Maybe<Scalars["Float"]["output"]>;
  avgImpressionsPerUrl?: Maybe<Scalars["Float"]["output"]>;
  avgPosition?: Maybe<Scalars["Float"]["output"]>;
  avgPosition7d?: Maybe<Scalars["Float"]["output"]>;
  avgPositionRankingsGt1m?: Maybe<Scalars["Float"]["output"]>;
  avgPositionRankingsLt1m?: Maybe<Scalars["Float"]["output"]>;
  clicks?: Maybe<Scalars["Int"]["output"]>;
  clicksRankingFirstClickGte1m?: Maybe<Scalars["Int"]["output"]>;
  clicksRankingFirstClickLt1m?: Maybe<Scalars["Int"]["output"]>;
  clicksRankingFirstClickToday?: Maybe<Scalars["Int"]["output"]>;
  clicksWithPw?: Maybe<Scalars["Int"]["output"]>;
  clicksWoPw?: Maybe<Scalars["Int"]["output"]>;
  ctr?: Maybe<Scalars["Float"]["output"]>;
  ctr7d?: Maybe<Scalars["Float"]["output"]>;
  ctrWithPw?: Maybe<Scalars["Float"]["output"]>;
  ctrWithPw7d?: Maybe<Scalars["Float"]["output"]>;
  ctrWoPw?: Maybe<Scalars["Float"]["output"]>;
  ctrWoPw7d?: Maybe<Scalars["Float"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  impressions?: Maybe<Scalars["Int"]["output"]>;
  impressionsWithPw?: Maybe<Scalars["Int"]["output"]>;
  impressionsWoPw?: Maybe<Scalars["Int"]["output"]>;
  position?: Maybe<Scalars["Float"]["output"]>;
  sumClicks7d?: Maybe<Scalars["Int"]["output"]>;
  sumClicksWithPw7d?: Maybe<Scalars["Int"]["output"]>;
  sumClicksWoPw7d?: Maybe<Scalars["Int"]["output"]>;
  sumImpressions7d?: Maybe<Scalars["Int"]["output"]>;
  sumImpressionsWithPw7d?: Maybe<Scalars["Int"]["output"]>;
  sumImpressionsWoPw7d?: Maybe<Scalars["Int"]["output"]>;
  uniqueKeywords?: Maybe<Scalars["Int"]["output"]>;
  uniqueRankings?: Maybe<Scalars["Int"]["output"]>;
  uniqueRankings7d?: Maybe<Scalars["Int"]["output"]>;
  uniqueRankingsNew?: Maybe<Scalars["Int"]["output"]>;
  uniqueRankingsNew7d?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrls?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrls7d?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrlsFirstClickGte1m?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrlsFirstClickLt1m?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrlsNew?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrlsNew7d?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrlsNew30d?: Maybe<Scalars["Int"]["output"]>;
  weightedPosition?: Maybe<Scalars["Float"]["output"]>;
  weightedPositionKwGte1m?: Maybe<Scalars["Float"]["output"]>;
  weightedPositionKwLt1m?: Maybe<Scalars["Float"]["output"]>;
};

export type ElementDescriptorAttributes = {
  __typename?: "ElementDescriptorAttributes";
  key?: Maybe<Scalars["String"]["output"]>;
  truncated?: Maybe<Scalars["Boolean"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ElementDescriptorInnerHtmlExcerpt = {
  __typename?: "ElementDescriptorInnerHtmlExcerpt";
  truncated?: Maybe<Scalars["Boolean"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ElementDescriptorType = {
  __typename?: "ElementDescriptorType";
  firstAttributes?: Maybe<Array<Maybe<ElementDescriptorAttributes>>>;
  hasOtherAttributes?: Maybe<Scalars["Boolean"]["output"]>;
  innerHtmlExcerpt?: Maybe<ElementDescriptorInnerHtmlExcerpt>;
  tag?: Maybe<Scalars["String"]["output"]>;
};

/** An enumeration. */
export enum EmulationDeviceChoices {
  Botify = "BOTIFY",
  BotifySmartphone = "BOTIFY_SMARTPHONE",
  Desktop = "DESKTOP",
  Google = "GOOGLE",
  GoogleSmartphone = "GOOGLE_SMARTPHONE",
  Ipad = "IPAD",
  Iphone = "IPHONE",
  Laptop = "LAPTOP",
}

export type ErrorType = {
  __typename?: "ErrorType";
  field: Scalars["String"]["output"];
  messages: Array<Scalars["String"]["output"]>;
};

export type FakeConfigType = {
  __typename?: "FakeConfigType";
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type FetchBotifyProjectLink = {
  __typename?: "FetchBotifyProjectLink";
  link?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ForceRefreshErrorType = {
  __typename?: "ForceRefreshErrorType";
  message: Scalars["String"]["output"];
};

export type ForceRefreshOptions = {
  device?: InputMaybe<Device>;
  mode?: InputMaybe<Mode>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ForceSectionRefreshMutation = {
  __typename?: "ForceSectionRefreshMutation";
  errors?: Maybe<Array<ForceRefreshErrorType>>;
  ok: Scalars["Boolean"]["output"];
};

export type ForceUrlsRefreshMutation = {
  __typename?: "ForceUrlsRefreshMutation";
  errors?: Maybe<Array<ForceRefreshErrorType>>;
  ok: Scalars["Boolean"]["output"];
};

export type HeaderType = {
  __typename?: "HeaderType";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type HtmlBlockType = {
  __typename?: "HtmlBlockType";
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  cssSelector: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  innerHtml: Scalars["String"]["output"];
  insertStrategy?: Maybe<Scalars["String"]["output"]>;
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  pageEdit: PageEditType;
  /** Watch DOM mutations on first matching selector to reexecute alteration */
  watchSelector: Scalars["String"]["output"];
};

export type HtmlHeadOptimizationType = {
  __typename?: "HtmlHeadOptimizationType";
  companyDescription?: Maybe<Scalars["String"]["output"]>;
  completionPercent?: Maybe<Scalars["Int"]["output"]>;
  createdDate: Scalars["DateTime"]["output"];
  /** Description of the optimization */
  description: Scalars["String"]["output"];
  forbiddenKeywords?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id: Scalars["UUID"]["output"];
  modifiedDate: Scalars["DateTime"]["output"];
  sample?: Maybe<Scalars["JSONString"]["output"]>;
  scope?: Maybe<Scalars["JSONString"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  targetAudience?: Maybe<Scalars["String"]["output"]>;
  /** Title of the optimization */
  title: Scalars["String"]["output"];
};

export enum HtmlUpdatesLogAction {
  Added = "ADDED",
  Changed = "CHANGED",
  Removed = "REMOVED",
}

export enum HtmlUpdatesLogElement {
  AlternateToMobile = "ALTERNATE_TO_MOBILE",
  Canonical = "CANONICAL",
  Description = "DESCRIPTION",
  Element = "ELEMENT",
  ElementAttribute = "ELEMENT_ATTRIBUTE",
  H1 = "H1",
  Hreflang = "HREFLANG",
  HtmlBlock = "HTML_BLOCK",
  Link = "LINK",
  Next = "NEXT",
  OgDescription = "OG_DESCRIPTION",
  PermanentRedirect = "PERMANENT_REDIRECT",
  Prev = "PREV",
  TemporaryRedirect = "TEMPORARY_REDIRECT",
  Title = "TITLE",
}

export enum HtmlUpdatesLogStep {
  None = "NONE",
  PreBeamResponse = "PRE_BEAM_RESPONSE",
  Rendering = "RENDERING",
}

export type HtmlUpdatesLogType = {
  __typename?: "HtmlUpdatesLogType";
  action?: Maybe<HtmlUpdatesLogAction>;
  attribute?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  element?: Maybe<HtmlUpdatesLogElement>;
  lang?: Maybe<Scalars["String"]["output"]>;
  oldValue?: Maybe<Scalars["String"]["output"]>;
  selector?: Maybe<Scalars["String"]["output"]>;
  step?: Maybe<HtmlUpdatesLogStep>;
  strategy?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type HtmlUpdatesType = {
  __typename?: "HtmlUpdatesType";
  log?: Maybe<Array<Maybe<HtmlUpdatesLogType>>>;
};

export type IgnoredEntryType = {
  __typename?: "IgnoredEntryType";
  reason?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["JSONString"]["output"]>;
};

/**
 *
 *     Determines the impact level when refreshing a list of urls.
 *     OK: the list is within limits.
 *     WARNING: the list is over limits but was requested by a qualified user.
 *     DANGER: the list is over limits and was requested by an unqualified user.
 *
 */
export enum ImpactLevel {
  Danger = "DANGER",
  Ok = "OK",
  Warning = "WARNING",
}

/** An enumeration. */
export enum ImportJobStatus {
  /** Created */
  Created = "CREATED",
  /** Done */
  Done = "DONE",
  /** Failed */
  Failed = "FAILED",
  /** Running */
  Running = "RUNNING",
}

export type ImportJobType = {
  __typename?: "ImportJobType";
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  dateCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  errorMessage: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  importType?: Maybe<Scalars["String"]["output"]>;
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  result: Scalars["String"]["output"];
  status: ImportJobStatus;
  uri: Scalars["String"]["output"];
  website: WebsiteType;
};

/** An enumeration. */
export enum ImportTypeChoices {
  HtmlBlock = "HTML_BLOCK",
  Metadata = "METADATA",
}

export type IndexationEventDetail = {
  __typename?: "IndexationEventDetail";
  error?: Maybe<Scalars["String"]["output"]>;
  ftlRawPage?: Maybe<Scalars["JSONString"]["output"]>;
};

export type InheritedRefreshRulesOverride = {
  __typename?: "InheritedRefreshRulesOverride";
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  maxStale?: Maybe<Scalars["Int"]["output"]>;
  preventIndexation?: Maybe<Scalars["Boolean"]["output"]>;
  priority?: Maybe<Scalars["Int"]["output"]>;
  refreshFrequency?: Maybe<Scalars["Int"]["output"]>;
};

export type InputBotsDiscoveryType = {
  __typename?: "InputBotsDiscoveryType";
  /** Refresh is active */
  active: Scalars["Boolean"]["output"];
  /** Bot ID */
  bot?: Maybe<BotsDiscoveryInputBot>;
  changes?: Maybe<ModelChangesType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  minCrawlsInPeriod: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  nbDays: Scalars["Int"]["output"];
  ready?: Maybe<Scalars["Boolean"]["output"]>;
  /** Search Engine ID */
  searchEngine?: Maybe<BotsDiscoveryInputSearchEngine>;
  sections?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  stableId: Scalars["String"]["output"];
  volume?: Maybe<VolumeType>;
  website: WebsiteType;
};

export type InputCsvType = {
  __typename?: "InputCSVType";
  /** Refresh is active */
  active: Scalars["Boolean"]["output"];
  changes?: Maybe<ModelChangesType>;
  /** Column Number for URL (starts to 0) */
  columnNumber?: Maybe<Scalars["Int"]["output"]>;
  commentCharacter: Scalars["String"]["output"];
  config?: Maybe<FakeConfigType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  downloadStrategy: SitemapInputDownloadStrategy;
  /** Valid start date for strategy urlPrefixAllNewFiles */
  downloadStrategyAllNewFilesStartDatetime?: Maybe<
    Scalars["DateTime"]["output"]
  >;
  escapeFirstLines?: Maybe<Scalars["Int"]["output"]>;
  extraHeaders?: Maybe<Array<Maybe<DistantInputHeaderType>>>;
  /** In minutes */
  frequency: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  mode: ModeChoices;
  name: Scalars["String"]["output"];
  /** Number of days in the past to filter in case mode is incremental */
  nbDays?: Maybe<Scalars["Int"]["output"]>;
  queryData: Array<CsvDataType>;
  /** Add Input in Smart Cache Refresh Inventory */
  ready: Scalars["Boolean"]["output"];
  separator: Scalars["String"]["output"];
  stableId: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  userAgent: Scalars["String"]["output"];
  volume?: Maybe<VolumeType>;
  website: WebsiteType;
};

export type InputCsvTypeQueryDataArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InputDescriptor = {
  __typename?: "InputDescriptor";
  id?: Maybe<Scalars["String"]["output"]>;
  inputType?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  stableId?: Maybe<Scalars["String"]["output"]>;
};

export type InputLinksDiscoveryType = {
  __typename?: "InputLinksDiscoveryType";
  /** Refresh is active */
  active: Scalars["Boolean"]["output"];
  changes?: Maybe<ModelChangesType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nbDays: Scalars["Int"]["output"];
  queryData: Array<LinksDiscoveryDataType>;
  /** Add Input in Smart Cache Refresh Inventory */
  ready: Scalars["Boolean"]["output"];
  sections?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  stableId: Scalars["String"]["output"];
  volume?: Maybe<VolumeType>;
  website: WebsiteType;
};

export type InputLinksDiscoveryTypeQueryDataArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InputManualUploadType = {
  __typename?: "InputManualUploadType";
  /** Refresh is active */
  active: Scalars["Boolean"]["output"];
  changes?: Maybe<ModelChangesType>;
  /** Column Number for URL (starts to 0) */
  columnNumber?: Maybe<Scalars["Int"]["output"]>;
  commentCharacter: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  escapeFirstLines?: Maybe<Scalars["Int"]["output"]>;
  fileName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** Add Input in Smart Cache Refresh Inventory */
  ready: Scalars["Boolean"]["output"];
  separator: Scalars["String"]["output"];
  stableId: Scalars["String"]["output"];
  volume?: Maybe<VolumeType>;
  website: WebsiteType;
};

export type InputSitemapType = {
  __typename?: "InputSitemapType";
  /** Refresh is active */
  active: Scalars["Boolean"]["output"];
  changes?: Maybe<ModelChangesType>;
  config?: Maybe<FakeConfigType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  downloadStrategy: SitemapInputDownloadStrategy;
  /** Valid start date for strategy urlPrefixAllNewFiles */
  downloadStrategyAllNewFilesStartDatetime?: Maybe<
    Scalars["DateTime"]["output"]
  >;
  extraHeaders?: Maybe<Array<Maybe<DistantInputHeaderType>>>;
  /** In minutes */
  frequency: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  mode: ModeChoices;
  name: Scalars["String"]["output"];
  /** Number of days in the past to filter in case mode is incremental */
  nbDays?: Maybe<Scalars["Int"]["output"]>;
  queryData: Array<SitemapDataType>;
  /** Add Input in Smart Cache Refresh Inventory */
  ready: Scalars["Boolean"]["output"];
  stableId: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  userAgent: Scalars["String"]["output"];
  volume?: Maybe<VolumeType>;
  website: WebsiteType;
};

export type InputSitemapTypeQueryDataArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InputStreamingType = {
  __typename?: "InputStreamingType";
  /** Refresh is active */
  active: Scalars["Boolean"]["output"];
  changes?: Maybe<ModelChangesType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** Add Input in Smart Cache Refresh Inventory */
  ready: Scalars["Boolean"]["output"];
  stableId: Scalars["String"]["output"];
  volume?: Maybe<VolumeType>;
};

export type InputTextListType = {
  __typename?: "InputTextListType";
  /** Refresh is active */
  active: Scalars["Boolean"]["output"];
  changes?: Maybe<ModelChangesType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nbUrls?: Maybe<Scalars["Int"]["output"]>;
  queryData: Array<TextListDataType>;
  /** Add Input in Smart Cache Refresh Inventory */
  ready: Scalars["Boolean"]["output"];
  stableId: Scalars["String"]["output"];
  urls: Scalars["String"]["output"];
  volume?: Maybe<VolumeType>;
  website: WebsiteType;
};

export type InputTextListTypeQueryDataArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InterceptorCheckerConfigType = {
  __typename?: "InterceptorCheckerConfigType";
  active: Scalars["Boolean"]["output"];
  alertingFailStrategy: InterceptorCheckerJobAlertingFailStrategy;
  /** Comma separated */
  checksToPerform: Scalars["String"]["output"];
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  dateCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  domain: Scalars["String"]["output"];
  extraHeaders: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  /** Comma separated */
  ignoredParams: Scalars["String"]["output"];
  interceptorCheckerJobs: Array<InterceptorCheckerJobType>;
  /** in ms */
  interceptorTimeout: Scalars["Int"]["output"];
  lastJob?: Maybe<InterceptorCheckerJobType>;
  maxParallel: Scalars["Int"]["output"];
  maxSpeed: Scalars["Int"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  platform?: Maybe<InterceptorCheckerJobPlatform>;
  scopeRoute: Scalars["String"]["output"];
  switchToAkamaiStaging: Scalars["Boolean"]["output"];
  testRules: Array<Maybe<InterceptorChecksEnum>>;
  urlAmp: Scalars["String"]["output"];
  urlCacheHit: Scalars["String"]["output"];
  urlOutOfScope: Scalars["String"]["output"];
  userAgentBrowser: Scalars["String"]["output"];
  userAgentFailover: Scalars["String"]["output"];
  userAgentTestBot: Scalars["String"]["output"];
  website: WebsiteType;
};

/** An enumeration. */
export enum InterceptorCheckerJobAlertingFailStrategy {
  /** Single Fail */
  SingleFail = "SINGLE_FAIL",
  /** Two consecutive fails */
  TwoConsecutiveFails = "TWO_CONSECUTIVE_FAILS",
}

/** An enumeration. */
export enum InterceptorCheckerJobPlatform {
  /** CDN Akamai */
  Akamai = "AKAMAI",
  /** CDN Cloudflare */
  Cloudflare = "CLOUDFLARE",
  /** CDN Cloudfront */
  Cloudfront = "CLOUDFRONT",
  /** Other */
  Other = "OTHER",
  /** Salesforce Commerce Cloud */
  Sfcc = "SFCC",
}

export type InterceptorCheckerJobResult = {
  __typename?: "InterceptorCheckerJobResult";
  id?: Maybe<InterceptorChecksEnum>;
  succeed?: Maybe<Scalars["Boolean"]["output"]>;
};

/** An enumeration. */
export enum InterceptorCheckerJobStatus {
  /** Created */
  Created = "CREATED",
  /** Done */
  Done = "DONE",
  /** Failed */
  Failed = "FAILED",
  /** Running */
  Running = "RUNNING",
}

export type InterceptorCheckerJobType = {
  __typename?: "InterceptorCheckerJobType";
  alertingFailStrategy: InterceptorCheckerJobAlertingFailStrategy;
  checksResult?: Maybe<Array<Maybe<InterceptorCheckerJobResult>>>;
  /** This one is different than the `result` field since it only contains a small portion of the result that we can safely expose to the frontend app */
  checksResultJson: Scalars["String"]["output"];
  /** Comma separated */
  checksToPerform: Scalars["String"]["output"];
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  dateCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  domain: Scalars["String"]["output"];
  errorMessage: Scalars["String"]["output"];
  extraHeaders: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  /** Comma separated */
  ignoredParams: Scalars["String"]["output"];
  interceptorCheckerConfig?: Maybe<InterceptorCheckerConfigType>;
  /** in ms */
  interceptorTimeout: Scalars["Int"]["output"];
  maxParallel: Scalars["Int"]["output"];
  maxSpeed: Scalars["Int"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  platform?: Maybe<InterceptorCheckerJobPlatform>;
  result: Scalars["String"]["output"];
  scopeRoute: Scalars["String"]["output"];
  status: InterceptorCheckerJobStatus;
  switchToAkamaiStaging: Scalars["Boolean"]["output"];
  testStatus?: Maybe<TestStatus>;
  urlAmp: Scalars["String"]["output"];
  urlCacheHit: Scalars["String"]["output"];
  urlOutOfScope: Scalars["String"]["output"];
  userAgentBrowser: Scalars["String"]["output"];
  userAgentFailover: Scalars["String"]["output"];
  userAgentTestBot: Scalars["String"]["output"];
  website: WebsiteType;
};

export enum InterceptorChecksEnum {
  AlwaysSuccess = "ALWAYS_SUCCESS",
  Amp = "AMP",
  BotifyBot = "BOTIFY_BOT",
  CacheHit = "CACHE_HIT",
  CacheMiss = "CACHE_MISS",
  ForwardParameters = "FORWARD_PARAMETERS",
  Http = "HTTP",
  IgnoredParameters = "IGNORED_PARAMETERS",
  InterceptedUa = "INTERCEPTED_UA",
  NotModified = "NOT_MODIFIED",
  NoCache = "NO_CACHE",
  NoCacheHeader = "NO_CACHE_HEADER",
  NoFallbackCache = "NO_FALLBACK_CACHE",
  OtherBots = "OTHER_BOTS",
  OutOfScope = "OUT_OF_SCOPE",
  Post = "POST",
  Redirect = "REDIRECT",
  Resources = "RESOURCES",
  RobotsTxt = "ROBOTS_TXT",
  SearchBots = "SEARCH_BOTS",
  Timeout = "TIMEOUT",
  User = "USER",
  Websocket = "WEBSOCKET",
}

export type InternalCreateSectionMutation = {
  __typename?: "InternalCreateSectionMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  section?: Maybe<SectionType>;
};

export type InternalCreateSectionMutationInput = {
  customRefreshRules?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  desktopConfig?: InputMaybe<Scalars["ID"]["input"]>;
  /** Content Types split by comma */
  htmlContentTypes?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Parameters split by comma */
  ignoreParameters?: InputMaybe<Scalars["String"]["input"]>;
  ignoreParametersCascading?: InputMaybe<SectionIgnoreParametersCascading>;
  /** Replace bad links/rel to final URL */
  ignoreParametersReplaceLinks?: InputMaybe<Scalars["Boolean"]["input"]>;
  ignoreParametersStrategy?: InputMaybe<SectionIgnoreParametersStrategy>;
  inheritedRefreshRulesOverrides?: InputMaybe<Scalars["String"]["input"]>;
  /** Set a redirect http code when pushState or window.location is called */
  jsRedirectStatusCode?: InputMaybe<SectionJsRedirectStatusCode>;
  /** Live Render Max pages per sec */
  liveMaxSpeed?: InputMaybe<Scalars["Decimal"]["input"]>;
  liveMode?: InputMaybe<SectionLiveMode>;
  liveScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Max pages per sec */
  maxSpeed?: InputMaybe<Scalars["Decimal"]["input"]>;
  /** Max stale to serve pages in case it was not refreshed in time */
  maxStale?: InputMaybe<Scalars["Int"]["input"]>;
  mobileConfig?: InputMaybe<Scalars["ID"]["input"]>;
  mode: SectionMode;
  /** HTML Content to return */
  modeCustomHtmlHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeCustomHtmlStatusCode?: InputMaybe<Scalars["Int"]["input"]>;
  modeNotFoundHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeNotFoundStatusCode?: InputMaybe<SectionModeNotFoundStatusCode>;
  modeRedirectJs?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectLocation?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectStatusCode?: InputMaybe<SectionModeRedirectStatusCode>;
  modeRedirectStrategy?: InputMaybe<SectionModeRedirectStrategy>;
  name: Scalars["String"]["input"];
  /** When set to TRUE, the section will override the render configs of its parent, not default to it */
  overrideRenderConfigs?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Refresh Pages every N minutes */
  refreshFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  /** Smallest is the most prioritary */
  refreshPriority?: InputMaybe<Scalars["Int"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InternalUpdateSectionMutation = {
  __typename?: "InternalUpdateSectionMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  section?: Maybe<SectionType>;
};

export type InternalUpdateSectionMutationInput = {
  customRefreshRules?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  desktopConfig?: InputMaybe<Scalars["ID"]["input"]>;
  /** Content Types split by comma */
  htmlContentTypes?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Parameters split by comma */
  ignoreParameters?: InputMaybe<Scalars["String"]["input"]>;
  ignoreParametersCascading?: InputMaybe<SectionIgnoreParametersCascading>;
  /** Replace bad links/rel to final URL */
  ignoreParametersReplaceLinks?: InputMaybe<Scalars["Boolean"]["input"]>;
  ignoreParametersStrategy?: InputMaybe<SectionIgnoreParametersStrategy>;
  inheritedRefreshRulesOverrides?: InputMaybe<Scalars["String"]["input"]>;
  /** Set a redirect http code when pushState or window.location is called */
  jsRedirectStatusCode?: InputMaybe<SectionJsRedirectStatusCode>;
  /** Live Render Max pages per sec */
  liveMaxSpeed?: InputMaybe<Scalars["Decimal"]["input"]>;
  liveMode?: InputMaybe<SectionLiveMode>;
  liveScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Max pages per sec */
  maxSpeed?: InputMaybe<Scalars["Decimal"]["input"]>;
  /** Max stale to serve pages in case it was not refreshed in time */
  maxStale?: InputMaybe<Scalars["Int"]["input"]>;
  mobileConfig?: InputMaybe<Scalars["ID"]["input"]>;
  mode: SectionMode;
  /** HTML Content to return */
  modeCustomHtmlHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeCustomHtmlStatusCode?: InputMaybe<Scalars["Int"]["input"]>;
  modeNotFoundHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeNotFoundStatusCode?: InputMaybe<SectionModeNotFoundStatusCode>;
  modeRedirectJs?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectLocation?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectStatusCode?: InputMaybe<SectionModeRedirectStatusCode>;
  modeRedirectStrategy?: InputMaybe<SectionModeRedirectStrategy>;
  name: Scalars["String"]["input"];
  /** When set to TRUE, the section will override the render configs of its parent, not default to it */
  overrideRenderConfigs?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Refresh Pages every N minutes */
  refreshFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  /** Smallest is the most prioritary */
  refreshPriority?: InputMaybe<Scalars["Int"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  website: Scalars["ID"]["input"];
  websiteVersion?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InventoryStatsSamplesType = {
  __typename?: "InventoryStatsSamplesType";
  device?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum JsCodeStep {
  /** [Rendering] Action */
  Action = "ACTION",
  /** [Rendering] Dom Content Loaded */
  DomContentLoaded = "DOM_CONTENT_LOADED",
  /** [Rendering] Done */
  Done = "DONE",
  /** [Rendering] Init */
  Init = "INIT",
  /** [Rendering] Load */
  Load = "LOAD",
  /** [Delivery] Pre-Beam Response */
  PreBeamResponse = "PRE_BEAM_RESPONSE",
  /** [Rendering] Setup Before Render */
  SetupBeforeRender = "SETUP_BEFORE_RENDER",
  /** [Rendering] Wait For */
  WaitFor = "WAIT_FOR",
}

export type JsCodeType = {
  __typename?: "JSCodeType";
  active: Scalars["Boolean"]["output"];
  /** The code is applied on every section of the website. Not taking care of the sections list. */
  applyOnWebsite: Scalars["Boolean"]["output"];
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  /** Rendering on action code */
  onActionCode: Scalars["String"]["output"];
  /** Rendering on dom content loaded code */
  onDomContentLoadedCode: Scalars["String"]["output"];
  /** Rendering on done code */
  onDoneCode: Scalars["String"]["output"];
  /** Rendering on init code */
  onInitCode: Scalars["String"]["output"];
  /** Rendering on load code */
  onLoadCode: Scalars["String"]["output"];
  /** Delivery on pre-beam response code */
  onPreBeamResponseCode: Scalars["String"]["output"];
  /** Rendering on setup before render code */
  onSetupBeforeRenderCode: Scalars["String"]["output"];
  /** Rendering on wait for code */
  onWaitForCode: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
  sections: Array<SectionType>;
  stableId: Scalars["String"]["output"];
  steps?: Maybe<Array<JsCodeStep>>;
  websiteVersion: WebsiteVersionType;
};

export type JobEvent = {
  __typename?: "JobEvent";
  date?: Maybe<Scalars["Date"]["output"]>;
  instanceId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<JobStatusEnum>;
};

export enum JobStatusEnum {
  Deleted = "DELETED",
  Failed = "FAILED",
  Finished = "FINISHED",
  Running = "RUNNING",
  Todo = "TODO",
  Unknown = "UNKNOWN",
  Waiting = "WAITING",
}

export type JobType = {
  __typename?: "JobType";
  date?: Maybe<Scalars["String"]["output"]>;
  dateCreated?: Maybe<Scalars["Date"]["output"]>;
  dateFinished?: Maybe<Scalars["Date"]["output"]>;
  dateLastUpdate?: Maybe<Scalars["Date"]["output"]>;
  done?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  events?: Maybe<Array<Maybe<JobEvent>>>;
  fromCronTable?: Maybe<Scalars["String"]["output"]>;
  instanceId?: Maybe<Scalars["String"]["output"]>;
  instances?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  isDone?: Maybe<Scalars["Boolean"]["output"]>;
  jobOutput?: Maybe<Scalars["String"]["output"]>;
  jobParams?: Maybe<Scalars["String"]["output"]>;
  lastDone?: Maybe<Scalars["Boolean"]["output"]>;
  lastInstanceId?: Maybe<Scalars["String"]["output"]>;
  lastJobOutput?: Maybe<Scalars["String"]["output"]>;
  lastJobParams?: Maybe<Scalars["String"]["output"]>;
  lastOutputLogUri?: Maybe<Scalars["String"]["output"]>;
  lastOutputUri?: Maybe<Scalars["String"]["output"]>;
  lastStatus?: Maybe<JobStatusEnum>;
  lastUpdate?: Maybe<Scalars["String"]["output"]>;
  metadataDirUri?: Maybe<Scalars["String"]["output"]>;
  outputLogUri?: Maybe<Scalars["String"]["output"]>;
  outputUri?: Maybe<Scalars["String"]["output"]>;
  resumeOnRetry?: Maybe<Scalars["String"]["output"]>;
  retryable?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  uniqueName?: Maybe<Scalars["String"]["output"]>;
  websiteId?: Maybe<Scalars["String"]["output"]>;
};

export enum JobTypeEnum {
  Batch = "BATCH",
  BotifyBatch = "BOTIFY_BATCH",
  BotifyBatchImport = "BOTIFY_BATCH_IMPORT",
  BotifyCrawlImport = "BOTIFY_CRAWL_IMPORT",
  Cleanup = "CLEANUP",
  Cost = "COST",
  Cron = "CRON",
  ExplainIndex = "EXPLAIN_INDEX",
  Index = "INDEX",
  InputMerge = "INPUT_MERGE",
  InputRefresh = "INPUT_REFRESH",
  Inventory = "INVENTORY",
  InventoryStats = "INVENTORY_STATS",
  LinksDiscoveryCleanStream = "LINKS_DISCOVERY_CLEAN_STREAM",
  LinksDiscoveryMergedStream = "LINKS_DISCOVERY_MERGED_STREAM",
  LogsExport = "LOGS_EXPORT",
  StreamBatch = "STREAM_BATCH",
  StreamMaterialize = "STREAM_MATERIALIZE",
  SubmitBatchBuild = "SUBMIT_BATCH_BUILD",
  SubmitBatchExec = "SUBMIT_BATCH_EXEC",
  Unknown = "UNKNOWN",
  UpdateTokens = "UPDATE_TOKENS",
}

export type LudSearchResultType = {
  __typename?: "LUDSearchResultType";
  url?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["GenericScalar"]["output"]>;
};

export type LudUrlsCountType = {
  __typename?: "LUDUrlsCountType";
  count?: Maybe<Scalars["Int"]["output"]>;
  estimated?: Maybe<Scalars["Boolean"]["output"]>;
};

export type LinksDiscoveryDataType = {
  __typename?: "LinksDiscoveryDataType";
  count?: Maybe<Scalars["Int"]["output"]>;
  dateFirstSeen?: Maybe<Scalars["DateTime"]["output"]>;
  dateLastSeen?: Maybe<Scalars["DateTime"]["output"]>;
  sample?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type LiveTestOutputType = {
  __typename?: "LiveTestOutputType";
  device: DeviceEnum;
  reason?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<LiveTestStatusEnum>;
  url: Scalars["String"]["output"];
};

export enum LiveTestStatusEnum {
  Error = "ERROR",
  Fail = "FAIL",
  NotCached = "NOT_CACHED",
  OutOfScope = "OUT_OF_SCOPE",
  Success = "SUCCESS",
  Warning = "WARNING",
}

/** Live Update feed configuration */
export type LiveUpdateConfigurationType = {
  __typename?: "LiveUpdateConfigurationType";
  /** If true, a quote may appear in an unquoted field and a non-doubled quote may appear in a quoted field. */
  csvLazyQuotes: Scalars["Boolean"]["output"];
  /** Separator */
  csvSeparator: Scalars["String"]["output"];
  friendlyName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  /** If true, when a source feed is missing, the ingester will not treat it as an error. */
  ignoreFeedNotFoundError: Scalars["Boolean"]["output"];
  isActive: Scalars["Boolean"]["output"];
  lastIngestion?: Maybe<LiveUpdateIngestionType>;
  /** Rules to apply during ingestion to map fields from the original feed. */
  mapping: Scalars["String"]["output"];
  /** Cron-based syntax for refresh time and frequency (see https://crontab.guru) */
  schedule: Scalars["String"]["output"];
  /** List of the URLs to download the feed. */
  sourceUrls: Scalars["String"]["output"];
  stableId: Scalars["String"]["output"];
};

/** Live Update ingestion job output */
export type LiveUpdateIngestionType = {
  __typename?: "LiveUpdateIngestionType";
  feedId: Scalars["String"]["output"];
  finished: Scalars["Boolean"]["output"];
  ignoredCount: Scalars["Int"]["output"];
  ignoredSample: Array<IgnoredEntryType>;
  lastError: Scalars["String"]["output"];
  lastModified: Scalars["DateTime"]["output"];
  readCount: Scalars["Int"]["output"];
  readSample: Array<Scalars["String"]["output"]>;
  uniqueId: Scalars["String"]["output"];
  updateSkipped: Scalars["Boolean"]["output"];
  writtenBytes: Scalars["Int"]["output"];
  writtenCount: Scalars["Int"]["output"];
  writtenSample: Array<Scalars["String"]["output"]>;
};

export type LiveUpdateQueries = {
  __typename?: "LiveUpdateQueries";
  configuration?: Maybe<LiveUpdateConfigurationType>;
  configurations: Array<LiveUpdateConfigurationType>;
  ingestion?: Maybe<LiveUpdateIngestionType>;
  ingestions: Array<LiveUpdateIngestionType>;
};

export type LiveUpdateQueriesConfigurationArgs = {
  id: Scalars["ID"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type LiveUpdateQueriesConfigurationsArgs = {
  feedId?: InputMaybe<Scalars["ID"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  websiteId: Scalars["String"]["input"];
};

export type LiveUpdateQueriesIngestionArgs = {
  uniqueId: Scalars["ID"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type LiveUpdateQueriesIngestionsArgs = {
  feedId?: InputMaybe<Scalars["ID"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  websiteId: Scalars["String"]["input"];
};

export type ManageModuleTagsType = {
  actionType: ActionTypeEnum;
  id: Scalars["String"]["input"];
  moduleIds: Array<InputMaybe<Scalars["String"]["input"]>>;
};

/**
 * Add or Remove several modules
 *
 * [
 *     {
 *         tag_id : 'Y3XKDS'
 *         module_ids : [ 1, 3, 56]
 *         actionType: 'DELETE' | 'ADD'
 *     },
 *     ...
 * ]
 */
export type ManageModulesTags = {
  __typename?: "ManageModulesTags";
  moduleTags?: Maybe<Array<Maybe<ModuleTagType>>>;
};

/** An enumeration. */
export enum Mode {
  PurgeRefresh = "purgeRefresh",
  Refresh = "refresh",
}

/** An enumeration. */
export enum ModeChoices {
  Immutable = "IMMUTABLE",
  Incremental = "INCREMENTAL",
  Purge = "PURGE",
  Purgerefresh = "PURGEREFRESH",
  Refresh = "REFRESH",
}

export type ModelChangesType = {
  __typename?: "ModelChangesType";
  changedAt: Scalars["DateTime"]["output"];
  changedBy?: Maybe<ChangedByType>;
  status: ChangesStatus;
};

export enum ModuleBuildStatus {
  /** Failed */
  Failed = "FAILED",
  /** Running */
  Running = "RUNNING",
  /** Success */
  Success = "SUCCESS",
}

/** An enumeration. */
export enum ModuleDeployStatus {
  /** Failed */
  Failed = "FAILED",
  /** Running */
  Running = "RUNNING",
  /** Success */
  Success = "SUCCESS",
}

export enum ModuleRunningStatus {
  /** Live */
  Live = "LIVE",
  /** pause */
  Pause = "PAUSE",
}

export type ModuleTagType = {
  __typename?: "ModuleTagType";
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  modules: Array<ModuleType>;
  name?: Maybe<Scalars["String"]["output"]>;
  website: WebsiteType;
};

export type ModuleType = {
  __typename?: "ModuleType";
  /** Module is active or soft deleted */
  active: Scalars["Boolean"]["output"];
  buildError: Scalars["String"]["output"];
  buildStatus?: Maybe<ModuleBuildStatus>;
  comment: Scalars["String"]["output"];
  config: Scalars["String"]["output"];
  createdBy: Scalars["String"]["output"];
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  data: Scalars["String"]["output"];
  datasets?: Maybe<Array<Maybe<DatasetType>>>;
  dateCreated: Scalars["DateTime"]["output"];
  dateLastEdited: Scalars["DateTime"]["output"];
  dateOriginalCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  deployError: Scalars["String"]["output"];
  deployStatus?: Maybe<ModuleDeployStatus>;
  description: Scalars["String"]["output"];
  firstDeploymentDate?: Maybe<Scalars["DateTime"]["output"]>;
  hasUpdatedConfig?: Maybe<Scalars["Boolean"]["output"]>;
  hasUpdatedUrls: Scalars["Boolean"]["output"];
  /** This flag is set to true when we change the variables of the modules. It is then used to force the user to replace their data since it's probably incompatible */
  hasUpdatedVariables: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  /** Path to a cloud-stored KV store. It can be left `None` if the module doesn't need a KV store, or if it wasn't built yet. */
  kvPath?: Maybe<Scalars["String"]["output"]>;
  lastDeployedBy: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  moduleType: ModulesTypesChoices;
  name: Scalars["String"]["output"];
  priority: Scalars["Int"]["output"];
  productionModule?: Maybe<ModuleType>;
  /** JSON dict to override the reporting config, acceptable keys: search_console_table, logs_table, website_id, module_id */
  reportingOverrideConfig: Scalars["String"]["output"];
  runningStatus: ModuleRunningStatus;
  /** If set, this field controls the size (in percentage) of the variant group used for split testing. If left NULL, split testing won't be enabled for that module. */
  splitTestingVariantGroupSizePercentage?: Maybe<Scalars["Int"]["output"]>;
  stableId: Scalars["String"]["output"];
  tags: Array<ModuleTagType>;
  versionDatePublished?: Maybe<Scalars["DateTime"]["output"]>;
  versionNum: Scalars["Int"]["output"];
  versionStatus: ModuleVersionStatus;
  website: WebsiteType;
};

export enum ModuleVersionStatus {
  /** Archived */
  Archived = "ARCHIVED",
  /** Draft */
  Draft = "DRAFT",
  /** Production */
  Production = "PRODUCTION",
}

export type ModulesListType = {
  __typename?: "ModulesListType";
  id: Scalars["ID"]["output"];
  jsonschema: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export enum ModulesTypesChoices {
  /** Custom Js */
  CustomJs = "CUSTOM_JS",
  /** No-follow links */
  NoFollowLinks = "NO_FOLLOW_LINKS",
  /** PageEditor */
  PageEditor = "PAGE_EDITOR",
  /** Redirect By Page */
  Redirect = "REDIRECT",
  /** Remove Links from page */
  RemoveLinks = "REMOVE_LINKS",
}

export type Mutation = {
  __typename?: "Mutation";
  analyzeSectionRefreshImpact?: Maybe<AnalyzeSectionRefreshImpactMutation>;
  analyzeUrlsRefreshImpact?: Maybe<AnalyzeUrLsRefreshImpactMutation>;
  /**
   * `ArchiveModuleMutation` archives a module, identified by its `stable_id`, by:
   * - Setting the `version_status` of the production version from `production` to `archived`.
   * - Removing the existing draft version.
   */
  archiveModule?: Maybe<ArchiveModuleMutation>;
  checkSetup?: Maybe<CheckSetupMutation>;
  createAdnConfig?: Maybe<CreateAdnConfigMutation>;
  createBotifyAnalyticsAutomation?: Maybe<RegisterBotifyAnalyticsAutomationMutation>;
  createBotifyAnalyticsImport?: Maybe<CreateBotifyAnalyticsImportMutation>;
  /** Generate a signed url to download the optimization result. */
  createDownloadLinkHtmlHeadOptimization?: Maybe<CreateDownloadLinkHtmlHeadOptimization>;
  createDraftVersion?: Maybe<WebsiteType>;
  /** Create a new Html Head optimization and launch the preview generation */
  createHtmlHeadOptimization?: Maybe<CreateHtmlHeadOptimizationMutation>;
  createImportJob?: Maybe<CreateImportJobMutation>;
  createInputBotsDiscovery?: Maybe<CreateInputBotsDiscoveryMutation>;
  createInputCsv?: Maybe<CreateInputCsvMutation>;
  createInputLinksDiscovery?: Maybe<CreateInputLinksDiscoveryMutation>;
  createInputManualUpload?: Maybe<CreateInputManualUploadMutation>;
  createInputSitemap?: Maybe<CreateInputSitemapMutation>;
  createInputTextList?: Maybe<CreateInputTextListMutation>;
  createJsCode?: Maybe<CreateJsCodeMutation>;
  createModule?: Maybe<CreateModuleMutation>;
  /** Create a new Module Tag */
  createModuleTag?: Maybe<CreateModuleTagMutation>;
  /** Generate a link for LUD export */
  createModuleUrlsExportLink?: Maybe<CreateModuleUrlsExportLinkMutation>;
  /** Create a LUD dataset increment (Add/Edit/Remove) */
  createModuleUrlsIncrement?: Maybe<CreateModuleUrlsIncrementMutation>;
  /** Generate a link for LUD export */
  createModulesUrlsExportLink?: Maybe<CreateModuleUrlsExportLinkMutation>;
  createQualityControlLiveTestJob?: Maybe<QualityControlLiveTestJobType>;
  createSection?: Maybe<CreateSectionMutation>;
  createSignedUrl?: Maybe<CreateSignedUrlMutation>;
  createSmartlinkOptimization?: Maybe<CreateSmartLinkOptimizationMutation>;
  /** Generate a signed url to download the optimization result. */
  createSmartlinkResultDownloadLink?: Maybe<CreateSmartLinkResultDownloadLink>;
  createSmartlinkSignedUrlForUpload?: Maybe<CreateSmartLinkSignedUrlForUpload>;
  createWebsite?: Maybe<CreateWebsiteMutation>;
  deleteDraftVersion?: Maybe<WebsiteType>;
  deleteHtmlHeadOptimization?: Maybe<DeleteHtmlHeadOptimization>;
  deleteInputBotsDiscovery?: Maybe<DeleteBotsDiscoveryInputMutation>;
  deleteInputCsv?: Maybe<DeleteCsvInputMutation>;
  deleteInputLinksDiscovery?: Maybe<DeleteLinksDiscoveryInputMutation>;
  deleteInputManualUpload?: Maybe<DeleteManualUploadInputMutation>;
  deleteInputSitemap?: Maybe<DeleteSitemapInputMutation>;
  deleteInputTextList?: Maybe<DeleteTextListInputMutation>;
  deleteInterceptorChecker?: Maybe<DeleteInterceptorCheckerMutation>;
  deleteJsCode?: Maybe<JsCodeType>;
  /** Delete an existing Module Tag */
  deleteModuleTag?: Maybe<DeleteModuleTagMutation>;
  deleteQualityControl?: Maybe<DeleteQualityControlMutation>;
  deleteSection?: Maybe<DeleteSectionMutation>;
  deleteSmartlinkOptimization?: Maybe<DeleteSmartLinkOptimization>;
  deleteWebsite?: Maybe<WebsiteType>;
  deployAdnConfig?: Maybe<DeployAdnConfigMutation>;
  fetchBotifyProjectLink?: Maybe<FetchBotifyProjectLink>;
  forceSectionRefresh?: Maybe<ForceSectionRefreshMutation>;
  forceUrlsRefresh?: Maybe<ForceUrlsRefreshMutation>;
  internalCreateSection?: Maybe<InternalCreateSectionMutation>;
  internalUpdateSection?: Maybe<InternalUpdateSectionMutation>;
  /**
   * Add or Remove several modules
   *
   * [
   *     {
   *         tag_id : 'Y3XKDS'
   *         module_ids : [ 1, 3, 56]
   *         actionType: 'DELETE' | 'ADD'
   *     },
   *     ...
   * ]
   */
  manageModulesTags?: Maybe<ManageModulesTags>;
  orderJsCode?: Maybe<Array<Maybe<JsCodeType>>>;
  /**
   * `PauseModuleMutation` pauses a module, identified by its `stable_id`.
   * To do so, it will simply set `running_status=pause` on all versions of the module.
   */
  pauseModule?: Maybe<PauseModuleMutation>;
  previewCpap?: Maybe<PreviewCpapMutation>;
  /**
   * PreviewElementDescriptorMutation does almost the same thing as PreviewExtractsMutation, except it takes only a list
   * of css selectors, and for each one, returns a descriptor if the element is found, else an error.
   */
  previewElementDescriptor?: Maybe<
    Array<Maybe<PreviewElementDescriptorResultType>>
  >;
  /**
   * PreviewExtractsMutation allows running PageWorkers' extracts logic on an arbitrary page, without
   * the need for a saved module. It should be used during modules' creation flow, to quickly assert
   * if an extract is correctly configured, and reassure the user.
   */
  previewExtracts?: Maybe<Array<Maybe<PreviewExtractsResultType>>>;
  previewJs?: Maybe<PreviewJsMutation>;
  /**
   * PreviewExtractsMutation allows running PageWorkers' extracts logic on an arbitrary page, without
   * the need for a saved module. It should be used during modules' creation flow, to quickly assert
   * if an extract is correctly configured, and reassure the user.
   */
  previewPageworkersExtracts?: Maybe<Array<Maybe<PreviewExtractsResultType>>>;
  /**
   * PreviewTemplatesMutation allows compiling a template to get a preview by applying
   * extracts and variables. It should be used during modules' creation flow to quickly assert
   * if a template is valid and if all the extracts and variables are found.
   */
  previewPageworkersTemplates?: Maybe<Array<Maybe<PreviewTemplatesResultType>>>;
  pushDraftToProduction?: Maybe<WebsiteType>;
  pushModuleToProduction?: Maybe<PushModuleToProductionMutation>;
  pwOffboarding?: Maybe<PwOffboarding>;
  refreshToken?: Maybe<Refresh>;
  requestManualUploadPresignedUrl?: Maybe<RequestManualUploadPresignedUrlMutation>;
  revertToVersion?: Maybe<WebsiteType>;
  rollbackAdnConfig?: Maybe<RollbackAdnConfigMutation>;
  rollbackAllAdnConfigs?: Maybe<RollbackAllAdnConfigsMutation>;
  /**
   * `RollbackModuleMutation` resets the current module draft's configuration to that of
   * the current production module's configuration.
   */
  rollbackModuleMutation?: Maybe<RollbackModuleMutation>;
  runAllInterceptorCheckers?: Maybe<RunAllInterceptorCheckersMutation>;
  saveInterceptorChecker?: Maybe<InterceptorCheckerConfigType>;
  saveQualityControl?: Maybe<QualityControlType>;
  sectionMove?: Maybe<SectionMoveMutation>;
  toggleInputBotsDiscovery?: Maybe<InputBotsDiscoveryType>;
  toggleInputCsv?: Maybe<InputCsvType>;
  toggleInputLinksDiscovery?: Maybe<InputLinksDiscoveryType>;
  toggleInputManualUpload?: Maybe<InputManualUploadType>;
  toggleInputSitemap?: Maybe<InputSitemapType>;
  toggleInputTextList?: Maybe<InputTextListType>;
  togglePwTagDelivery?: Maybe<WebsiteType>;
  toggleQualityControl?: Maybe<QualityControlType>;
  toggleSwDelivery?: Maybe<WebsiteType>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  triggerFullGenerationHtmlHeadOptimization?: Maybe<TriggerFullGenerationHtmlHeadOptimization>;
  /** `UnarchiveModuleMutation` restores an archived module, identified by its `stable_id`. */
  unarchiveModule?: Maybe<UnarchiveModuleMutation>;
  /**
   * `UnpauseModuleMutation` reactivates a paused module, identified by its `stable_id`.
   * To do so, it will simply set `running_status=live` on all versions of the module.
   */
  unpauseModule?: Maybe<UnpauseModuleMutation>;
  updateAdnConfig?: Maybe<UpdateAdnConfigMutation>;
  updateBaConfig?: Maybe<UpdateBaConfigCodeMutation>;
  updateConfig?: Maybe<ConfigMutation>;
  /** Update a new Html Head optimization and launch the preview generation if needed */
  updateHtmlHeadOptimization?: Maybe<UpdateHtmlHeadOptimizationMutation>;
  updateInputBotsDiscovery?: Maybe<UpdateInputBotsDiscoveryMutation>;
  updateInputCsv?: Maybe<UpdateInputCsvMutation>;
  updateInputLinksDiscovery?: Maybe<UpdateInputLinksDiscoveryMutation>;
  updateInputManualUpload?: Maybe<UpdateInputManualUploadMutation>;
  updateInputSitemap?: Maybe<UpdateInputSitemapMutation>;
  updateInputTextList?: Maybe<UpdateInputTextListMutation>;
  updateJsCode?: Maybe<UpdateJsCodeMutation>;
  updateModule?: Maybe<UpdateModuleMutation>;
  /** Update an existing Module Tag */
  updateModuleTag?: Maybe<UpdateModuleTagMutation>;
  updateSection?: Maybe<UpdateSectionMutation>;
  updateSmartlinkOptimization?: Maybe<UpdateSmartLinkOptimizationMutation>;
  updateVersionComment?: Maybe<UpdateVersionCommentMutation>;
  updateWebsiteBasicSettings?: Maybe<UpdateWebsiteBasicSettingsMutation>;
  updateWebsiteSettings?: Maybe<UpdateWebsiteSettingsMutation>;
  urlTester?: Maybe<UrlTesterMutation>;
  verifyToken?: Maybe<Verify>;
};

export type MutationAnalyzeSectionRefreshImpactArgs = {
  sectionId: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationAnalyzeUrlsRefreshImpactArgs = {
  urls: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationArchiveModuleArgs = {
  moduleStableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCheckSetupArgs = {
  input: CheckSetupMutationInput;
};

export type MutationCreateAdnConfigArgs = {
  input: CreateAdnConfigMutationInput;
};

export type MutationCreateBotifyAnalyticsAutomationArgs = {
  input: RegisterBotifyAnalyticsAutomationMutationInput;
};

export type MutationCreateBotifyAnalyticsImportArgs = {
  input: CreateBotifyAnalyticsImportMutationInput;
};

export type MutationCreateDownloadLinkHtmlHeadOptimizationArgs = {
  forcedDownloadFilename?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateDraftVersionArgs = {
  baseVersion: Scalars["ID"]["input"];
  website: Scalars["ID"]["input"];
};

export type MutationCreateHtmlHeadOptimizationArgs = {
  companyDescription: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  forbiddenKeywords?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  scope: Scalars["JSONString"]["input"];
  targetAudience: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateImportJobArgs = {
  input: CreateImportJobMutationInput;
};

export type MutationCreateInputBotsDiscoveryArgs = {
  input: CreateInputBotsDiscoveryMutationInput;
};

export type MutationCreateInputCsvArgs = {
  input: CreateInputCsvMutationInput;
};

export type MutationCreateInputLinksDiscoveryArgs = {
  input: CreateInputLinksDiscoveryMutationInput;
};

export type MutationCreateInputManualUploadArgs = {
  input: CreateInputManualUploadMutationInput;
};

export type MutationCreateInputSitemapArgs = {
  input: CreateInputSitemapMutationInput;
};

export type MutationCreateInputTextListArgs = {
  input: CreateInputTextListMutationInput;
};

export type MutationCreateJsCodeArgs = {
  input: CreateJsCodeMutationInput;
};

export type MutationCreateModuleArgs = {
  input: CreateModuleMutationInput;
};

export type MutationCreateModuleTagArgs = {
  name: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateModuleUrlsExportLinkArgs = {
  jwt?: InputMaybe<Scalars["String"]["input"]>;
  stableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateModuleUrlsIncrementArgs = {
  data: Scalars["JSONString"]["input"];
  jwt?: InputMaybe<Scalars["String"]["input"]>;
  stableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateModulesUrlsExportLinkArgs = {
  jwt?: InputMaybe<Scalars["String"]["input"]>;
  stableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateQualityControlLiveTestJobArgs = {
  device: DeviceEnum;
  scope: Scalars["JSONString"]["input"];
  strategySearchElement?: InputMaybe<StrategySearchElementInputType>;
  strategySearchText?: InputMaybe<StrategySearchTextInputType>;
  urls: Array<InputMaybe<Scalars["String"]["input"]>>;
  website: Scalars["ID"]["input"];
};

export type MutationCreateSectionArgs = {
  input: CreateSectionMutationInput;
};

export type MutationCreateSignedUrlArgs = {
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateSmartlinkOptimizationArgs = {
  addAlternateLinks?: InputMaybe<Scalars["Boolean"]["input"]>;
  addAmp?: InputMaybe<Scalars["Boolean"]["input"]>;
  addCanonicals?: InputMaybe<Scalars["Boolean"]["input"]>;
  addHreflang?: InputMaybe<Scalars["Boolean"]["input"]>;
  crawlEndDate: Scalars["String"]["input"];
  crawlId: Scalars["String"]["input"];
  crawlLinksFeatureId: Scalars["String"]["input"];
  crawlRevisionId: Scalars["String"]["input"];
  dataDays?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  destinationUrlsCsv: Scalars["String"]["input"];
  gcpProjectId: Scalars["String"]["input"];
  homePages: Array<InputMaybe<Scalars["String"]["input"]>>;
  maxInlinksToAdd: Scalars["Int"]["input"];
  maxOutlinksToAdd: Scalars["Int"]["input"];
  previousLinksStrategy?: InputMaybe<PreviousLinksStrategyChoices>;
  title: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateSmartlinkResultDownloadLinkArgs = {
  forcedFilename?: InputMaybe<Scalars["String"]["input"]>;
  optimizationId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateSmartlinkSignedUrlForUploadArgs = {
  websiteId: Scalars["ID"]["input"];
};

export type MutationCreateWebsiteArgs = {
  input: CreateWebsiteMutationInput;
};

export type MutationDeleteDraftVersionArgs = {
  website: Scalars["ID"]["input"];
};

export type MutationDeleteHtmlHeadOptimizationArgs = {
  id: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationDeleteInputBotsDiscoveryArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInputCsvArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInputLinksDiscoveryArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInputManualUploadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInputSitemapArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInputTextListArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInterceptorCheckerArgs = {
  id: Scalars["ID"]["input"];
  website: Scalars["ID"]["input"];
};

export type MutationDeleteJsCodeArgs = {
  jsCode: Scalars["ID"]["input"];
  websiteVersion: Scalars["ID"]["input"];
};

export type MutationDeleteModuleTagArgs = {
  id: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationDeleteQualityControlArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteSectionArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteSmartlinkOptimizationArgs = {
  optimizationId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationDeleteWebsiteArgs = {
  website: Scalars["ID"]["input"];
};

export type MutationDeployAdnConfigArgs = {
  configId: Scalars["ID"]["input"];
  environment: AdnEnvironment;
};

export type MutationFetchBotifyProjectLinkArgs = {
  website: Scalars["ID"]["input"];
};

export type MutationForceSectionRefreshArgs = {
  forceRefreshToken: Scalars["String"]["input"];
  options?: InputMaybe<ForceRefreshOptions>;
  sectionId: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationForceUrlsRefreshArgs = {
  forceRefreshToken: Scalars["String"]["input"];
  options?: InputMaybe<ForceRefreshOptions>;
  urls: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationInternalCreateSectionArgs = {
  input: InternalCreateSectionMutationInput;
};

export type MutationInternalUpdateSectionArgs = {
  input: InternalUpdateSectionMutationInput;
};

export type MutationManageModulesTagsArgs = {
  actionsList?: InputMaybe<Array<InputMaybe<ManageModuleTagsType>>>;
  websiteId: Scalars["ID"]["input"];
};

export type MutationOrderJsCodeArgs = {
  codeOrder: Array<Scalars["ID"]["input"]>;
  websiteVersion: Scalars["ID"]["input"];
};

export type MutationPauseModuleArgs = {
  moduleStableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationPreviewCpapArgs = {
  input: PreviewCpapMutationInput;
};

export type MutationPreviewElementDescriptorArgs = {
  cssSelectors?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type MutationPreviewExtractsArgs = {
  extracts: Scalars["JSONString"]["input"];
  url: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type MutationPreviewJsArgs = {
  input: PreviewJsMutationInput;
};

export type MutationPreviewPageworkersExtractsArgs = {
  extracts: Scalars["JSONString"]["input"];
  url: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type MutationPreviewPageworkersTemplatesArgs = {
  templates: Scalars["JSONString"]["input"];
  url: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type MutationPushDraftToProductionArgs = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  website: Scalars["ID"]["input"];
};

export type MutationPushModuleToProductionArgs = {
  input: PushModuleToProductionMutationInput;
};

export type MutationPwOffboardingArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRequestManualUploadPresignedUrlArgs = {
  website: Scalars["ID"]["input"];
};

export type MutationRevertToVersionArgs = {
  targetVersion: Scalars["ID"]["input"];
  website: Scalars["ID"]["input"];
};

export type MutationRollbackAdnConfigArgs = {
  environment: AdnEnvironment;
  website: Scalars["ID"]["input"];
};

export type MutationRollbackAllAdnConfigsArgs = {
  datetimeStr?: InputMaybe<Scalars["String"]["input"]>;
  environment: AdnEnvironment;
};

export type MutationRollbackModuleMutationArgs = {
  moduleStableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationRunAllInterceptorCheckersArgs = {
  website: Scalars["ID"]["input"];
};

export type MutationSaveInterceptorCheckerArgs = {
  checksToPerform: Array<InputMaybe<InterceptorChecksEnum>>;
  extraHeaders?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  interceptorTimeout?: InputMaybe<Scalars["Int"]["input"]>;
  url: Scalars["String"]["input"];
  userAgentTestBot: Scalars["String"]["input"];
  website: Scalars["ID"]["input"];
};

export type MutationSaveQualityControlArgs = {
  active: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  device: DeviceEnum;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  scope: Scalars["JSONString"]["input"];
  severity: SeverityEnum;
  strategySearchElement?: InputMaybe<StrategySearchElementInputType>;
  strategySearchText?: InputMaybe<StrategySearchTextInputType>;
  testUrls?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  website: Scalars["ID"]["input"];
  websiteVersion: Scalars["ID"]["input"];
};

export type MutationSectionMoveArgs = {
  input: SectionMoveMutationInput;
};

export type MutationToggleInputBotsDiscoveryArgs = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  ready?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationToggleInputCsvArgs = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  ready?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationToggleInputLinksDiscoveryArgs = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  ready?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationToggleInputManualUploadArgs = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  ready?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationToggleInputSitemapArgs = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  ready?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationToggleInputTextListArgs = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  ready?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationTogglePwTagDeliveryArgs = {
  active: Scalars["Boolean"]["input"];
  website: Scalars["ID"]["input"];
};

export type MutationToggleQualityControlArgs = {
  active: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationToggleSwDeliveryArgs = {
  active: Scalars["Boolean"]["input"];
  website: Scalars["ID"]["input"];
};

export type MutationTokenAuthArgs = {
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type MutationTriggerFullGenerationHtmlHeadOptimizationArgs = {
  id: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationUnarchiveModuleArgs = {
  moduleStableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationUnpauseModuleArgs = {
  moduleStableId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationUpdateAdnConfigArgs = {
  input: UpdateAdnConfigMutationInput;
};

export type MutationUpdateBaConfigArgs = {
  input: UpdateBaConfigCodeMutationInput;
};

export type MutationUpdateConfigArgs = {
  input: ConfigMutationInput;
};

export type MutationUpdateHtmlHeadOptimizationArgs = {
  companyDescription?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  forbiddenKeywords?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id: Scalars["ID"]["input"];
  scope?: InputMaybe<Scalars["JSONString"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  websiteId: Scalars["ID"]["input"];
};

export type MutationUpdateInputBotsDiscoveryArgs = {
  input: UpdateInputBotsDiscoveryMutationInput;
};

export type MutationUpdateInputCsvArgs = {
  input: UpdateInputCsvMutationInput;
};

export type MutationUpdateInputLinksDiscoveryArgs = {
  input: UpdateInputLinksDiscoveryMutationInput;
};

export type MutationUpdateInputManualUploadArgs = {
  input: UpdateInputManualUploadMutationInput;
};

export type MutationUpdateInputSitemapArgs = {
  input: UpdateInputSitemapMutationInput;
};

export type MutationUpdateInputTextListArgs = {
  input: UpdateInputTextListMutationInput;
};

export type MutationUpdateJsCodeArgs = {
  input: UpdateJsCodeMutationInput;
};

export type MutationUpdateModuleArgs = {
  input: UpdateModuleMutationInput;
};

export type MutationUpdateModuleTagArgs = {
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type MutationUpdateSectionArgs = {
  input: UpdateSectionMutationInput;
};

export type MutationUpdateSmartlinkOptimizationArgs = {
  addAlternateLinks?: InputMaybe<Scalars["Boolean"]["input"]>;
  addAmp?: InputMaybe<Scalars["Boolean"]["input"]>;
  addCanonicals?: InputMaybe<Scalars["Boolean"]["input"]>;
  addHreflang?: InputMaybe<Scalars["Boolean"]["input"]>;
  crawlEndDate: Scalars["String"]["input"];
  crawlId: Scalars["String"]["input"];
  crawlLinksFeatureId: Scalars["String"]["input"];
  crawlRevisionId: Scalars["String"]["input"];
  dataDays?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  destinationUrlsCsv: Scalars["String"]["input"];
  gcpProjectId: Scalars["String"]["input"];
  homePages: Array<InputMaybe<Scalars["String"]["input"]>>;
  maxInlinksToAdd: Scalars["Int"]["input"];
  maxOutlinksToAdd: Scalars["Int"]["input"];
  optimizationId: Scalars["ID"]["input"];
  previousLinksStrategy?: InputMaybe<PreviousLinksStrategyChoices>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  websiteId: Scalars["ID"]["input"];
};

export type MutationUpdateVersionCommentArgs = {
  input: UpdateVersionCommentMutationInput;
};

export type MutationUpdateWebsiteBasicSettingsArgs = {
  input: UpdateWebsiteBasicSettingsMutationInput;
};

export type MutationUpdateWebsiteSettingsArgs = {
  input: UpdateWebsiteSettingsMutationInput;
};

export type MutationUrlTesterArgs = {
  overloadedSection?: InputMaybe<SectionInput>;
  urls: Array<InputMaybe<Scalars["String"]["input"]>>;
  website: Scalars["ID"]["input"];
  websiteVersionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** An enumeration. */
export enum NotDeliverableReason {
  BadToken = "bad_token",
  BlockedBySection = "blocked_by_section",
  Cached = "cached",
  CustomResponse = "custom_response",
  Delivered = "delivered",
  DeliveryStopped = "delivery_stopped",
  InternalError = "internal_error",
  InternalError_15 = "internal_error_15",
  InvalidRedirect = "invalid_redirect",
  InvalidRequest = "invalid_request",
  IpFiltered = "ip_filtered",
  JsCodeError = "js_code_error",
  JsCodeTimeout = "js_code_timeout",
  JsForcedCacheMiss = "js_forced_cache_miss",
  JsRedirectNotEnabled = "js_redirect_not_enabled",
  JsRedirectSameUrl = "js_redirect_same_url",
  LastRefresh_4xx = "last_refresh_4xx",
  LastRefresh_5xx = "last_refresh_5xx",
  LiveRenderFailed = "live_render_failed",
  LiveRenderNotConnected = "live_render_not_connected",
  LiveRenderRateLimited = "live_render_rate_limited",
  LiveRenderStartTimeout = "live_render_start_timeout",
  LiveRenderStartTimeoutTooSmall = "live_render_start_timeout_too_small",
  LiveRenderTimeout = "live_render_timeout",
  NormalizedUrlFailed = "normalized_url_failed",
  NotCached = "not_cached",
  NotHtml = "not_html",
  OriginServerError = "origin_server_error",
  OutOfScope = "out_of_scope",
  QualityControlsFailed = "quality_controls_failed",
  RedirectLoop = "redirect_loop",
  RedirectToForbiddenHost = "redirect_to_forbidden_host",
  RenderingFailed = "rendering_failed",
  RenderingTimeout = "rendering_timeout",
  RequestOriginTimeout = "request_origin_timeout",
  RequestTimeout = "request_timeout",
  ServerOverloaded = "server_overloaded",
  Throttled = "throttled",
  UnsupportedMethod = "unsupported_method",
  WebsocketNotSupported = "websocket_not_supported",
}

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: "ObtainJSONWebToken";
  payload: Scalars["GenericScalar"]["output"];
  refreshExpiresIn: Scalars["Int"]["output"];
  token: Scalars["String"]["output"];
};

/** An enumeration. */
export enum PageEditDevice {
  /** Desktop */
  Desktop = "DESKTOP",
  /** Desktop and mobile */
  DesktopAndMobile = "DESKTOP_AND_MOBILE",
  /** Mobile */
  Mobile = "MOBILE",
}

export type PageEditPaginatedType = {
  __typename?: "PageEditPaginatedType";
  hasNext?: Maybe<Scalars["Boolean"]["output"]>;
  hasPrev?: Maybe<Scalars["Boolean"]["output"]>;
  objects?: Maybe<Array<Maybe<PageEditType>>>;
  page?: Maybe<Scalars["Int"]["output"]>;
  pages?: Maybe<Scalars["Int"]["output"]>;
};

export type PageEditType = {
  __typename?: "PageEditType";
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  dateCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  device: PageEditDevice;
  h1: Scalars["String"]["output"];
  htmlBlocks: Array<HtmlBlockType>;
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  permanentRedirect: Scalars["String"]["output"];
  temporaryRedirect: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  website: WebsiteType;
};

export type PageworkersClusterType = {
  __typename?: "PageworkersClusterType";
  /** If set, keep the website in pw-websites.json for this alternate cluster (this can be useful for smooth migrations) */
  alternateWebsites: Array<WebsiteType>;
  comments: Scalars["String"]["output"];
  /** if set, we are going to set (after save) all deliveryapi instances of the cluster in 'continuous profiling' mode with (little) performances impacts */
  continuousProfilingEnabled: Scalars["Boolean"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  deliveryApiHost: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  region?: Maybe<Scalars["String"]["output"]>;
  websites: Array<WebsiteType>;
};

export type PaginationInfoType = {
  __typename?: "PaginationInfoType";
  count: Scalars["Int"]["output"];
  page: Scalars["Int"]["output"];
  size: Scalars["Int"]["output"];
};

export type PaginationTypeInput = {
  page: Scalars["Int"]["input"];
  size: Scalars["Int"]["input"];
};

/**
 * `PauseModuleMutation` pauses a module, identified by its `stable_id`.
 * To do so, it will simply set `running_status=pause` on all versions of the module.
 */
export type PauseModuleMutation = {
  __typename?: "PauseModuleMutation";
  error?: Maybe<Scalars["String"]["output"]>;
  module?: Maybe<ModuleType>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type PreviewCpapMutation = {
  __typename?: "PreviewCpapMutation";
  code?: Maybe<Scalars["String"]["output"]>;
  device: Scalars["String"]["output"];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  headers?: Maybe<Array<Maybe<Array<Maybe<Scalars["String"]["output"]>>>>>;
  html?: Maybe<Scalars["String"]["output"]>;
  jsError?: Maybe<Scalars["String"]["output"]>;
  jsRules?: Maybe<Scalars["String"]["output"]>;
  logs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  onLoadCode?: Maybe<Scalars["String"]["output"]>;
  screenshot?: Maybe<Scalars["String"]["output"]>;
  stats?: Maybe<Scalars["JSONString"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
  website: Scalars["String"]["output"];
};

export type PreviewCpapMutationInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  device: Scalars["String"]["input"];
  jsRules?: InputMaybe<Scalars["String"]["input"]>;
  onLoadCode?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
  website: Scalars["String"]["input"];
};

export type PreviewDispatcherType = {
  __typename?: "PreviewDispatcherType";
  beamProcessingTime?: Maybe<Scalars["Int"]["output"]>;
  deliveredFrom?: Maybe<Scalars["String"]["output"]>;
  headers?: Maybe<Array<Maybe<Array<Maybe<Scalars["String"]["output"]>>>>>;
  html?: Maybe<Scalars["String"]["output"]>;
  htmlUpdates?: Maybe<HtmlUpdatesType>;
  inputs?: Maybe<Array<Maybe<InputDescriptor>>>;
  jsError?: Maybe<Scalars["String"]["output"]>;
  lastIndexTime?: Maybe<Scalars["Int"]["output"]>;
  logs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  notDeliveredReason?: Maybe<Scalars["String"]["output"]>;
  originalProcessingTime?: Maybe<Scalars["Int"]["output"]>;
  properties?: Maybe<Scalars["JSONString"]["output"]>;
  qualityControls?: Maybe<Array<Maybe<QualityControlPreviewType>>>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
  refreshTime?: Maybe<Scalars["Int"]["output"]>;
  section?: Maybe<SectionType>;
  stats?: Maybe<Scalars["JSONString"]["output"]>;
  statusCode?: Maybe<Scalars["Int"]["output"]>;
};

export type PreviewElementDescriptorResultType = {
  __typename?: "PreviewElementDescriptorResultType";
  descriptor?: Maybe<ElementDescriptorType>;
  error?: Maybe<Scalars["String"]["output"]>;
  matchesCount?: Maybe<Scalars["Int"]["output"]>;
};

export type PreviewExtractsResultType = {
  __typename?: "PreviewExtractsResultType";
  error?: Maybe<Scalars["String"]["output"]>;
  matchesCount?: Maybe<Scalars["Int"]["output"]>;
  result?: Maybe<Scalars["String"]["output"]>;
  truncated?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PreviewJsMutation = {
  __typename?: "PreviewJsMutation";
  configId?: Maybe<Scalars["String"]["output"]>;
  deliveredFrom?: Maybe<Scalars["String"]["output"]>;
  device?: Maybe<PreviewerConfigDevice>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  headers?: Maybe<Array<Array<Maybe<Scalars["String"]["output"]>>>>;
  html?: Maybe<Scalars["String"]["output"]>;
  jsCodePreBeamResponse?: Maybe<Scalars["String"]["output"]>;
  jsCodeRendering?: Maybe<Scalars["String"]["output"]>;
  jsError?: Maybe<Scalars["String"]["output"]>;
  logs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  preBeamResponseCode?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
  renderingDomContentLoadedCode?: Maybe<Scalars["String"]["output"]>;
  renderingOnActionCode?: Maybe<Scalars["String"]["output"]>;
  renderingOnInitCode?: Maybe<Scalars["String"]["output"]>;
  renderingOnSetupBeforeRenderCode?: Maybe<Scalars["String"]["output"]>;
  renderingOnWaitForCode?: Maybe<Scalars["String"]["output"]>;
  renderingOndoneCode?: Maybe<Scalars["String"]["output"]>;
  renderingOnloadCode?: Maybe<Scalars["String"]["output"]>;
  serverError?: Maybe<Scalars["String"]["output"]>;
  snippetId?: Maybe<Scalars["ID"]["output"]>;
  snippetName?: Maybe<Scalars["String"]["output"]>;
  stats?: Maybe<Scalars["JSONString"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusCode?: Maybe<Scalars["Int"]["output"]>;
  url: Scalars["String"]["output"];
  website: Scalars["String"]["output"];
};

export type PreviewJsMutationInput = {
  configId?: InputMaybe<Scalars["String"]["input"]>;
  device?: InputMaybe<PreviewerConfigDevice>;
  preBeamResponseCode?: InputMaybe<Scalars["String"]["input"]>;
  renderingDomContentLoadedCode?: InputMaybe<Scalars["String"]["input"]>;
  renderingOnActionCode?: InputMaybe<Scalars["String"]["input"]>;
  renderingOnInitCode?: InputMaybe<Scalars["String"]["input"]>;
  renderingOnSetupBeforeRenderCode?: InputMaybe<Scalars["String"]["input"]>;
  renderingOnWaitForCode?: InputMaybe<Scalars["String"]["input"]>;
  renderingOndoneCode?: InputMaybe<Scalars["String"]["input"]>;
  renderingOnloadCode?: InputMaybe<Scalars["String"]["input"]>;
  snippetId?: InputMaybe<Scalars["ID"]["input"]>;
  snippetName?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
  website: Scalars["String"]["input"];
};

export type PreviewTemplatesResultType = {
  __typename?: "PreviewTemplatesResultType";
  error?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<Scalars["String"]["output"]>;
  truncated?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum PreviewerConfigDevice {
  Desktop = "DESKTOP",
  Mobile = "MOBILE",
}

export enum PreviousLinksStrategyChoices {
  /** Clean */
  Clean = "CLEAN",
  /** Clean in Scope */
  CleanInScope = "CLEAN_IN_SCOPE",
  /** Remove */
  Remove = "REMOVE",
}

export type PushModuleToProductionMutation = {
  __typename?: "PushModuleToProductionMutation";
  module?: Maybe<ModuleType>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type PushModuleToProductionMutationInput = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  moduleId: Scalars["ID"]["input"];
  websiteId: Scalars["ID"]["input"];
};

export type PwOffboarding = {
  __typename?: "PwOffboarding";
  status?: Maybe<Scalars["String"]["output"]>;
};

export enum QualityControlDevice {
  /** Desktop */
  Desktop = "DESKTOP",
  /** Desktop and mobile */
  DesktopAndMobile = "DESKTOP_AND_MOBILE",
  /** Mobile */
  Mobile = "MOBILE",
}

export enum QualityControlLiveTestJobStatus {
  /** Created */
  Created = "CREATED",
  /** Done */
  Done = "DONE",
  /** Failed */
  Failed = "FAILED",
  /** Running */
  Running = "RUNNING",
}

export type QualityControlLiveTestJobType = {
  __typename?: "QualityControlLiveTestJobType";
  id: Scalars["String"]["output"];
  result?: Maybe<Array<Maybe<LiveTestOutputType>>>;
  status: QualityControlLiveTestJobStatus;
};

export type QualityControlPreviewType = {
  __typename?: "QualityControlPreviewType";
  active: Scalars["Boolean"]["output"];
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  device: QualityControlDevice;
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  scope?: Maybe<Scalars["JSONString"]["output"]>;
  severity: QualityControlSeverity;
  stableId: Scalars["String"]["output"];
  status?: Maybe<Scalars["Int"]["output"]>;
  strategyId: Scalars["String"]["output"];
  strategySearchElement?: Maybe<StrategySearchElementType>;
  strategySearchText?: Maybe<StrategySearchTextType>;
  strategyType?: Maybe<StrategyTypeEnum>;
  testUrls?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  website: WebsiteType;
  websiteVersion?: Maybe<WebsiteVersionType>;
};

export enum QualityControlSeverity {
  /** Fail */
  Fail = "FAIL",
  /** Warning */
  Warning = "WARNING",
}

export type QualityControlType = {
  __typename?: "QualityControlType";
  active: Scalars["Boolean"]["output"];
  changes?: Maybe<ModelChangesType>;
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  device: QualityControlDevice;
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  scope?: Maybe<Scalars["JSONString"]["output"]>;
  severity: QualityControlSeverity;
  stableId: Scalars["String"]["output"];
  strategyId: Scalars["String"]["output"];
  strategySearchElement?: Maybe<StrategySearchElementType>;
  strategySearchText?: Maybe<StrategySearchTextType>;
  strategyType?: Maybe<StrategyTypeEnum>;
  testUrls?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  website: WebsiteType;
  websiteVersion?: Maybe<WebsiteVersionType>;
};

export type Query = {
  __typename?: "Query";
  EXPERIMENTALModuleReportingMetrics?: Maybe<
    Array<Maybe<Experimental_ReportingMetricsType>>
  >;
  adnConfig?: Maybe<AdnConfigType>;
  adnVersions: Array<AdnVersionsType>;
  allClusters?: Maybe<Array<ClusterType>>;
  allPageworkersClusters?: Maybe<Array<PageworkersClusterType>>;
  allWebsites: Array<WebsiteType>;
  cluster?: Maybe<ClusterType>;
  currentUser?: Maybe<UserType>;
  importJob?: Maybe<ImportJobType>;
  inputBotsDiscovery?: Maybe<InputBotsDiscoveryType>;
  inputCsv?: Maybe<InputCsvType>;
  inputLinksDiscovery?: Maybe<InputLinksDiscoveryType>;
  inputManualUpload?: Maybe<InputManualUploadType>;
  inputSitemap?: Maybe<InputSitemapType>;
  inputStreaming?: Maybe<InputStreamingType>;
  inputTextList?: Maybe<InputTextListType>;
  lastAdnConfigStatuses: Array<AdnConfigStatusType>;
  listSearchEngines?: Maybe<Array<SearchEngineType>>;
  liveupdate: LiveUpdateQueries;
  moduleReportingMetrics?: Maybe<ReportingMetricsAggregatesType>;
  moduleReportingScope?: Maybe<Array<Maybe<ReportingScopeType>>>;
  moduleSplitTestingReportingMetrics?: Maybe<SplitTestingReportingMetricsAggregatesType>;
  moduleUrlsBySearch?: Maybe<Array<Maybe<LudSearchResultType>>>;
  moduleUrlsCount?: Maybe<LudUrlsCountType>;
  modulesStableIdToNameMapping?: Maybe<Scalars["JSONString"]["output"]>;
  pageEdit?: Maybe<PageEditType>;
  pageEdits?: Maybe<PageEditPaginatedType>;
  pageworkersCluster?: Maybe<PageworkersClusterType>;
  previewDispatcher?: Maybe<PreviewDispatcherType>;
  qualityControlLiveTestJob?: Maybe<QualityControlLiveTestJobType>;
  queryIndexedPages: Array<QueryIndexedPagesResult>;
  queryServedPages: Array<QueryServedPagesResult>;
  website?: Maybe<WebsiteType>;
  websiteVersion?: Maybe<WebsiteVersionType>;
  websiteVersionsDiff?: Maybe<WebsiteVersionsDiffType>;
  websitesList: WebsitesList;
};

export type QueryExperimentalModuleReportingMetricsArgs = {
  moduleId: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryAdnConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryClusterArgs = {
  name: Scalars["String"]["input"];
};

export type QueryImportJobArgs = {
  id: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryInputBotsDiscoveryArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInputCsvArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInputLinksDiscoveryArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInputManualUploadArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInputSitemapArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInputStreamingArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInputTextListArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryModuleReportingMetricsArgs = {
  moduleId: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryModuleReportingScopeArgs = {
  moduleId: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryModuleSplitTestingReportingMetricsArgs = {
  moduleId: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryModuleUrlsBySearchArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  jwt?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  stableId: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryModuleUrlsCountArgs = {
  jwt?: InputMaybe<Scalars["String"]["input"]>;
  stableId: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryModulesStableIdToNameMappingArgs = {
  websiteId: Scalars["String"]["input"];
};

export type QueryPageEditArgs = {
  id: Scalars["String"]["input"];
  websiteId: Scalars["String"]["input"];
};

export type QueryPageEditsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  websiteId: Scalars["String"]["input"];
};

export type QueryPageworkersClusterArgs = {
  name: Scalars["String"]["input"];
};

export type QueryPreviewDispatcherArgs = {
  device: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
};

export type QueryQualityControlLiveTestJobArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryQueryIndexedPagesArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  timestampEnd?: InputMaybe<Scalars["Int"]["input"]>;
  timestampStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryQueryServedPagesArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  timestampEnd?: InputMaybe<Scalars["Int"]["input"]>;
  timestampStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryWebsiteArgs = {
  id: Scalars["String"]["input"];
};

export type QueryWebsiteVersionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryWebsiteVersionsDiffArgs = {
  id: Scalars["String"]["input"];
  newVersionId: Scalars["String"]["input"];
  oldVersionId: Scalars["String"]["input"];
};

export type QueryWebsitesListArgs = {
  filters?: InputMaybe<WebsitesFilterInput>;
  pagination?: InputMaybe<PaginationTypeInput>;
};

export type QueryContentSubmitPagesResult = {
  __typename?: "QueryContentSubmitPagesResult";
  countSubmissions?: Maybe<Scalars["Int"]["output"]>;
  countSubmissionsFailed?: Maybe<Scalars["Int"]["output"]>;
  countSubmissionsSuccess?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  datetime?: Maybe<Scalars["DateTime"]["output"]>;
  device?: Maybe<Scalars["String"]["output"]>;
  devicesFound?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  errorCode?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  service?: Maybe<Scalars["String"]["output"]>;
  succeed?: Maybe<Scalars["Boolean"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  websiteId?: Maybe<Scalars["String"]["output"]>;
};

export type QueryIndexedPagesResult = {
  __typename?: "QueryIndexedPagesResult";
  avgFetchingTime?: Maybe<Scalars["Float"]["output"]>;
  canonicalEqual?: Maybe<Scalars["String"]["output"]>;
  canonicalUrl?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  countFailedQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countSuccessfulQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countUrls?: Maybe<Scalars["Int"]["output"]>;
  countUrlsReadyToBeServed?: Maybe<Scalars["Int"]["output"]>;
  countWarningQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countWithFailedQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countWithSuccessfulQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countWithWarningQualityControls?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  dateHour?: Maybe<Scalars["String"]["output"]>;
  datetime?: Maybe<Scalars["DateTime"]["output"]>;
  datetimeNearestHour?: Maybe<Scalars["Date"]["output"]>;
  device?: Maybe<Scalars["String"]["output"]>;
  expirationDate?: Maybe<Scalars["Date"]["output"]>;
  expirationTime?: Maybe<Scalars["DateTime"]["output"]>;
  fileUri?: Maybe<Scalars["String"]["output"]>;
  fileUriHash?: Maybe<Scalars["String"]["output"]>;
  firstDateOfMonth?: Maybe<Scalars["Date"]["output"]>;
  firstDateOfWeek?: Maybe<Scalars["Date"]["output"]>;
  httpCode?: Maybe<Scalars["Int"]["output"]>;
  httpCodeCategory?: Maybe<Scalars["String"]["output"]>;
  httpCodeIs3xx?: Maybe<Scalars["Boolean"]["output"]>;
  httpCodeIs4xx?: Maybe<Scalars["Boolean"]["output"]>;
  httpCodeIs5xx?: Maybe<Scalars["Boolean"]["output"]>;
  httpCodeIsGood?: Maybe<Scalars["Boolean"]["output"]>;
  indexed: Scalars["Boolean"]["output"];
  lastExpirationDate?: Maybe<Scalars["Date"]["output"]>;
  lastIndexationDate?: Maybe<Scalars["Date"]["output"]>;
  nextRefreshTime?: Maybe<Scalars["DateTime"]["output"]>;
  notDeliverableReason: NotDeliverableReason;
  notReadyToBeServedReason?: Maybe<Scalars["String"]["output"]>;
  pageId?: Maybe<Scalars["BigInt"]["output"]>;
  pageKey?: Maybe<Scalars["String"]["output"]>;
  pageSize?: Maybe<Scalars["Int"]["output"]>;
  qualityControlName?: Maybe<Scalars["String"]["output"]>;
  qualityControlsFinalStatus?: Maybe<Scalars["String"]["output"]>;
  ratioFailedQualityControls?: Maybe<Scalars["Float"]["output"]>;
  ratioSuccessfulQualityControls?: Maybe<Scalars["Float"]["output"]>;
  ratioUrlsReadyToBeDelivered?: Maybe<Scalars["Float"]["output"]>;
  ratioWarningQualityControls?: Maybe<Scalars["Float"]["output"]>;
  ratioWithFailedQualityControls?: Maybe<Scalars["Boolean"]["output"]>;
  ratioWithSuccessfulQualityControls?: Maybe<Scalars["Float"]["output"]>;
  ratioWithWarningQualityControls?: Maybe<Scalars["Boolean"]["output"]>;
  readyToBeServed?: Maybe<Scalars["Boolean"]["output"]>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
  timeToFetchMs?: Maybe<Scalars["Int"]["output"]>;
  timeToRenderMs?: Maybe<Scalars["Int"]["output"]>;
  ttl?: Maybe<Scalars["Int"]["output"]>;
  ttlRange?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  urlsSample?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  websiteId?: Maybe<Scalars["String"]["output"]>;
  websiteName?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type QueryInventoryStatsResult = {
  __typename?: "QueryInventoryStatsResult";
  configId?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  countExpired?: Maybe<Scalars["Int"]["output"]>;
  countInIndex?: Maybe<Scalars["Int"]["output"]>;
  countInStale?: Maybe<Scalars["Int"]["output"]>;
  countNotIndexed?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  inputGroup?: Maybe<Scalars["String"]["output"]>;
  inputs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  priority?: Maybe<Scalars["String"]["output"]>;
  refreshFrequency?: Maybe<Scalars["String"]["output"]>;
  samplesExpired?: Maybe<Array<Maybe<InventoryStatsSamplesType>>>;
  samplesInIndex?: Maybe<Array<Maybe<InventoryStatsSamplesType>>>;
  samplesInStale?: Maybe<Array<Maybe<InventoryStatsSamplesType>>>;
  samplesNotIndexed?: Maybe<Array<Maybe<InventoryStatsSamplesType>>>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
};

export type QueryServedPagesResult = {
  __typename?: "QueryServedPagesResult";
  avgCacheFreshnessForDeliveredPages?: Maybe<Scalars["Float"]["output"]>;
  avgDeliveryTime?: Maybe<Scalars["Float"]["output"]>;
  avgDeliveryTimeOnCacheHit?: Maybe<Scalars["Float"]["output"]>;
  avgFetchingTime?: Maybe<Scalars["Float"]["output"]>;
  avgFetchingTimeOnCacheHit?: Maybe<Scalars["Float"]["output"]>;
  avgSpeedGain?: Maybe<Scalars["Float"]["output"]>;
  avgSpeedGainOnCacheHit?: Maybe<Scalars["Float"]["output"]>;
  bot?: Maybe<Scalars["String"]["output"]>;
  botId?: Maybe<Scalars["String"]["output"]>;
  cacheHit?: Maybe<Scalars["Boolean"]["output"]>;
  cacheUrl?: Maybe<Scalars["String"]["output"]>;
  cacheUrlQueryStringKeys?: Maybe<Scalars["String"]["output"]>;
  canonicalEqual?: Maybe<Scalars["String"]["output"]>;
  canonicalUrl?: Maybe<Scalars["String"]["output"]>;
  countAllChanged?: Maybe<Scalars["Int"]["output"]>;
  countCanonicalChanged?: Maybe<Scalars["Int"]["output"]>;
  countDescriptionChanged?: Maybe<Scalars["Int"]["output"]>;
  countErroredQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countFailedQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countH1Changed?: Maybe<Scalars["Int"]["output"]>;
  countHits?: Maybe<Scalars["Int"]["output"]>;
  countHitsByHour?: Maybe<Scalars["String"]["output"]>;
  countHitsByMinute?: Maybe<Scalars["String"]["output"]>;
  countHitsDelivered?: Maybe<Scalars["Int"]["output"]>;
  countHitsNotDelivered?: Maybe<Scalars["Int"]["output"]>;
  countHreflangChanged?: Maybe<Scalars["Int"]["output"]>;
  countLinksChanged?: Maybe<Scalars["Int"]["output"]>;
  countSuccessfulQualityControls?: Maybe<Scalars["Int"]["output"]>;
  countTitleChanged?: Maybe<Scalars["Int"]["output"]>;
  countUrls?: Maybe<Scalars["Int"]["output"]>;
  countUrlsDelivered?: Maybe<Scalars["Int"]["output"]>;
  countWarningQualityControls?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  dateHour?: Maybe<Scalars["String"]["output"]>;
  datetime?: Maybe<Scalars["DateTime"]["output"]>;
  datetimeNearestHour?: Maybe<Scalars["DateTime"]["output"]>;
  delivered?: Maybe<Scalars["Boolean"]["output"]>;
  deliveredContent?: Maybe<Scalars["String"]["output"]>;
  deliveryOriginStatus?: Maybe<Scalars["String"]["output"]>;
  deliveryRatio?: Maybe<Scalars["Float"]["output"]>;
  deliveryTime?: Maybe<Scalars["Float"]["output"]>;
  device?: Maybe<Scalars["String"]["output"]>;
  exampleCacheUrl?: Maybe<Scalars["String"]["output"]>;
  exampleUrl?: Maybe<Scalars["String"]["output"]>;
  firstDateOfMonth?: Maybe<Scalars["Date"]["output"]>;
  firstDateOfWeek?: Maybe<Scalars["Date"]["output"]>;
  httpCodeBeam?: Maybe<Scalars["Int"]["output"]>;
  httpCodeIndexedPage?: Maybe<Scalars["Int"]["output"]>;
  httpCodeIndexedPageCategory?: Maybe<Scalars["String"]["output"]>;
  httpCodeServedPage?: Maybe<Scalars["String"]["output"]>;
  indexedDevicesFound?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  lastDeliveryDate?: Maybe<Scalars["Date"]["output"]>;
  notDeliveredReason?: Maybe<Scalars["String"]["output"]>;
  pathN1?: Maybe<Scalars["String"]["output"]>;
  pathN2?: Maybe<Scalars["String"]["output"]>;
  pathN3?: Maybe<Scalars["String"]["output"]>;
  qualityControlAllSeenListFail?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  qualityControlAllSeenListSuccess?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  qualityControlAllSeenListWarning?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  qualityControlLastSeenListFail?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  qualityControlLastSeenListSuccess?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  qualityControlLastSeenListWarning?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  qualityControlLastSeenStatusSucceeded?: Maybe<Scalars["Boolean"]["output"]>;
  qualityControlListFail?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  qualityControlListWarning?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  qualityControlName?: Maybe<Scalars["String"]["output"]>;
  qualityControlStatusSucceeded?: Maybe<Scalars["Boolean"]["output"]>;
  qualityControlsFinalStatus?: Maybe<Scalars["String"]["output"]>;
  queryStringKeys?: Maybe<Scalars["String"]["output"]>;
  resolvedDevice?: Maybe<Scalars["String"]["output"]>;
  searchEngine?: Maybe<Scalars["String"]["output"]>;
  searchEngineId?: Maybe<Scalars["String"]["output"]>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
  speedGain?: Maybe<Scalars["Int"]["output"]>;
  sumDeliveryTime?: Maybe<Scalars["Float"]["output"]>;
  sumFetchingTime?: Maybe<Scalars["Float"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  urlsSample?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
  websiteId?: Maybe<Scalars["String"]["output"]>;
  websiteName?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type Refresh = {
  __typename?: "Refresh";
  payload: Scalars["GenericScalar"]["output"];
  refreshExpiresIn: Scalars["Int"]["output"];
  token: Scalars["String"]["output"];
};

export type RefreshRule = {
  __typename?: "RefreshRule";
  filters?: Maybe<Array<Maybe<RefreshRuleFilter>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  maxStale?: Maybe<Scalars["Int"]["output"]>;
  preventIndexation?: Maybe<Scalars["Boolean"]["output"]>;
  priority?: Maybe<Scalars["Int"]["output"]>;
  refreshFrequency?: Maybe<Scalars["Int"]["output"]>;
};

export type RefreshRuleFilter = {
  __typename?: "RefreshRuleFilter";
  input?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  predicate?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type RegisterBotifyAnalyticsAutomationMutation = {
  __typename?: "RegisterBotifyAnalyticsAutomationMutation";
  automationProject: Scalars["String"]["output"];
  automationUsername: Scalars["String"]["output"];
  botifyAnalyticsInput?: Maybe<BotifyAnalyticsInputType>;
  device: Scalars["String"]["output"];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  website: Scalars["String"]["output"];
};

export type RegisterBotifyAnalyticsAutomationMutationInput = {
  automationProject: Scalars["String"]["input"];
  automationUsername: Scalars["String"]["input"];
  device: Scalars["String"]["input"];
  website: Scalars["String"]["input"];
};

export type ReportingMetricsAggregatesType = {
  __typename?: "ReportingMetricsAggregatesType";
  day?: Maybe<Array<Maybe<ReportingMetricsType>>>;
  week?: Maybe<Array<Maybe<ReportingMetricsType>>>;
};

export type ReportingMetricsType = {
  __typename?: "ReportingMetricsType";
  avgClicksPerUrl?: Maybe<Scalars["Float"]["output"]>;
  avgImpressionsPerUrl?: Maybe<Scalars["Float"]["output"]>;
  avgPosition?: Maybe<Scalars["Float"]["output"]>;
  clicks?: Maybe<Scalars["Int"]["output"]>;
  ctr?: Maybe<Scalars["Float"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  impressions?: Maybe<Scalars["Int"]["output"]>;
  uniqueKeywords?: Maybe<Scalars["Int"]["output"]>;
  uniqueUrls?: Maybe<Scalars["Int"]["output"]>;
};

export type ReportingScopeType = {
  __typename?: "ReportingScopeType";
  date?: Maybe<Scalars["String"]["output"]>;
  isInGsc?: Maybe<Scalars["Boolean"]["output"]>;
  nbUrls?: Maybe<Scalars["Int"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export type RequestManualUploadPresignedUrlMutation = {
  __typename?: "RequestManualUploadPresignedUrlMutation";
  fields?: Maybe<Scalars["JSONString"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type RollbackAdnConfigMutation = {
  __typename?: "RollbackADNConfigMutation";
  message: Scalars["String"]["output"];
};

export type RollbackAllAdnConfigsMessages = {
  __typename?: "RollbackAllADNConfigsMessages";
  message: Scalars["String"]["output"];
  websiteId?: Maybe<Scalars["String"]["output"]>;
};

export type RollbackAllAdnConfigsMutation = {
  __typename?: "RollbackAllADNConfigsMutation";
  messages: Array<RollbackAllAdnConfigsMessages>;
};

/**
 * `RollbackModuleMutation` resets the current module draft's configuration to that of
 * the current production module's configuration.
 */
export type RollbackModuleMutation = {
  __typename?: "RollbackModuleMutation";
  error?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
};

export type RunAllInterceptorCheckersMutation = {
  __typename?: "RunAllInterceptorCheckersMutation";
  configs?: Maybe<Array<Maybe<InterceptorCheckerConfigType>>>;
};

export type SearchEngineIndexAgeResultType = {
  __typename?: "SearchEngineIndexAgeResultType";
  ageRange?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
};

export type SearchEngineIndexAgeType = {
  __typename?: "SearchEngineIndexAgeType";
  error?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<Array<SearchEngineIndexAgeResultType>>;
};

export type SearchEngineIndexResultType = {
  __typename?: "SearchEngineIndexResultType";
  count?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
};

export type SearchEngineIndexType = {
  __typename?: "SearchEngineIndexType";
  error?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<Array<SearchEngineIndexResultType>>;
};

export type SearchEngineType = {
  __typename?: "SearchEngineType";
  bots?: Maybe<Array<BotType>>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum SectionIgnoreParametersCascading {
  /** Append current parameters to default Behavior */
  Append = "APPEND",
  /** Replace current parameters */
  Replace = "REPLACE",
}

export enum SectionIgnoreParametersStrategy {
  /** Do a permanent redirect */
  PermanentRedirect = "PERMANENT_REDIRECT",
  /** Serve Page */
  Serve = "SERVE",
  /** Serve and add canonical (if not already exists) relation to URL without parameters */
  ServeAndAddCanonical = "SERVE_AND_ADD_CANONICAL",
  /** Serve and force-add canonical relation to URL without parameters */
  ServeAndForceCanonical = "SERVE_AND_FORCE_CANONICAL",
  /** Do a temporary redirect */
  TemporaryRedirect = "TEMPORARY_REDIRECT",
}

export type SectionInput = {
  fallbackSection: Scalars["Boolean"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  rules: Scalars["JSONString"]["input"];
  sectionPriority: Scalars["Int"]["input"];
};

export enum SectionIsolationLevel {
  /** No Cache */
  Nocache = "NOCACHE",
  /** Subdomain */
  Subdomain = "SUBDOMAIN",
  /** Website */
  Website = "WEBSITE",
}

export enum SectionJsRedirectStatusCode {
  /** 301 */
  Code_301 = "CODE_301",
  /** 302 */
  Code_302 = "CODE_302",
  /** Do Nothing */
  DoNothing = "DO_NOTHING",
}

export enum SectionLiveMode {
  /** Live Fetch And Render */
  LiveFetchAndRender = "LIVE_FETCH_AND_RENDER",
  /** Live Fetch (legacy) */
  LiveFetchLegacy = "LIVE_FETCH_LEGACY",
  /** Live Fetch */
  LiveFetchOnly = "LIVE_FETCH_ONLY",
  /** No Live */
  NoLive = "NO_LIVE",
}

export enum SectionMode {
  /** Allow */
  Allow = "ALLOW",
  /** Allow as resource */
  AllowAsResource = "ALLOW_AS_RESOURCE",
  /** Block */
  Block = "BLOCK",
  /** Custom HTML served without hitting cache */
  CustomHtml = "CUSTOM_HTML",
  /** Not found */
  NotFound = "NOT_FOUND",
  /** Redirect */
  Redirect = "REDIRECT",
}

export enum SectionModeNotFoundStatusCode {
  /** Code 404 */
  Code_404 = "CODE_404",
  /** Code 410 */
  Code_410 = "CODE_410",
}

export enum SectionModeRedirectStatusCode {
  /** Code 301 */
  Code_301 = "CODE_301",
  /** Code 302 */
  Code_302 = "CODE_302",
}

export enum SectionModeRedirectStrategy {
  /** Custom JS */
  CustomJs = "CUSTOM_JS",
  /** Single URL */
  SingleUrl = "SINGLE_URL",
}

export type SectionMoveMutation = {
  __typename?: "SectionMoveMutation";
  direction: Scalars["String"]["output"];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  otherSection?: Maybe<SectionType>;
  section?: Maybe<SectionType>;
  website: Scalars["String"]["output"];
};

export type SectionMoveMutationInput = {
  direction: Scalars["String"]["input"];
  section: Scalars["String"]["input"];
  website: Scalars["String"]["input"];
};

export type SectionType = {
  __typename?: "SectionType";
  /** [deprecated] Allow Live Fetching/Rendering if page is not cached. Use live_mode instead */
  allowLive?: Maybe<Scalars["Boolean"]["output"]>;
  botsdiscoveryinputSet: Array<InputBotsDiscoveryType>;
  changes?: Maybe<ModelChangesType>;
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  customRefreshRules?: Maybe<Array<Maybe<RefreshRule>>>;
  customRefreshRulesJson: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  /** NULL maps to the "Do not process" section. Use "inherit_render_configs" to control inheritance */
  desktopConfig?: Maybe<ConfigType>;
  /** If Yes, Rules won't be intepreted */
  fallbackSection: Scalars["Boolean"]["output"];
  headerCssAttribute: Scalars["String"]["output"];
  headerCssSelector: Scalars["String"]["output"];
  /** Content Types splited by comma */
  htmlContentTypes: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  /** Parameters splited by comma */
  ignoreParameters: Scalars["String"]["output"];
  ignoreParametersCascading?: Maybe<SectionIgnoreParametersCascading>;
  ignoreParametersReplaceLinks?: Maybe<Scalars["Boolean"]["output"]>;
  ignoreParametersStrategy?: Maybe<SectionIgnoreParametersStrategy>;
  inheritedRefreshRulesOverrides?: Maybe<
    Array<Maybe<InheritedRefreshRulesOverride>>
  >;
  inheritedRefreshRulesOverridesJson: Scalars["String"]["output"];
  isolationId: Scalars["String"]["output"];
  isolationLevel?: Maybe<SectionIsolationLevel>;
  jsCodes: Array<JsCodeType>;
  /** Set a redirect http code when pushState or window.location is called */
  jsRedirectStatusCode?: Maybe<SectionJsRedirectStatusCode>;
  linksdiscoveryinputSet: Array<InputLinksDiscoveryType>;
  /** Live Render Max pages per sec */
  liveMaxSpeed?: Maybe<Scalars["Decimal"]["output"]>;
  liveMode?: Maybe<SectionLiveMode>;
  liveScope?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Section is automatically managed by another Botify Activation Service */
  locked: Scalars["Boolean"]["output"];
  /** Max pages per sec */
  maxSpeed?: Maybe<Scalars["Decimal"]["output"]>;
  /** Max stale to serve pages in case it was not refreshed in time */
  maxStale?: Maybe<Scalars["Int"]["output"]>;
  /** NULL maps to the "Do not process" section. Use "inherit_render_configs" to control inheritance */
  mobileConfig?: Maybe<ConfigType>;
  mode: SectionMode;
  /** HTML Content to return */
  modeCustomHtmlHtml: Scalars["String"]["output"];
  modeCustomHtmlStatusCode?: Maybe<Scalars["Int"]["output"]>;
  modeNotFoundHtml: Scalars["String"]["output"];
  modeNotFoundStatusCode?: Maybe<SectionModeNotFoundStatusCode>;
  modeRedirectJs: Scalars["String"]["output"];
  modeRedirectLocation: Scalars["String"]["output"];
  modeRedirectStatusCode?: Maybe<SectionModeRedirectStatusCode>;
  modeRedirectStrategy?: Maybe<SectionModeRedirectStrategy>;
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  /** When set to TRUE, the section will override the render configs of its parent, not default to it */
  overrideRenderConfigs: Scalars["Boolean"]["output"];
  /** Refresh Pages every N minutes */
  refreshFrequency?: Maybe<Scalars["Int"]["output"]>;
  /** Smallest is the most prioritary */
  refreshPriority?: Maybe<Scalars["Int"]["output"]>;
  /** Separated by commas */
  removeResponseHeadersFromOriginalPage: Scalars["String"]["output"];
  rules?: Maybe<Scalars["String"]["output"]>;
  /** Smallest is the most prioritary */
  sectionPriority: Scalars["Float"]["output"];
  stableId: Scalars["String"]["output"];
  statusCodeCssAttribute: Scalars["String"]["output"];
  statusCodeCssSelector: Scalars["String"]["output"];
  website: WebsiteType;
  websiteVersion?: Maybe<WebsiteVersionType>;
};

/** An enumeration. */
export enum SettingsCheckerJobStatus {
  /** Created */
  Created = "CREATED",
  /** Done */
  Done = "DONE",
  /** Failed */
  Failed = "FAILED",
  /** Running */
  Running = "RUNNING",
}

export type SettingsCheckerJobType = {
  __typename?: "SettingsCheckerJobType";
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  dateCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  errorMessage: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  result: Scalars["String"]["output"];
  status: SettingsCheckerJobStatus;
  testStatus?: Maybe<TestStatus>;
  website: WebsiteType;
};

export enum SeverityEnum {
  /** Fail */
  Fail = "FAIL",
  /** Warning */
  Warning = "WARNING",
}

export type SitemapDataType = {
  __typename?: "SitemapDataType";
  count?: Maybe<Scalars["Int"]["output"]>;
  dateFirstSeen?: Maybe<Scalars["DateTime"]["output"]>;
  dateLastSeen?: Maybe<Scalars["DateTime"]["output"]>;
  sample?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum SitemapInputDownloadStrategy {
  /** Matches URL */
  Matchesurl = "MATCHESURL",
  /** Get all new files matching URL prefix */
  Urlprefixallnewfiles = "URLPREFIXALLNEWFILES",
  /** Get last modified file from all files matching URL prefix */
  Urlprefixlastmodified = "URLPREFIXLASTMODIFIED",
}

export type SmartLinkOptimizationType = {
  __typename?: "SmartLinkOptimizationType";
  completionPercent: Scalars["Int"]["output"];
  crawlId?: Maybe<Scalars["String"]["output"]>;
  createdDate: Scalars["DateTime"]["output"];
  dashboardLink?: Maybe<Scalars["String"]["output"]>;
  dataDays?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  destinationUrlsCsv?: Maybe<Scalars["String"]["output"]>;
  gcpProjectId?: Maybe<Scalars["String"]["output"]>;
  homePages?: Maybe<Array<Scalars["String"]["output"]>>;
  id: Scalars["ID"]["output"];
  maxInlinksToAdd?: Maybe<Scalars["Int"]["output"]>;
  maxOutlinksToAdd?: Maybe<Scalars["Int"]["output"]>;
  modifiedDate: Scalars["DateTime"]["output"];
  previousLinksStrategy?: Maybe<PreviousLinksStrategyChoices>;
  state: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  type: SmartLinkOptimizationTypeEnum;
};

export enum SmartLinkOptimizationTypeEnum {
  /** Boost Products */
  Pdp2Pdp = "PDP2PDP",
  /** Boost Categories */
  Pdp2Plp = "PDP2PLP",
}

/** An enumeration. */
export enum SplitTestingAnalysisResultEnum {
  Inconclusive = "INCONCLUSIVE",
  Negative = "NEGATIVE",
  Positive = "POSITIVE",
}

export type SplitTestingReportingAnalysisAggregatesType = {
  __typename?: "SplitTestingReportingAnalysisAggregatesType";
  homogeneousGroups?: Maybe<SplitTestingReportingAnalysisType>;
  splitGroups?: Maybe<SplitTestingReportingAnalysisType>;
};

export type SplitTestingReportingAnalysisDeltaStatsType = {
  __typename?: "SplitTestingReportingAnalysisDeltaStatsType";
  delta?: Maybe<Scalars["Float"]["output"]>;
  lift?: Maybe<Scalars["Float"]["output"]>;
  liftCi?: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
};

export type SplitTestingReportingAnalysisDescriptiveStatsType = {
  __typename?: "SplitTestingReportingAnalysisDescriptiveStatsType";
  mean?: Maybe<Scalars["Float"]["output"]>;
  nobs?: Maybe<Scalars["Float"]["output"]>;
  relativeStd?: Maybe<Scalars["Float"]["output"]>;
  std?: Maybe<Scalars["Float"]["output"]>;
};

export type SplitTestingReportingAnalysisErrorType = {
  __typename?: "SplitTestingReportingAnalysisErrorType";
  code?: Maybe<Scalars["Int"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type SplitTestingReportingAnalysisStatsType = {
  __typename?: "SplitTestingReportingAnalysisStatsType";
  controlGroupStats?: Maybe<SplitTestingReportingAnalysisDescriptiveStatsType>;
  deltaStats?: Maybe<SplitTestingReportingAnalysisDeltaStatsType>;
  metric?: Maybe<Scalars["String"]["output"]>;
  ttestResults?: Maybe<SplitTestingReportingAnalysisTtestType>;
  variantGroupStats?: Maybe<SplitTestingReportingAnalysisDescriptiveStatsType>;
};

export type SplitTestingReportingAnalysisTtestType = {
  __typename?: "SplitTestingReportingAnalysisTtestType";
  alpha?: Maybe<Scalars["Float"]["output"]>;
  pValue?: Maybe<Scalars["Float"]["output"]>;
  significantDifferenceBtwMeans?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SplitTestingReportingAnalysisType = {
  __typename?: "SplitTestingReportingAnalysisType";
  error?: Maybe<SplitTestingReportingAnalysisErrorType>;
  result?: Maybe<SplitTestingAnalysisResultEnum>;
  stats?: Maybe<SplitTestingReportingAnalysisStatsType>;
};

export type SplitTestingReportingMetricsAggregatesType = {
  __typename?: "SplitTestingReportingMetricsAggregatesType";
  analysis?: Maybe<SplitTestingReportingAnalysisAggregatesType>;
  control?: Maybe<ReportingMetricsAggregatesType>;
  variant?: Maybe<ReportingMetricsAggregatesType>;
};

export type StrategySearchElementInputType = {
  /** css selector */
  cssSelector: Scalars["String"]["input"];
  predicate?: InputMaybe<StrategySearchElementPredicateEnumCreate>;
  /** value */
  value: Scalars["Int"]["input"];
};

export enum StrategySearchElementPredicate {
  /** Equal To */
  Eq = "EQ",
  /** Greater Than */
  Gt = "GT",
  /** Less Than */
  Lt = "LT",
}

export enum StrategySearchElementPredicateEnumCreate {
  /** Equal To */
  Eq = "EQ",
  /** Greater Than */
  Gt = "GT",
  /** Less Than */
  Lt = "LT",
}

export type StrategySearchElementType = {
  __typename?: "StrategySearchElementType";
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  cssSelector: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  predicate: StrategySearchElementPredicate;
  stableId: Scalars["String"]["output"];
  value: Scalars["Int"]["output"];
};

export type StrategySearchTextInputType = {
  /** css selector */
  cssSelector: Scalars["String"]["input"];
  /** iterator */
  iterator?: InputMaybe<StrategySearchTextIteratorEnumCreate>;
  /** Wildcards are allowed */
  value: Scalars["String"]["input"];
};

export enum StrategySearchTextIterator {
  /** All */
  All = "ALL",
  /** First */
  First = "FIRST",
}

export enum StrategySearchTextIteratorEnumCreate {
  /** All */
  All = "ALL",
  /** First */
  First = "FIRST",
}

export type StrategySearchTextType = {
  __typename?: "StrategySearchTextType";
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  cssSelector: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  iterator: StrategySearchTextIterator;
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  stableId: Scalars["String"]["output"];
  /** Wildcards are allowed */
  value: Scalars["String"]["output"];
};

export enum StrategyTypeEnum {
  StrategySearchElement = "STRATEGY_SEARCH_ELEMENT",
  StrategySearchText = "STRATEGY_SEARCH_TEXT",
}

export enum TestStatus {
  Fail = "fail",
  Success = "success",
  Warning = "warning",
}

export type TextListDataType = {
  __typename?: "TextListDataType";
  sample?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type TriggerFullGenerationHtmlHeadOptimization = {
  __typename?: "TriggerFullGenerationHtmlHeadOptimization";
  htmlHeadOptimization?: Maybe<HtmlHeadOptimizationType>;
};

/** `UnarchiveModuleMutation` restores an archived module, identified by its `stable_id`. */
export type UnarchiveModuleMutation = {
  __typename?: "UnarchiveModuleMutation";
  error?: Maybe<Scalars["String"]["output"]>;
  module?: Maybe<ModuleType>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/**
 * `UnpauseModuleMutation` reactivates a paused module, identified by its `stable_id`.
 * To do so, it will simply set `running_status=live` on all versions of the module.
 */
export type UnpauseModuleMutation = {
  __typename?: "UnpauseModuleMutation";
  error?: Maybe<Scalars["String"]["output"]>;
  module?: Maybe<ModuleType>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type UpdateAdnConfigMutation = {
  __typename?: "UpdateADNConfigMutation";
  adnConfig?: Maybe<AdnConfigType>;
  errors: Array<ErrorType>;
};

export type UpdateAdnConfigMutationInput = {
  customerOriginPort?: InputMaybe<Scalars["Int"]["input"]>;
  /** Timeout in milliseconds */
  customerOriginTimeout: Scalars["Int"]["input"];
  /** The origin URL field has to be filled when the request isn't sent to the customer CDN but to the customer origin directly. */
  customerOriginUrl?: InputMaybe<Scalars["String"]["input"]>;
  deployTests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  failoverHostHeader?: InputMaybe<Scalars["String"]["input"]>;
  failoverRequestHeaders?: InputMaybe<Scalars["String"]["input"]>;
  failoverRequestHeadersFilters?: InputMaybe<Scalars["String"]["input"]>;
  failoverResponseHeaders?: InputMaybe<Scalars["String"]["input"]>;
  /** The User-Agent to use when the request is sent back to the customer CDN (the request should not be intercepted by SpeedWorkers or we will end up with an infinite loop). No User-Agent is necessary if the fallback request is sent directly to the customer origin (because in this case, the request doesn't go back to the customer CDN). */
  failoverUserAgent?: InputMaybe<Scalars["String"]["input"]>;
  gdprModeEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Ignore paths that match the pattern (the matching is done on the path, not on the querystring) */
  ignoredPathsRegex: Scalars["String"]["input"];
  interceptedBotsRegex: Scalars["String"]["input"];
  swOriginRewrite?: InputMaybe<Scalars["String"]["input"]>;
  /** Timeout in milliseconds */
  swOriginTimeout: Scalars["Int"]["input"];
  swRealOrigin?: InputMaybe<Scalars["String"]["input"]>;
  /** The absolute URL to use to test the lightweight CDN setup. */
  urlCacheHit?: InputMaybe<Scalars["String"]["input"]>;
  verifiedBotsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  workerVersion: Scalars["String"]["input"];
};

export type UpdateBaConfigCodeMutation = {
  __typename?: "UpdateBaConfigCodeMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  website?: Maybe<WebsiteType>;
};

export type UpdateBaConfigCodeMutationInput = {
  /** OnDone code */
  baDesktopCode?: InputMaybe<Scalars["String"]["input"]>;
  baDesktopJsConfig?: InputMaybe<Scalars["String"]["input"]>;
  baDesktopJsRules?: InputMaybe<Scalars["String"]["input"]>;
  /** OnLoad code */
  baDesktopOnLoadCode?: InputMaybe<Scalars["String"]["input"]>;
  /** OnDone code */
  baMobileCode?: InputMaybe<Scalars["String"]["input"]>;
  baMobileJsConfig?: InputMaybe<Scalars["String"]["input"]>;
  baMobileJsRules?: InputMaybe<Scalars["String"]["input"]>;
  /** OnLoad code */
  baMobileOnLoadCode?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Update a new Html Head optimization and launch the preview generation if needed */
export type UpdateHtmlHeadOptimizationMutation = {
  __typename?: "UpdateHtmlHeadOptimizationMutation";
  htmlHeadOptimization?: Maybe<HtmlHeadOptimizationType>;
};

export type UpdateInputBotsDiscoveryMutation = {
  __typename?: "UpdateInputBotsDiscoveryMutation";
  botsDiscoveryInput?: Maybe<InputBotsDiscoveryType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateInputBotsDiscoveryMutationInput = {
  /** Bot ID */
  bot?: InputMaybe<BotsDiscoveryInputBot>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  minCrawlsInPeriod: Scalars["Int"]["input"];
  nbDays: Scalars["Int"]["input"];
  /** Search Engine ID */
  searchEngine?: InputMaybe<BotsDiscoveryInputSearchEngine>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type UpdateInputCsvMutation = {
  __typename?: "UpdateInputCSVMutation";
  csvInput?: Maybe<InputCsvType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateInputCsvMutationInput = {
  /** Column Number for URL (starts to 0) */
  columnNumber?: InputMaybe<Scalars["Int"]["input"]>;
  commentCharacter?: InputMaybe<Scalars["String"]["input"]>;
  downloadStrategy: DownloadStrategyChoices;
  /** Valid start date for strategy urlPrefixAllNewFiles */
  downloadStrategyAllNewFilesStartDatetime?: InputMaybe<
    Scalars["DateTime"]["input"]
  >;
  escapeFirstLines?: InputMaybe<Scalars["Int"]["input"]>;
  extraHeaders?: InputMaybe<Scalars["String"]["input"]>;
  /** In minutes */
  frequency: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  /** Number of days in the past to filter in case mode is incremental */
  nbDays?: InputMaybe<Scalars["Int"]["input"]>;
  separator?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
  userAgent: Scalars["String"]["input"];
};

export type UpdateInputLinksDiscoveryMutation = {
  __typename?: "UpdateInputLinksDiscoveryMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  linksDiscoveryInput?: Maybe<InputLinksDiscoveryType>;
};

export type UpdateInputLinksDiscoveryMutationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  nbDays: Scalars["Int"]["input"];
  sections?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type UpdateInputManualUploadMutation = {
  __typename?: "UpdateInputManualUploadMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  manualUploadInput?: Maybe<InputManualUploadType>;
};

export type UpdateInputManualUploadMutationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type UpdateInputSitemapMutation = {
  __typename?: "UpdateInputSitemapMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  sitemapInput?: Maybe<InputSitemapType>;
};

export type UpdateInputSitemapMutationInput = {
  downloadStrategy: DownloadStrategyChoices;
  /** Valid start date for strategy urlPrefixAllNewFiles */
  downloadStrategyAllNewFilesStartDatetime?: InputMaybe<
    Scalars["DateTime"]["input"]
  >;
  extraHeaders?: InputMaybe<Scalars["String"]["input"]>;
  /** In minutes */
  frequency: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  /** Number of days in the past to filter in case mode is incremental */
  nbDays?: InputMaybe<Scalars["Int"]["input"]>;
  url: Scalars["String"]["input"];
  userAgent: Scalars["String"]["input"];
};

export type UpdateInputTextListMutation = {
  __typename?: "UpdateInputTextListMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  textListInput?: Maybe<InputTextListType>;
};

export type UpdateInputTextListMutationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  urls: Scalars["String"]["input"];
};

export type UpdateJsCodeMutation = {
  __typename?: "UpdateJSCodeMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  jsCode?: Maybe<JsCodeType>;
};

export type UpdateJsCodeMutationInput = {
  active: Scalars["Boolean"]["input"];
  applyOnWebsite?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  onActionCode?: InputMaybe<Scalars["String"]["input"]>;
  onDomContentLoadedCode?: InputMaybe<Scalars["String"]["input"]>;
  onDoneCode?: InputMaybe<Scalars["String"]["input"]>;
  onInitCode?: InputMaybe<Scalars["String"]["input"]>;
  onLoadCode?: InputMaybe<Scalars["String"]["input"]>;
  onPreBeamResponseCode?: InputMaybe<Scalars["String"]["input"]>;
  onSetupBeforeRenderCode?: InputMaybe<Scalars["String"]["input"]>;
  onWaitForCode?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  websiteVersion: Scalars["ID"]["input"];
};

export type UpdateModuleMutation = {
  __typename?: "UpdateModuleMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  module?: Maybe<ModuleType>;
};

export type UpdateModuleMutationInput = {
  config: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  /** If set, this field controls the size (in percentage) of the variant group used for split testing. If left NULL, split testing won't be enabled for that module. */
  splitTestingVariantGroupSizePercentage?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Update an existing Module Tag */
export type UpdateModuleTagMutation = {
  __typename?: "UpdateModuleTagMutation";
  moduleTag?: Maybe<ModuleTagType>;
};

export type UpdateSectionMutation = {
  __typename?: "UpdateSectionMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  section?: Maybe<SectionType>;
};

export type UpdateSectionMutationInput = {
  customRefreshRules?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  desktopConfig?: InputMaybe<Scalars["ID"]["input"]>;
  /** Content Types split by comma */
  htmlContentTypes?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** Parameters split by comma */
  ignoreParameters?: InputMaybe<Scalars["String"]["input"]>;
  ignoreParametersCascading?: InputMaybe<SectionIgnoreParametersCascading>;
  /** Replace bad links/rel to final URL */
  ignoreParametersReplaceLinks?: InputMaybe<Scalars["Boolean"]["input"]>;
  ignoreParametersStrategy?: InputMaybe<SectionIgnoreParametersStrategy>;
  inheritedRefreshRulesOverrides?: InputMaybe<Scalars["String"]["input"]>;
  /** Set a redirect http code when pushState or window.location is called */
  jsRedirectStatusCode?: InputMaybe<SectionJsRedirectStatusCode>;
  /** Max stale to serve pages in case it was not refreshed in time */
  maxStale?: InputMaybe<Scalars["Int"]["input"]>;
  mobileConfig?: InputMaybe<Scalars["ID"]["input"]>;
  mode: SectionMode;
  /** HTML Content to return */
  modeCustomHtmlHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeCustomHtmlStatusCode?: InputMaybe<Scalars["Int"]["input"]>;
  modeNotFoundHtml?: InputMaybe<Scalars["String"]["input"]>;
  modeNotFoundStatusCode?: InputMaybe<SectionModeNotFoundStatusCode>;
  modeRedirectJs?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectLocation?: InputMaybe<Scalars["String"]["input"]>;
  modeRedirectStatusCode?: InputMaybe<SectionModeRedirectStatusCode>;
  modeRedirectStrategy?: InputMaybe<SectionModeRedirectStrategy>;
  name: Scalars["String"]["input"];
  /** When set to TRUE, the section will override the render configs of its parent, not default to it */
  overrideRenderConfigs?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Refresh Pages every N minutes */
  refreshFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  /** Smallest is the most prioritary */
  refreshPriority?: InputMaybe<Scalars["Int"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateSmartLinkOptimizationMutation = {
  __typename?: "UpdateSmartLinkOptimizationMutation";
  smartlinkOptimization?: Maybe<SmartLinkOptimizationType>;
};

export type UpdateVersionCommentMutation = {
  __typename?: "UpdateVersionCommentMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  websiteVersion?: Maybe<WebsiteVersionType>;
};

export type UpdateVersionCommentMutationInput = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateWebsiteBasicSettingsMutation = {
  __typename?: "UpdateWebsiteBasicSettingsMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  websiteVersion?: Maybe<WebsiteVersionType>;
};

export type UpdateWebsiteBasicSettingsMutationInput = {
  /** If key is not sent, the field will retain its previous value */
  hosts?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateWebsiteSettingsMutation = {
  __typename?: "UpdateWebsiteSettingsMutation";
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  websiteVersion?: Maybe<WebsiteVersionType>;
};

export type UpdateWebsiteSettingsMutationInput = {
  /** Real Client IP sent by the interceptor */
  customClientIpHeader?: InputMaybe<Scalars["String"]["input"]>;
  enableIncrementalCache?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableSmartCacheRefresh?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterBadBots?: InputMaybe<Scalars["Boolean"]["input"]>;
  hosts: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** The minimum refresh interval for index table. */
  indexMinimumRefreshInterval: Scalars["Int"]["input"];
  liveMaxSpeed?: InputMaybe<Scalars["Decimal"]["input"]>;
  /** Max Number of Pages / Sec */
  maxSpeed: Scalars["Decimal"]["input"];
  noDeliveryStatusCode?: InputMaybe<Scalars["Int"]["input"]>;
  /** The minimum refresh interval for pages_logs (dispatcher) table. */
  pagesMinimumRefreshInterval: Scalars["Int"]["input"];
  useRenderingFarm?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UrlTesterMatchingRule = {
  __typename?: "UrlTesterMatchingRule";
  field: Scalars["String"]["output"];
  group?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  operator: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type UrlTesterMatchingSection = {
  __typename?: "UrlTesterMatchingSection";
  id?: Maybe<Scalars["String"]["output"]>;
  matchingRules?: Maybe<Array<UrlTesterMatchingRule>>;
  mode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type UrlTesterMutation = {
  __typename?: "UrlTesterMutation";
  results?: Maybe<Array<UrlTesterResult>>;
};

export type UrlTesterResult = {
  __typename?: "UrlTesterResult";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  errorType: Scalars["String"]["output"];
  matchingSection?: Maybe<UrlTesterMatchingSection>;
  url: Scalars["String"]["output"];
};

export type UserType = {
  __typename?: "UserType";
  email: Scalars["String"]["output"];
  groupsNames?: Maybe<Scalars["String"]["output"]>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars["Boolean"]["output"];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"]["output"];
};

export type Verify = {
  __typename?: "Verify";
  payload: Scalars["GenericScalar"]["output"];
};

export type VolumeType = {
  __typename?: "VolumeType";
  count?: Maybe<Scalars["Int"]["output"]>;
  sample?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

/** An enumeration. */
export enum WebsiteApiKeyScope {
  /** Activation Delivery Network */
  Adn = "ADN",
  /** Delivery */
  Delivery = "DELIVERY",
  /** Inventory API */
  Inventory = "INVENTORY",
  /** Preview */
  Preview = "PREVIEW",
}

export type WebsiteApiKeyType = {
  __typename?: "WebsiteAPIKeyType";
  active: Scalars["Boolean"]["output"];
  comment: Scalars["String"]["output"];
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  dateCreated: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  scope: WebsiteApiKeyScope;
  website: WebsiteType;
};

export type WebsiteDebugConfigType = {
  __typename?: "WebsiteDebugConfigType";
  cronsConfig?: Maybe<Scalars["String"]["output"]>;
  deliveryJsConfig?: Maybe<Scalars["String"]["output"]>;
  draftCronsConfig?: Maybe<Scalars["String"]["output"]>;
  draftInputsConfig?: Maybe<Scalars["String"]["output"]>;
  draftMainConfig?: Maybe<Scalars["String"]["output"]>;
  draftRenderingJsCode?: Maybe<Scalars["String"]["output"]>;
  draftRenderingJsConfig?: Maybe<Scalars["String"]["output"]>;
  inputsConfig?: Maybe<Scalars["String"]["output"]>;
  mainConfig?: Maybe<Scalars["String"]["output"]>;
  renderingJsCode?: Maybe<Scalars["String"]["output"]>;
  renderingJsConfig?: Maybe<Scalars["String"]["output"]>;
};

/** An enumeration. */
export enum WebsiteHealthInterceptorStatus {
  /** Fail */
  Fail = "FAIL",
  /** Success */
  Success = "SUCCESS",
  /** Warning */
  Warning = "WARNING",
}

/** An enumeration. */
export enum WebsiteHealthSettingsStatus {
  /** Fail */
  Fail = "FAIL",
  /** Success */
  Success = "SUCCESS",
  /** Warning */
  Warning = "WARNING",
}

export type WebsiteInventoryStatsResultInputsType = {
  __typename?: "WebsiteInventoryStatsResultInputsType";
  id?: Maybe<Scalars["String"]["output"]>;
  inputType?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type WebsiteInventoryStatsResultType = {
  __typename?: "WebsiteInventoryStatsResultType";
  configId?: Maybe<Scalars["String"]["output"]>;
  configName: Scalars["String"]["output"];
  count: Scalars["Int"]["output"];
  countExpired: Scalars["Int"]["output"];
  countInIndex: Scalars["Int"]["output"];
  countInStale: Scalars["Int"]["output"];
  countNotIndexed: Scalars["Int"]["output"];
  inputGroup: Scalars["String"]["output"];
  inputs: Array<WebsiteInventoryStatsResultInputsType>;
  priority: Scalars["String"]["output"];
  samplesExpired: Array<WebsiteInventoryStatsSamplesType>;
  samplesInIndex: Array<WebsiteInventoryStatsSamplesType>;
  samplesInStale: Array<WebsiteInventoryStatsSamplesType>;
  samplesNotIndexed: Array<WebsiteInventoryStatsSamplesType>;
  sectionName: Scalars["String"]["output"];
};

export type WebsiteInventoryStatsSamplesType = {
  __typename?: "WebsiteInventoryStatsSamplesType";
  device: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type WebsiteInventoryStatsType = {
  __typename?: "WebsiteInventoryStatsType";
  error?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<Array<WebsiteInventoryStatsResultType>>;
};

export type WebsiteInventoryType = {
  __typename?: "WebsiteInventoryType";
  stats?: Maybe<WebsiteInventoryStatsType>;
};

export enum WebsiteIsolationLevel {
  /** No Cache */
  Nocache = "NOCACHE",
  /** Subdomain */
  Subdomain = "SUBDOMAIN",
  /** Website */
  Website = "WEBSITE",
}

export type WebsiteType = {
  __typename?: "WebsiteType";
  active: Scalars["Boolean"]["output"];
  adnConfigStatusesByEnv?: Maybe<Array<AdnConfigStatusesByEnvType>>;
  adnConfigs?: Maybe<Array<AdnConfigType>>;
  adnSubdomainId: Scalars["String"]["output"];
  allHtmlHeadOptimizations?: Maybe<Array<Maybe<HtmlHeadOptimizationType>>>;
  allTimeConfigs?: Maybe<Array<Maybe<AllTimeConfigType>>>;
  allTimeInputs?: Maybe<Array<Maybe<AllTimeInputType>>>;
  allTimeSections?: Maybe<Array<Maybe<AllTimeSectionType>>>;
  allUnversionedBotsDiscoveryInputs: Array<InputBotsDiscoveryType>;
  allUnversionedCsvInputs: Array<InputCsvType>;
  allUnversionedLinksDiscoveryInputs: Array<InputLinksDiscoveryType>;
  allUnversionedManualUploadInputs: Array<InputManualUploadType>;
  allUnversionedQualityControls: Array<QualityControlPreviewType>;
  allUnversionedSections: Array<SectionType>;
  allUnversionedSitemapInputs: Array<InputSitemapType>;
  allUnversionedStreamingInputs: Array<InputStreamingType>;
  allUnversionedTextListInputs: Array<InputTextListType>;
  allVersionsFromModule?: Maybe<Array<Maybe<ModuleType>>>;
  /** Let the rendering farm use Bare metal instances */
  allowMetalInRf: Scalars["Boolean"]["output"];
  annotations: Array<AnnotationType>;
  apiInventoryLimitPerMinute: Scalars["Int"]["output"];
  apiKeys: Array<WebsiteApiKeyType>;
  /** OnDone code */
  baDesktopCode: Scalars["String"]["output"];
  baDesktopJsConfig: Scalars["String"]["output"];
  baDesktopJsRules: Scalars["String"]["output"];
  /** OnLoad code */
  baDesktopOnLoadCode: Scalars["String"]["output"];
  /** OnDone code */
  baMobileCode: Scalars["String"]["output"];
  baMobileJsConfig: Scalars["String"]["output"];
  baMobileJsRules: Scalars["String"]["output"];
  /** OnLoad code */
  baMobileOnLoadCode: Scalars["String"]["output"];
  batchAdditionalFlagsJson: Scalars["String"]["output"];
  batchPocketCrawlerVersion: Scalars["String"]["output"];
  beamDomain?: Maybe<Scalars["String"]["output"]>;
  beamWebsiteId?: Maybe<Scalars["String"]["output"]>;
  botifyAnalyticsInputs: Array<BotifyAnalyticsInputType>;
  botifyProjectSlug: Scalars["String"]["output"];
  botifyProjectUser: Scalars["String"]["output"];
  cluster: ClusterType;
  configs: Array<ConfigType>;
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  creator: UserType;
  debugConfig?: Maybe<WebsiteDebugConfigType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  draftVersion?: Maybe<WebsiteVersionType>;
  expectedDeliveredPagesPerMinute: Scalars["Int"]["output"];
  flattenConfig: Scalars["String"]["output"];
  /** When set to true, Website modules and their KVs will be removed from the Pageworkers cluster and the tag will be replace by an empty JavaScript file */
  flushDataFromPageworkersCluster: Scalars["Boolean"]["output"];
  hasAdnEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  healthInterceptorLastChecked?: Maybe<Scalars["DateTime"]["output"]>;
  healthInterceptorStatus?: Maybe<WebsiteHealthInterceptorStatus>;
  healthSettingsLastChecked?: Maybe<Scalars["DateTime"]["output"]>;
  healthSettingsStatus?: Maybe<WebsiteHealthSettingsStatus>;
  hosts?: Maybe<Scalars["String"]["output"]>;
  htmlHeadOptimization?: Maybe<HtmlHeadOptimizationType>;
  id: Scalars["String"]["output"];
  indexEventDetail?: Maybe<IndexationEventDetail>;
  /** Expressed in dollars. The cost limit of an input when querying it above which we will raise a warning. 1Tb cost around $5. */
  inputCostThreshold: Scalars["Float"]["output"];
  interceptorCheckerConfigs?: Maybe<Array<InterceptorCheckerConfigType>>;
  interceptorCheckerJobs?: Maybe<Array<InterceptorCheckerJobType>>;
  inventory?: Maybe<WebsiteInventoryType>;
  isolationId: Scalars["String"]["output"];
  isolationLevel: WebsiteIsolationLevel;
  linksToDoc: Scalars["String"]["output"];
  linksToReport: Scalars["String"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  moduleFromDraftStableId?: Maybe<ModuleType>;
  moduleTags: Array<ModuleTagType>;
  modules: Array<ModuleType>;
  modulesTypes?: Maybe<Array<ModulesListType>>;
  name: Scalars["String"]["output"];
  overrideBeamHost: Scalars["String"]["output"];
  overrideBeamToken: Scalars["String"]["output"];
  overrideBeamWebsiteId: Scalars["String"]["output"];
  /** bigquery dataset name for website related tables : inputs, cache queue... */
  overrideBigqueryDataset: Scalars["String"]["output"];
  /** bigquery project name for multi-site tables : index_logs, pages_logs... */
  overrideBigqueryGlobalDataset: Scalars["String"]["output"];
  overrideBigqueryIndexLogsTable: Scalars["String"]["output"];
  overrideBigqueryPagesLogsTable: Scalars["String"]["output"];
  /** bigquery project name for website related tables : inputs, cache queue... */
  overrideBigqueryProject: Scalars["String"]["output"];
  /** If set, this website will use a different version of PageWorkers tag during compilation. This should only be used for progressive rollouts, or exceptionally deploying a customer-specific fix, in case of incidents. It should either be empty, a version string (eg. "v0.1.2") or a branch with the "branches/" prefix (eg. "branches/task/PW-XXXX/some-branch") */
  overridePageworkersTagVersion: Scalars["String"]["output"];
  overridePreviewToken: Scalars["String"]["output"];
  overrideStoragePath: Scalars["String"]["output"];
  pageEditImportJobs: Array<ImportJobType>;
  pages: Array<PageEditType>;
  /** If false, PageWorkers tag will not contain custom-js module code. DO NOT EDIT WITHOUT WRITTEN ENGINEERING APPROVAL */
  pageworkersAllowCustomJsModule: Scalars["Boolean"]["output"];
  /** If set, keep the website in pw-websites.json for this alternate cluster (this can be useful for smooth migrations) */
  pageworkersAlternateCluster?: Maybe<PageworkersClusterType>;
  pageworkersCluster?: Maybe<PageworkersClusterType>;
  /** The max number of urls that the user can pick for Sitecrawler integration filters.  */
  pageworkersCrawledUrlsMaxNumber: Scalars["Int"]["output"];
  /** The size limit in MB allowed for client uploaded CSVs. It's used when generating signed urls. */
  pageworkersCsvUploadFileSizeInMb: Scalars["Int"]["output"];
  /** If set to true, PageWorkers tag will enable logic to rerun the modules when the page has changed. ONLY AFFECTS BROWSER ENV. */
  pageworkersEnableModulesReruns: Scalars["Boolean"]["output"];
  /** The KV size limit in MB of memory inside the delivery api (should be greater or equal than pageworkers_csv_upload_file_size_in_mb) */
  pageworkersKvInMemoryMaxSizeInMb: Scalars["Int"]["output"];
  /** If modules reruns are enabled, will limit the number of times a given module can be executed. */
  pageworkersMaximumModulesRerunsCount: Scalars["Int"]["output"];
  /** Duration in ms. If modules reruns are enabled, will limit the duration of the rerun window */
  pageworkersMaximumModulesRerunsDuration: Scalars["Int"]["output"];
  /** Duration in ms. URL manager module will be rerun after given delay. Input 0 will disable the rerun. */
  pageworkersUrlManagerModulesRerunDelay: Scalars["Int"]["output"];
  previewToken?: Maybe<Scalars["String"]["output"]>;
  productionVersion?: Maybe<WebsiteVersionType>;
  qualityControls?: Maybe<Array<Maybe<QualityControlType>>>;
  qualitycontrollivetestjobSet: Array<QualityControlLiveTestJobType>;
  queryContentSubmitPages: Array<QueryContentSubmitPagesResult>;
  queryCrawlStats?: Maybe<CrawlStatsType>;
  queryIndexedPages: Array<QueryIndexedPagesResult>;
  queryInventoryStats: Array<QueryInventoryStatsResult>;
  queryJobs?: Maybe<Array<JobType>>;
  querySearchEngineIndex?: Maybe<SearchEngineIndexType>;
  querySearchEngineIndexAge?: Maybe<SearchEngineIndexAgeType>;
  queryServedPages: Array<QueryServedPagesResult>;
  rfPortalConfiguration: Scalars["String"]["output"];
  /** When set to true, PageWorkers will be disabled: the tag will be replaced by an empty JavaScript file */
  serveEmptyPageworkersTag: Scalars["Boolean"]["output"];
  settingsCheckerHistory: Array<SettingsCheckerJobType>;
  settingsCheckerJobs?: Maybe<Array<Maybe<SettingsCheckerJobType>>>;
  /** When filled, customer can call beam service feeding a relative URL */
  singleDomain: Scalars["String"]["output"];
  smartlinkOptimization?: Maybe<SmartLinkOptimizationType>;
  smartlinkOptimizations?: Maybe<Array<Maybe<SmartLinkOptimizationType>>>;
  /** Use Botify Static IPs when fetching pages */
  staticIp: Scalars["Boolean"]["output"];
  /** Put Beam server in safe mode which will stop delivering pages */
  stopDeliveryBeam: Scalars["Boolean"]["output"];
  storageConfigDateAsked?: Maybe<Scalars["DateTime"]["output"]>;
  storageConfigDateLastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  storageConfigNeedsResync: Scalars["Boolean"]["output"];
  storagePath: Scalars["String"]["output"];
  versions: Array<WebsiteVersionType>;
};

export type WebsiteTypeAdnConfigStatusesByEnvArgs = {
  pagination?: InputMaybe<Scalars["JSONString"]["input"]>;
};

export type WebsiteTypeAllVersionsFromModuleArgs = {
  stableId?: InputMaybe<Scalars["String"]["input"]>;
  websiteId?: InputMaybe<Scalars["String"]["input"]>;
};

export type WebsiteTypeHtmlHeadOptimizationArgs = {
  id: Scalars["ID"]["input"];
};

export type WebsiteTypeIndexEventDetailArgs = {
  eventTimestamp?: InputMaybe<Scalars["Int"]["input"]>;
  fileUriHash?: InputMaybe<Scalars["BigInt"]["input"]>;
  pageId?: InputMaybe<Scalars["BigInt"]["input"]>;
  pageKey?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type WebsiteTypeInterceptorCheckerConfigsArgs = {
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeInterceptorCheckerJobsArgs = {
  filterDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterRunning?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeModuleFromDraftStableIdArgs = {
  stableId?: InputMaybe<Scalars["String"]["input"]>;
};

export type WebsiteTypeQueryContentSubmitPagesArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  timestampEnd?: InputMaybe<Scalars["Int"]["input"]>;
  timestampStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeQueryIndexedPagesArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  timestampEnd?: InputMaybe<Scalars["Int"]["input"]>;
  timestampStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeQueryInventoryStatsArgs = {
  dateEnd?: InputMaybe<Scalars["Date"]["input"]>;
  dateStart?: InputMaybe<Scalars["Date"]["input"]>;
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeQueryJobsArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  timestampEnd?: InputMaybe<Scalars["Int"]["input"]>;
  timestampStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeQuerySearchEngineIndexArgs = {
  botId?: InputMaybe<Scalars["Int"]["input"]>;
  nbDays?: InputMaybe<Scalars["Int"]["input"]>;
  searchEngineId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeQuerySearchEngineIndexAgeArgs = {
  searchEngineId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeQueryServedPagesArgs = {
  dimensions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Scalars["JSONString"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  postFilters?: InputMaybe<Scalars["JSONString"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
  timestampEnd?: InputMaybe<Scalars["Int"]["input"]>;
  timestampStart?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeSettingsCheckerJobsArgs = {
  filterDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterRunning?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebsiteTypeSmartlinkOptimizationArgs = {
  id: Scalars["ID"]["input"];
};

/** An enumeration. */
export enum WebsiteVersionLinksSanitizerScript {
  /** Go */
  Go = "GO",
  /** Js */
  Js = "JS",
}

export type WebsiteVersionType = {
  __typename?: "WebsiteVersionType";
  badBotCustomHtml: Scalars["String"]["output"];
  botsDiscoveryInputs: Array<InputBotsDiscoveryType>;
  comment: Scalars["String"]["output"];
  configs: Array<ConfigType>;
  createdDate?: Maybe<Scalars["DateTime"]["output"]>;
  csvInputs: Array<InputCsvType>;
  /** Real Client IP sent by the interceptor */
  customClientIpHeader: Scalars["String"]["output"];
  dateReleasedOnProduction?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedByCascade: Scalars["Boolean"]["output"];
  enableIncrementalCache: Scalars["Boolean"]["output"];
  /** Activate PageWorkers in SpeedWorkers */
  enablePwInSw: Scalars["Boolean"]["output"];
  enableRenderingTracking: Scalars["Boolean"]["output"];
  enableSmartCacheRefresh: Scalars["Boolean"]["output"];
  filterBadBots: Scalars["Boolean"]["output"];
  /** Force absolute redirect URLs */
  forceAbsoluteRedirect: Scalars["Boolean"]["output"];
  hosts: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  /** The minimum refresh interval for index table. */
  indexMinimumRefreshInterval: Scalars["Int"]["output"];
  inputs?: Maybe<Array<InputDescriptor>>;
  isDraftVersion?: Maybe<Scalars["Boolean"]["output"]>;
  isProductionVersion?: Maybe<Scalars["Boolean"]["output"]>;
  jsCodes: Array<JsCodeType>;
  lastEditedBy: Scalars["String"]["output"];
  linksDiscoveryInputs: Array<InputLinksDiscoveryType>;
  /** [deprecated] */
  linksSanitizerScript: WebsiteVersionLinksSanitizerScript;
  liveMaxSpeed?: Maybe<Scalars["Decimal"]["output"]>;
  liveupdateFeeds: Array<LiveUpdateConfigurationType>;
  /** Export logs to S3 daily */
  logsExport: Scalars["Boolean"]["output"];
  /** Start time of log export (UTC) */
  logsExportTime: Scalars["Time"]["output"];
  manualUploadInputs: Array<InputManualUploadType>;
  /** Max Number of Pages / Sec */
  maxSpeed: Scalars["Decimal"]["output"];
  modifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
  noDeliveryStatusCode?: Maybe<Scalars["Int"]["output"]>;
  onActionCode?: Maybe<Scalars["String"]["output"]>;
  onDomContentLoadedCode?: Maybe<Scalars["String"]["output"]>;
  onDoneCode?: Maybe<Scalars["String"]["output"]>;
  onInitCode?: Maybe<Scalars["String"]["output"]>;
  onLoadCode?: Maybe<Scalars["String"]["output"]>;
  onPreBeamResponseCode?: Maybe<Scalars["String"]["output"]>;
  onSetupBeforeRenderCode?: Maybe<Scalars["String"]["output"]>;
  onWaitForCode?: Maybe<Scalars["String"]["output"]>;
  pageEditCode?: Maybe<Scalars["String"]["output"]>;
  /** The minimum refresh interval for pages_logs (dispatcher) table. */
  pagesMinimumRefreshInterval: Scalars["Int"]["output"];
  qualityControls: Array<QualityControlType>;
  releasedOnProduction: Scalars["Boolean"]["output"];
  /** For how long the rendering farm will refuse to re-render a page that has already been sent to the renderers and that hasn't been indexed. Default value is twice the batch duration. */
  renderingFarmDeduplicationDelaySec: Scalars["Int"]["output"];
  /** Typical batch duration, usually 1h. However on big websites, we may want to set something bigger to reduce BQ costs */
  renderingFarmMaxBatchDurationSec: Scalars["Int"]["output"];
  /** The back won't recompute the cache queue for a website if it has already been computed less than maxCacheQueueAgeSec ago. Default value is 10 minutes. */
  renderingFarmMaxCacheQueueAgeSec: Scalars["Int"]["output"];
  /** Minimum delay between two batches. By default 20 minutes. On small websites, we may want to set something like 1h or more if we don't have some pages to refresh every hour. */
  renderingFarmMinBatchIntervalSec: Scalars["Int"]["output"];
  /** Do not decode URL when receiving it on Beam Service (customer can't urlencode) */
  requestUrlRaw: Scalars["Boolean"]["output"];
  sections: Array<SectionType>;
  segmentationRules?: Maybe<Scalars["String"]["output"]>;
  sitemapInputs: Array<InputSitemapType>;
  streamingInputs: Array<InputStreamingType>;
  textListInputs: Array<InputTextListType>;
  useRenderingFarm: Scalars["Boolean"]["output"];
  version: Scalars["Int"]["output"];
  website: WebsiteType;
};

export type WebsiteVersionTypeBotsDiscoveryInputsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeCsvInputsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeJsCodesArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeLinksDiscoveryInputsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeManualUploadInputsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeQualityControlsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeSectionsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeSitemapInputsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeStreamingInputsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionTypeTextListInputsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WebsiteVersionsDiffType = {
  __typename?: "WebsiteVersionsDiffType";
  newVersion: Scalars["String"]["output"];
  oldVersion: Scalars["String"]["output"];
  schema: Scalars["String"]["output"];
};

export type WebsitesFilterInput = {
  hasAdn?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasBotsDiscoveryInputs?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasLinksDiscoveryInputs?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasRenderingFarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasSmartCacheRefresh?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDemo?: InputMaybe<Scalars["Boolean"]["input"]>;
  nameHostsIdCluster?: InputMaybe<Scalars["String"]["input"]>;
};

export type WebsitesList = {
  __typename?: "WebsitesList";
  pageInfo: PaginationInfoType;
  websites: Array<WebsiteType>;
};
