import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DataHandler } from "../../../components/DataHandler";
import { CreateJsCodeMutation } from "../../../gql/types.generated";
import { CREATE_WEBSITE_SCRIPT } from "../../../gql/websites/scripts/createWebsiteScript";
import { CreateJsCodeMutationVariables } from "../../../gql/websites/scripts/createWebsiteScript.generated";
import { GET_DRAFT_VERSION_LIGHT } from "../../../gql/websites/website/getDraftVersionLight";
import {
  DraftVersionLightQuery,
  DraftVersionLightQueryVariables,
} from "../../../gql/websites/website/getDraftVersionLight.generated";
import { BreadcrumbTitle } from "../../components/header/Title";
import { WebsiteIdParams } from "../../types/routeParams";
import {
  ScriptSubmitForm,
  ScriptSubmitFormProps,
  ScriptSubmitFormValues,
} from "../components/ScriptSubmitForm";
import { defaultFormValues } from "../components/ScriptSubmitForm/mappers";

export const ScriptCreationPage = () => {
  const websiteId = useParams<WebsiteIdParams>().websiteId!;
  const { data, loading, error } = useQuery<
    DraftVersionLightQuery,
    DraftVersionLightQueryVariables
  >(GET_DRAFT_VERSION_LIGHT, { variables: { websiteId } });
  const website = data?.website;
  const draftVersion = website?.draftVersion;
  const luConfigs = data?.liveupdate.configurations ?? [];

  if (loading || error || !website || !draftVersion) {
    return (
      <DataHandler
        expectData
        data={website ?? draftVersion}
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <div>
      <BreadcrumbTitle
        website={website}
        steps={["Script Management", "Create"]}
      />
      <ScriptCreationForm
        websiteId={websiteId}
        websiteVersionId={draftVersion.id}
        sections={draftVersion.sections}
        luConfigs={luConfigs}
      />
    </div>
  );
};

type ScriptCreationFormProps = {
  websiteId: string;
  websiteVersionId: string;
  sections: ScriptSubmitFormProps["sections"];
  luConfigs: ScriptSubmitFormProps["luConfigs"];
};

const ScriptCreationForm = ({
  websiteId,
  websiteVersionId,
  sections,
  luConfigs,
}: ScriptCreationFormProps) => {
  const navigate = useNavigate();

  const [createWebsiteScript, { loading }] = useMutation<
    CreateJsCodeMutation,
    CreateJsCodeMutationVariables,
    { scriptName: string }
  >(CREATE_WEBSITE_SCRIPT, {
    onCompleted: (data) => {
      // FIXME: fix type generation
      // @ts-expect-error generated type is incorrect (missing createJsCode field)
      const created = data?.createJsCode?.jsCode;
      if (created) {
        toast(`Script "${created.name}" successfully created.`, {
          type: "success",
        });
        navigate(`/website/${websiteId}/scripts`);
      }
    },
    onError: (error, clientOptions) => {
      const { scriptName } = clientOptions!.context!;
      toast(`Failed to create script "${scriptName}": ${error.message}`, {
        type: "error",
      });
    },
  });

  const submitForm = (formValues: ScriptSubmitFormValues) => {
    createWebsiteScript({
      variables: { script: formValues },
      context: { scriptName: formValues.name },
    });
  };

  return (
    <ScriptSubmitForm
      base={defaultFormValues(websiteVersionId)}
      onSubmit={submitForm}
      submitting={loading}
      sections={sections}
      luConfigs={luConfigs}
    />
  );
};
