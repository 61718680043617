export const createLiveUpdateSnippet = (configurationStableId: string) =>
  `
const lu = new PageEdit.LiveUpdatePDP({
  response,
  // Product's KV key, might not be the URL
  url: response.context.url,
  kvId: "${configurationStableId}",
});

lu.registerPrice({
  cssSelectors: [],
  update: (element, value) => {
    
  },
});

lu.registerInitialPrice({
  cssSelectors: [],
  update: (element, value) => {
    
  },
  create: (value) => {
    
  },
});

lu.registerAvailability({
  cssSelectors: [],
  update: (element, value) => {
    
  },
});

lu.registerStructuredData({
  cssSelector: "script[type='application/ld+json']",
  jsonPath: "$.offers",
});

lu.apply()

`.slice(1);
