import { deliveryContext, renderingContext } from "../../contexts";

export type Context = "delivery" | "rendering";

type ContextLibs = {
  lib: string[];
  extraLibs: { content: string; filePath: string }[];
};

export const getContextLibs = (context: Context | undefined): ContextLibs =>
  !context ? defaultLibs : libsByContext[context];

const defaultLibs = { lib: ["es2021"], extraLibs: [] } satisfies ContextLibs;

const libsByContext = {
  rendering: {
    lib: ["es2021", "dom"],
    extraLibs: [{ content: renderingContext, filePath: "rendering.d.ts" }],
  },
  delivery: {
    lib: ["es2021"],
    extraLibs: [{ content: deliveryContext, filePath: "delivery.d.ts" }],
  },
} satisfies Record<Context, ContextLibs>;
