import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { Editor } from "../../../components/Editor";
import { PreviewIngestionMapping } from "../../../ftlwasm/liveupdate.wasm";
import { getMappingSchema } from "./mapping.schema";

export type IConfigTesterForm = {
  source: string;
  mapping: string;
  separator: string;
  lazyQuotes: boolean;
};

type ConfigTesterFormProps = {
  onSubmit: (data: IConfigTesterForm) => void;
};

export const ConfigTesterForm = ({ onSubmit }: ConfigTesterFormProps) => {
  const { register, handleSubmit, watch, setValue } =
    useForm<IConfigTesterForm>({
      defaultValues: {
        source: defaultSource,
        mapping: defaultMapping,
        separator: ",",
        lazyQuotes: false,
      },
    });
  const { source, separator, mapping } = watch();
  const columns = source?.split?.("\n")[0]?.split(separator) ?? [];

  return (
    <Form
      className="col-md-6"
      action=""
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {/* Source feed */}
      <Form.Group controlId="source" className="col-12">
        <Form.Label>Feed sample</Form.Label>
        <Editor
          language="plaintext"
          value={source}
          onChange={(value) => setValue("source", value)}
          height={150}
        />
        <Form.Control.Feedback type="invalid">
          Must be valid CSV
        </Form.Control.Feedback>
        <Form.Text id="sourceHelp" muted>
          Sample of the CSV source feed, including the header.
        </Form.Text>
      </Form.Group>

      {/* Feed mapping */}
      <Form.Group controlId="mapping">
        <Form.Label>Feed mapping</Form.Label>
        <Editor
          language="json"
          jsonSchemas={[
            {
              uri: "schema.json",
              fileMatch: ["*"],
              schema: getMappingSchema(columns),
            },
          ]}
          value={mapping}
          onChange={(value) => setValue("mapping", value)}
          height={600}
        />
        <Form.Control.Feedback type="invalid">
          Not a valid feed mapping
        </Form.Control.Feedback>
        <Form.Text id="mappingHelp" muted>
          JSON mapping to test
        </Form.Text>
      </Form.Group>

      {/* Separator */}
      <Form.Group controlId="separator">
        <Form.Label>CSV separator</Form.Label>
        <Form.Control {...register("separator")} />
      </Form.Group>

      {/* Lazy quotes */}
      <Form.Check
        {...register("lazyQuotes")}
        id="lazyQuotes"
        label="Lazy quotes"
      />

      <Button style={{ width: "max-content" }} variant="primary" type="submit">
        Preview
      </Button>
    </Form>
  );
};

const defaultSource = (
  [
    ["link", "price", "sale_price", "availability"],
    ["abc", "12.00 USD", "9.00 USD", "in_stock"],
    ["def", "", "", "in_stock"],
    ["ghi", "12.00 USD", "", "out_of_stock"],
  ] satisfies string[][]
)
  .map((fields) => fields.join(","))
  .join("\n") satisfies string;

const defaultMapping = JSON.stringify(
  {
    key: { column: "link" },
    base_price: {
      column: "price",
      split: { separator: " ", position: 0 },
    },
    sale_price: {
      column: "sale_price",
      split: { separator: " ", position: 0 },
    },
    currency: {
      column: "price",
      split: { separator: " ", position: 1 },
    },
    availability: {
      column: "availability",
      mapping: {
        in_stock: "InStock",
        out_of_stock: "OutOfStock",
      },
    },
  } satisfies PreviewIngestionMapping,
  null,
  2
);
