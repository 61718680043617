const getMapperSchema = (columns: string[]) => ({
  type: "object",
  properties: {
    column: {
      enum: columns,
      description: "Source column to read from for the current field",
    },
    defaultValue: {
      type: "string",
      description:
        "Default value to use when the current field value is empty, " +
        "after application of the transformers.",
    },
    regex: {
      type: "string",
      description:
        "Extract a value from the current field value using a Regex.\n" +
        "It uses the value of the first capturing group.\n" +
        "Warning: regex escapes (\\) must be doubled due to the JSON format.\n" +
        'e.g. "currency=(\\\\w+)".', // escapeception
    },
    mapping: {
      type: "object",
      patternProperties: { ".*": { type: "string" } },
      description:
        "Apply a value mapping.\n" +
        "If a field matches one of the keys, it is resolved to the value for that key.\n",
    },
    split: {
      type: "object",
      properties: {
        separator: {
          type: "string",
          description:
            "String value that separates the current field values.\n" +
            'e.g. " " in "3.00 USD".',
        },
        position: {
          type: "integer",
          description:
            "Position of the final value to retrieve after split, starting from 0.\n" +
            'e.g. "3.00 USD" -> 0 for price and 1 for currency.',
        },
      },
      required: ["separator", "position"],
      description:
        "Split the field and return the value at given position.\n" +
        'Typically used to extract price and currency from a single field, e.g. "3.00 USD".',
    },
  },
  required: ["column"],
  not: {
    anyOf: [
      { required: ["mapping", "regex"] },
      { required: ["mapping", "split"] },
      { required: ["split", "regex"] },
    ],
  },
  additionalProperties: false,
});

const getAvailabilityMapperSchema = (columns: string[]) => {
  const schema = getMapperSchema(columns);
  schema.properties.mapping.patternProperties = {
    ".*": {
      // @ts-expect-error: doesn't match the type of patternProperties
      // from MAPPER_SCHEMA which is perfectly normal
      enum: [
        "InStock",
        "OutOfStock",
        "PreSale",
        "OutOfOrder",
        "BackOrder",
        "Discontinued",
        "InStoreOnly",
        "LimitedAvailability",
        "OnlineOnly",
      ],
    },
  };

  return schema;
};

export const getMappingSchema = (columns: string[]) => {
  const mapperSchema = getMapperSchema(columns);

  return {
    type: "object",
    properties: {
      key: {
        ...mapperSchema,
        description:
          "Unique product identifier, must be retrievable from the product page. " +
          "Usually a product id or URL. " +
          "If the value is empty, the product is ignored.",
      },
      base_price: {
        ...mapperSchema,
        description:
          "Product price before sale application. " +
          "If the value is empty, the product is ignored.",
      },
      sale_price: {
        ...mapperSchema,
        description: "Product price after sale application.",
      },
      currency: {
        ...mapperSchema,
        description: "Product price currency.",
      },
      availability: {
        ...getAvailabilityMapperSchema(columns),
        description: "Product availability.",
      },
    },
    required: ["key", "base_price"],
    additionalProperties: false,
  };
};
