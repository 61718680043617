import { useState } from "react";
import { Alert, Tab, Table, Tabs } from "react-bootstrap";
import { downloadHTML } from "../../../../common/browser";
import { QC_STATUSES_IDX } from "../../../../constants";
import { HtmlUpdatesLogType } from "../../../../gql/types.generated";
import { ACTIONS_TEMPLATES } from "../../../constants";
import { IPreviewState } from "./preview";

type JsPreviewResultsProps = {
  preview: IPreviewState | null;
};

export const JsPreviewResults = ({ preview }: JsPreviewResultsProps) => {
  const defaultTab = "html";
  const [tab, setTab] = useState(defaultTab);

  return (
    <Tabs activeKey={tab} onSelect={(key) => setTab(key ?? defaultTab)}>
      <Tab eventKey="html" title="HTML">
        <ResultTab
          preview={preview}
          content={(preview) => (
            <>
              {preview?.html ? (
                <>
                  <div>
                    <a
                      href={downloadHTML(preview.html)}
                      download={getFilename(preview.url)}
                    >
                      Download HTML
                    </a>
                  </div>

                  <pre id="html">{preview.html}</pre>
                </>
              ) : (
                <>Empty HTML, possibly a fallback to origin, consult logs</>
              )}
            </>
          )}
        />
      </Tab>
      <Tab eventKey="preview" title="Preview" className="h-100">
        {/* sandbox Prevent redirects on top page */}
        <ResultTab
          preview={preview}
          content={(preview) => (
            <iframe
              title="Preview HTML"
              srcDoc={preview.html}
              width="100%"
              height="1024px"
              sandbox=""
            />
          )}
        />
      </Tab>
      <Tab eventKey="headers" title="Headers">
        <ResultTab
          preview={preview}
          content={(preview) => (
            <>
              <h4>Headers</h4>
              <Table>
                {preview?.headers?.map(([key, val]) => (
                  <tr>
                    <td>{key}</td>
                    <td>{val}</td>
                  </tr>
                ))}
              </Table>
            </>
          )}
        />
      </Tab>
      <Tab
        eventKey="html-updates"
        title={`HTML Updates (${preview ? (preview.stats?.htmlUpdates?.log || []).length : "x"})`}
      >
        <ResultTab
          preview={preview}
          content={(preview) => (
            <>
              {preview.stats?.htmlUpdates?.log?.map((entry) => (
                <div className="item">
                  {getActionMessage(entry)} <hr />
                </div>
              ))}
            </>
          )}
        />
      </Tab>
      <Tab eventKey="debug" title="Debug">
        <ResultTab
          preview={preview}
          content={(preview) => (
            <>
              {preview && preview.jsCodeRendering && (
                <div>
                  <h4>JS Generated Code</h4>
                  <p>Rendering</p>
                  <pre id="html">{preview.jsCodeRendering}</pre>
                  <p>Pre Beam Response</p>
                  <pre id="html">{preview.jsCodePreBeamResponse}</pre>
                </div>
              )}
            </>
          )}
        />
      </Tab>
      <Tab
        eventKey="logs"
        title={
          "Logs (" + (preview?.logs ? (preview.logs || []).length : "x") + ")"
        }
      >
        <ResultTab
          preview={preview}
          content={(preview) => (
            <>
              {preview.logs?.length && (
                <div>
                  <h4>Logs ({preview.logs.length})</h4>

                  {preview.logs.map((entry) => {
                    return <div>{entry}</div>;
                  })}
                </div>
              )}{" "}
            </>
          )}
        />
      </Tab>
      <Tab
        eventKey="properties"
        title={
          "Properties (" +
          (preview?.stats?.properties
            ? Object.keys(preview.stats?.properties)?.length
            : "x") +
          ")"
        }
      >
        <ResultTab
          preview={preview}
          displayOnlyOnSuccess={true}
          content={(preview) => (
            <>
              {preview &&
              Object.keys(preview.stats?.properties ?? {}).length > 0 ? (
                <div>
                  <h4>Properties</h4>

                  <Table>
                    {Object.entries(preview.stats?.properties ?? {}).map(
                      ([name, value]) => {
                        return (
                          <tr>
                            <td>{name}</td>
                            <td>{value}</td>
                          </tr>
                        );
                      }
                    )}
                  </Table>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        />
      </Tab>
      <Tab eventKey="qc" title="Quality Controls">
        <ResultTab
          preview={preview}
          content={(preview) => (
            <>
              {preview?.stats?.qualityControl?.all?.length && (
                <>
                  <h4>Quality Control</h4>
                  {preview.stats.qualityControl.errors?.length && (
                    <div>
                      Execution Errors :{" "}
                      {preview.stats.qualityControl.errors.map((e) => (
                        <p>{e}</p>
                      ))}
                    </div>
                  )}

                  {preview.stats.qualityControl.all.map((qc) => {
                    return (
                      <div>
                        <span
                          className={`base badge-${QC_STATUSES_IDX[qc.status]}`}
                        >
                          {QC_STATUSES_IDX[qc.status]}
                        </span>{" "}
                        {qc.name}
                        {qc.unsuccessMessage && (
                          <>/ Reason: {qc.unsuccessMessage}</>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        />
      </Tab>
    </Tabs>
  );
};

interface IResultTabProps {
  preview: IPreviewState | null;
  content: (preview: IPreviewState) => JSX.Element;
  displayOnlyOnSuccess?: boolean;
}

function ResultTab({
  preview,
  content,
  displayOnlyOnSuccess = false,
}: IResultTabProps) {
  if (!preview) {
    return (
      <div style={{ minHeight: "1024px" }}>Launch a Preview to see results</div>
    );
  }
  return (
    <div style={{ minHeight: "1024px" }}>
      <WarningsAndErrors preview={preview} />
      {preview.status === "rendering_failed" && (
        <Alert variant="danger">Rendering Failed !</Alert>
      )}
      {preview.status === "pre_beam_response_failed" && (
        <Alert variant="danger">Pre Beam Response Failed !</Alert>
      )}
      {(!displayOnlyOnSuccess || preview.status === "success") &&
        content(preview)}
    </div>
  );
}

const WarningsAndErrors = ({ preview }: { preview: IPreviewState | null }) => {
  if (!preview) return <></>;
  const { jsError, serverError, stats } = preview;
  return (
    <div>
      {jsError && (
        <div>
          <h4>Js Error</h4>
          {jsError}
        </div>
      )}

      {serverError && (
        <div>
          <h4>Server Error</h4>
          <pre>{serverError}</pre>
        </div>
      )}

      {stats && Object.keys(preview.stats).length > 0 && (
        <div>
          {!!stats.htmlUpdates?.warningMessages?.length && (
            <div>
              <h4>
                Warning Messages ({stats.htmlUpdates.warningMessages.length})
              </h4>

              {stats.htmlUpdates.warningMessages.map((entry) => {
                return <div>{entry}</div>;
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const getFilename = (url: string) => {
  // TODO: refactor legacy code
  let filename = url.substring(url.lastIndexOf("/") + 1);
  if (filename === "") {
    filename = "index.html";
  }
  if (!filename.endsWith(".html")) {
    filename += ".html";
  }
  return filename;
};

const getActionMessage = (args: HtmlUpdatesLogType) => {
  // TODO: refactor legacy code
  const action = args.action?.toLowerCase() ?? "";
  const template = ACTIONS_TEMPLATES[action as keyof typeof ACTIONS_TEMPLATES];
  const Component = template?.[args.element as keyof typeof template];

  if (!Component) return JSON.stringify(args);

  return (
    <div>
      <div>
        <Component {...args} />
      </div>
      {args.context && (
        <div>
          <strong>Context:</strong> {args.context}
        </div>
      )}
    </div>
  );
};
