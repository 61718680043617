import { useQuery } from "@apollo/client";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FieldErrors, useForm } from "../../common/hooks/useForm";
import { InputLockable } from "../../components/InputLockable/InputLockable";
import type { AdnConfigType, WebsiteType } from "../../gql/types.generated";
import { GET_ADN_VERSIONS } from "../../gql/websites/adn/getAdnVersions.gql";
import { GetAdnVersionsQuery } from "../../gql/websites/adn/getAdnVersions.gql.generated";
import { botsRegex } from "./bots";
import {
  DEFAULT_ASSETS_REGEX,
  DEFAULT_FAILOVER_USER_AGENT,
  DEPLOY_TESTS,
  DEPLOY_TESTS_IDS,
} from "./constants";
import { isValidJSONHeaders, isValidURL, isValidWorkerVersion } from "./utils";
import {FORBIDDEN_UA} from "../constants";

type DeployTestIds = (typeof DEPLOY_TESTS)[number][0];

export interface ADNConfigFormData {
  id?: string;
  failoverUserAgent: string;
  failoverRequestHeaders: string;
  failoverRequestHeadersFilters: string;
  failoverResponseHeaders: string;
  customerOriginUrl: string;
  customerOriginPort: number;
  customerOriginTimeout: number;
  swOriginTimeout: number;
  swRealOrigin: string;
  ignoredPathsRegex: string;
  workerVersion: string;
  interceptedBotsRegex: string;
  urlCacheHit: string;
  website: string;
  deployTests: DeployTestIds[];
}

export interface IADNConfigFormProps {
  adnConfig?: Partial<AdnConfigType>;
  websiteId?: WebsiteType["id"];
  submitting?: boolean;
  messages?: React.ReactNode | React.ReactNode[];
  onSubmit: (
    data: ADNConfigFormData,
    errors: FieldErrors<ADNConfigFormData>
  ) => void;
}

export function ADNConfigForm({
  adnConfig,
  websiteId,
  submitting,
  messages,
  onSubmit,
}: IADNConfigFormProps) {
  const {
    register,
    originalRegister,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm<ADNConfigFormData>({
    mode: "onTouched",
    defaultValues: {
      ...(adnConfig
        ? {
            id: adnConfig.id,
            failoverUserAgent: adnConfig.failoverUserAgent ?? undefined,
            failoverRequestHeaders:
              adnConfig.failoverRequestHeaders ?? undefined,
            failoverRequestHeadersFilters:
              adnConfig.failoverRequestHeadersFilters ?? undefined,
            failoverResponseHeaders:
              adnConfig.failoverResponseHeaders ?? undefined,
            customerOriginUrl: adnConfig.customerOriginUrl ?? undefined,
            customerOriginPort: adnConfig.customerOriginPort ?? undefined,
            customerOriginTimeout: adnConfig.customerOriginTimeout,
            swOriginTimeout: adnConfig.swOriginTimeout,
            swRealOrigin: adnConfig.swRealOrigin ?? undefined,
            ignoredPathsRegex: adnConfig.ignoredPathsRegex,
            workerVersion: adnConfig.workerVersion,
            interceptedBotsRegex: adnConfig.interceptedBotsRegex,
            urlCacheHit: adnConfig.urlCacheHit ?? undefined,
            deployTests:
              (adnConfig?.deployTests as DeployTestIds[]) ?? DEPLOY_TESTS_IDS,
          }
        : {
            deployTests: DEPLOY_TESTS_IDS,
            ignoredPathsRegex: DEFAULT_ASSETS_REGEX,
            failoverUserAgent: DEFAULT_FAILOVER_USER_AGENT,
            interceptedBotsRegex: FORBIDDEN_UA.source.replaceAll("\\", ""),
          }),
      website: websiteId,
    },
  });

  const { loading, error, data } =
    useQuery<GetAdnVersionsQuery>(GET_ADN_VERSIONS);

  if (loading) return <>Loading…</>;
  if (error) return <>{`Error! ${error}`}</>;
  if (!data?.adnVersions) return <>No Data</>;

  const versionsList = data.adnVersions.map((adnVersion) => adnVersion.version);

  const submitText = (() => {
    if (!adnConfig || !adnConfig.id) {
      return "Create";
    }
    return adnConfig.isDraft ? "Update" : "Clone";
  })();

  const interceptedBotsRegexValue = watch("interceptedBotsRegex");
  const interceptedBots = botsRegex.toShards(interceptedBotsRegexValue);
  const interceptedBotsMap = interceptedBots.reduce(
    (result, bot) => {
      result[bot] = bot;
      return result;
    },
    {} as Record<string, string>
  );

  const currentDeployTests: DeployTestIds[] = getValues().deployTests;

  function updateDeployTests(e: React.ChangeEvent<HTMLInputElement>) {
    const shouldTest = e.target.checked;
    const deployTest = e.target.value as DeployTestIds;

    let newDeployTests = currentDeployTests;

    if (shouldTest) {
      newDeployTests = [...newDeployTests, deployTest];
    } else {
      newDeployTests = newDeployTests.filter((d) => d !== deployTest);
    }

    setValue("deployTests", newDeployTests, {
      shouldValidate: true,
    });
  }

  function onInterceptedBotsBuilderChange(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const shouldIntercept = e.target.checked;
    const bot = e.target.value;

    // Either add bot shard or remove it
    const newInterceptedBots = (() => {
      if (shouldIntercept) {
        return interceptedBots.concat(bot);
      }
      return interceptedBots.filter((intercepted) => bot !== intercepted);
    })();

    // Sort bot shards for better UX
    const newBotsSorted = newInterceptedBots.sort((a, b) => a.localeCompare(b));

    // Update regex
    setValue("interceptedBotsRegex", botsRegex.fromShards(newBotsSorted), {
      shouldValidate: true,
    });
  }

  return (
    <Form action="" onSubmit={handleSubmit((data) => onSubmit(data, errors))}>
      {messages}

      {/* not registered to avoid to sending it */}
      {adnConfig?.createdDate ? (
        <Form.Group controlId="createdDate" as={Row}>
          <Form.Label column sm="2">
            Created at
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              value={moment(adnConfig.createdDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            />
          </Col>
        </Form.Group>
      ) : null}

      {/* not registered to avoid to sending it */}
      {adnConfig?.modifiedDate ? (
        <Form.Group controlId="modifiedDate" as={Row}>
          <Form.Label column sm="2">
            Modified at
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              value={moment(adnConfig.modifiedDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            />
          </Col>
        </Form.Group>
      ) : null}

      <Form.Group controlId="failoverUserAgent">
        <Form.Label>Failover User Agent</Form.Label>
        <Form.Control
          {...register("failoverUserAgent", {
            maxLength: 255,
          })}
          placeholder="e.g. botify-bot-sw-example"
          aria-describedby="failoverUserAgentHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is limited to 255 characters.
        </Form.Control.Feedback>
        <Form.Text id="failoverUserAgentHelp" muted>
          When accessing the customer's origin, it allows to bypass eventual
          restrictions by asking the customer to allow our user agent or give us
          an authorized user agent.
          <br />
          Otherwise, it helps prevent infinite request loops if failovers go
          through ADN again.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="failoverRequestHeaders">
        <Form.Label>Failover Request Headers</Form.Label>
        <Form.Control
          as="textarea"
          {...register("failoverRequestHeaders", {
            validate: isValidJSONHeaders,
          })}
          placeholder={`e.g. { "X-Custom-Header-1": "my header value", "X-Custom-Header-2": "my other header value" }`}
          aria-describedby="failoverRequestHeadersHelp"
        />
        <Form.Control.Feedback type="invalid">
          Must be valid JSON object with string values.
        </Form.Control.Feedback>
        <Form.Text id="failoverRequestHeadersHelp" muted>
          Extra headers we add to the failover request we send to the customer.
          Useful to bypass eventual restrictions set by the customer.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="failoverRequestHeadersFilters">
        <Form.Label>Failover Request Headers Filters</Form.Label>
        <Form.Control
          as="textarea"
          {...register("failoverRequestHeadersFilters", {
            validate: isValidJSONHeaders,
          })}
          placeholder={`e.g. [ "X-Custom-Header-1", "X-Custom-Header-2" ]`}
          aria-describedby="failoverRequestHeadersFiltersHelp"
        />
        <Form.Control.Feedback type="invalid">
          Must be valid JSON object with string values.
        </Form.Control.Feedback>
        <Form.Text id="failoverRequestHeadersFiltersHelp" muted>
          Headers we remove from the failover request we send to the customer.
          Useful if the customer's CDN does not accept to see its headers back
          to its CDN after the failover process.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="failoverResponseHeaders">
        <Form.Label>Failover Response Headers</Form.Label>
        <Form.Control
          as="textarea"
          {...register("failoverResponseHeaders", {
            validate: isValidJSONHeaders,
          })}
          placeholder={`e.g. { "X-Custom-Header-1": "my header value", "X-Custom-Header-2": "my other header value" }`}
          aria-describedby="failoverResponseHeadersHelp"
        />
        <Form.Control.Feedback type="invalid">
          Must be valid JSON object with string values.
        </Form.Control.Feedback>
        <Form.Text id="failoverResponseHeadersHelp" muted>
          Extra headers we add to the failover response we get from the customer
          and which will be sent to bots.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="customerOriginUrl">
        <Form.Label>Customer's Origin Url</Form.Label>
        <Form.Control
          type="url"
          {...register("customerOriginUrl", { validate: isValidURL })}
          placeholder="e.g. https://origin.example.com"
          aria-describedby="customerOriginUrlHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is limited to 255 characters and must be a valid url.
        </Form.Control.Feedback>
        <Form.Text id="customerOriginUrlHelp" muted>
          Customer's internal URL we should request when failing over. It is the
          private URL that the customer's own CDN is configured to request.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="customerOriginPort">
        <Form.Label>Customer's Origin Port</Form.Label>
        <Form.Control
          type="number"
          step={1}
          {...register("customerOriginPort", {
            required: false,
            min: 1,
            max: 65535,
            valueAsNumber: true,
          })}
          placeholder="e.g. 8080"
          aria-describedby="customerOriginPortHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field must be a positive integer between 1 and 65535.
        </Form.Control.Feedback>
        <Form.Text id="customerOriginPortHelp" muted>
          Customer's internal port we should request when failing over. It is
          the private port that the customer's own CDN is configured to request.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="customerOriginTimeout">
        <Form.Label>Customer's Origin Timeout*</Form.Label>
        <Form.Control
          type="number"
          {...register("customerOriginTimeout", {
            required: true,
            min: 0,
            valueAsNumber: true,
            validate: Number.isInteger,
          })}
          step={1}
          placeholder="15000"
          aria-describedby="customerOriginTimeoutHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is required and must be a positive integer.
        </Form.Control.Feedback>
        <Form.Text id="customerOriginTimeoutHelp" muted>
          Defaults to 15000ms. Time we wait before considering the failover
          request to the customer's origin failed. It should be increased for
          slow customers and lowered for fast customers. Once this timeout is
          reached we respond with an error.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="swOriginTimeout">
        <Form.Label>SpeedWorker's Origin Timeout*</Form.Label>
        <Form.Control
          type="number"
          step={1}
          {...register("swOriginTimeout", {
            required: true,
            min: 0,
            valueAsNumber: true,
            validate: Number.isInteger,
          })}
          placeholder="2000"
          aria-describedby="swOriginTimeoutHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is required and must be a positive integer.
        </Form.Control.Feedback>
        <Form.Text id="swOriginTimeoutHelp" muted>
          Defaults to 2000ms, or if the "live rendering" option is active, to
          the highest timeout of the JS configs plus 2000ms. Time we wait before
          considering the request from ADN to SpeedWorkers failed. Once this
          timeout is reached we fail over.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="swOriginRewrite">
        <Form.Label>SpeedWorker's Origin Rewrite</Form.Label>
        <Form.Control
          name="swOriginRewrite"
          as="textarea"
          readOnly
          placeholder="Not yet supported by the worker"
        />
      </Form.Group>

      <Form.Group controlId="swRealOrigin">
        <Form.Label>SpeedWorker's Real Origin</Form.Label>
        <Form.Control
          type="url"
          {...register("swRealOrigin", {
            maxLength: 255,
            validate: isValidURL,
          })}
          placeholder="e.g. https://www.example.com"
          aria-describedby="swRealOriginHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is limited to 255 characters and must be a valid url.
        </Form.Control.Feedback>
        <Form.Text id="swRealOriginHelp" muted>
          Changes how the customer needs to configure their integration. When
          this is set to https://www.example.com, instead of requesting
          adn.cloud?url=https://www.example.com/products the customer must
          request adn.cloud/products. To be used only to support some specific
          customer CDN integrations which do not support passing the request URL
          as a header or a search param.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="ignoredPathsRegex">
        <Form.Label>Ignored Paths Regexes*</Form.Label>
        <Form.Control
          {...register("ignoredPathsRegex", {
            required: true,
          })}
          placeholder={`e.g. ["${DEFAULT_ASSETS_REGEX}"]`}
          aria-describedby="ignoredPathsRegexHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is required and must be a valid regex.
        </Form.Control.Feedback>
        <Form.Text id="ignoredPathsRegexHelp" muted>
          JSON array of JavaScript regexes to determine which requests should be
          sent to the customer origin directly even if it is requested by a bot.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="workerVersion">
        <Form.Label>Worker Version*</Form.Label>
        <Form.Control
          as="select"
          {...register("workerVersion", {
            required: true,
            maxLength: 255,
            validate: (value) => {
              return isValidWorkerVersion(versionsList, value);
            },
          })}
          aria-describedby="workerVersionHelp"
        >
          <option value="">Please select a version</option>
          {data.adnVersions.map((adnVersion) => (
            <option key={adnVersion.version} value={adnVersion.version}>
              {adnVersion.version}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          This field is required and must be a valid worker version.
        </Form.Control.Feedback>
        <Form.Text id="workerVersionHelp" muted>
          Version of the worker code to execute. Unless specific need, always
          use the latest available version.
        </Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Check
          type="switch"
          id="gdprModeEnabled"
          name="gdprModeEnabled"
          label="GDPR Mode"
          disabled
          checked={false}
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          type="switch"
          id="verifiedBotsEnabled"
          name="verifiedBotsEnabled"
          label="Verify Bots"
          disabled
          checked={false}
        />
      </Form.Group>

      <Form.Group controlId="interceptedBotsRegex">
        <Form.Label>Intercepted Bots Regex*</Form.Label>
        <InputLockable
          {...register("interceptedBotsRegex", {
            required: true,
            maxLength: 1023,
          })}
          placeholder="e.g. googlebot/|googlebot-image/|google-inspectiontool/|googleother|bingbot/|yandexbot/|yandexmobilebot/|baiduspider/|baiduspider+|applebot/|yeti/|botify-bot-sw-|gptbot/|chatgpt-user/|oai-searchbot/|amazonbot/|anthropic-ai|claudebot/|claude-web|bytespider|ccbot/|facebookbot/|facebookexternalhit/|facebookcatalog/|perplexitybot/|youbot/|prerender"
          aria-describedby="interceptedBotsRegexHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is required, limited to 255 chars and must be a valid
          regex.
        </Form.Control.Feedback>
        <Form.Text id="interceptedBotsRegexHelp" muted>
          JavaScript Regex to determine which bot requests we send to
          SpeedWorkers. Other requests will fail over to the customer's origin.
        </Form.Text>
      </Form.Group>

      <p style={{ marginBottom: ".5em" }}>Build the intercepted bots regex</p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginBottom: "1em",
        }}
      >
        {botsRegex.allShards.map((bot) => (
          <Form.Check
            key={bot}
            id={bot}
            type="checkbox"
            label={bot}
            value={bot}
            checked={bot in interceptedBotsMap}
            onChange={onInterceptedBotsBuilderChange}
          />
        ))}
      </div>

      <Form.Group controlId="urlCacheHit">
        <Form.Label>Testing URL</Form.Label>
        <Form.Control
          type="url"
          {...register("urlCacheHit", {
            maxLength: 250,
            validate: isValidURL,
          })}
          placeholder="e.g. https://www.example.com/products"
          aria-describedby="urlCacheHitHelp"
        />
        <Form.Control.Feedback type="invalid">
          This field is limited to 255 chars and must be a valid url.
        </Form.Control.Feedback>
        <Form.Text id="urlCacheHitHelp" muted>
          URL against which the configuration will be tested upon deployment.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="deployTests">
        <Form.Label>Deploy tests</Form.Label>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            marginBottom: "1em",
          }}
        >
          {DEPLOY_TESTS.map(([value, label], idx) => (
            <Form.Check
              type="checkbox"
              onChange={updateDeployTests}
              label={label}
              key={value}
              value={value}
              checked={currentDeployTests?.includes(value)}
              name="deployTests"
            />
          ))}
        </div>
      </Form.Group>

      {websiteId ? (
        <input
          type="hidden"
          {...originalRegister("website", {
            required: true,
          })}
        />
      ) : null}

      {adnConfig?.id ? (
        <input
          type="hidden"
          {...originalRegister("id", {
            required: true,
          })}
        />
      ) : null}

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button variant="primary" type="submit" disabled={submitting}>
          {submitText}
          {submitting ? "…" : ""}
        </Button>
      </div>
    </Form>
  );
}
