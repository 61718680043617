import { useState } from "react";
import {
  previewIngestion,
  type PreviewIngestionResult,
} from "../../../ftlwasm/liveupdate.wasm";
import { ConfigTesterForm, type IConfigTesterForm } from "./ConfigTesterForm";
import { ConfigTesterResult } from "./ConfigTesterResult";

export const ConfigTester = () => {
  const [result, setResult] = useState<PreviewIngestionResult | null>(null);

  const runIngestionPreview = ({
    source,
    mapping,
    separator,
    lazyQuotes,
  }: IConfigTesterForm) => {
    const result = previewIngestion({
      source,
      mapping: JSON.parse(mapping),
      separator,
      lazyQuotes,
    });
    setResult(result);
  };

  return (
    <div>
      <h2>Configuration</h2>
      <ConfigTesterForm onSubmit={runIngestionPreview} />

      <h2 className="mt-4">Ingestion result</h2>
      <ConfigTesterResult result={result} />
    </div>
  );
};
