import { ApolloError } from "@apollo/client/errors";
import { Mutation } from "@apollo/client/react/components";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "./constants";
import { LOGIN, LoginMutation, LoginMutationVariables } from "./gql/auth";

interface IForm {
  email: string;
  username: string;
  password: string;
}

export function Login() {
  const navigate = useNavigate();
  const [form, setForm] = useState<IForm>({
    email: "",
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const setState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const displayError = (error: ApolloError) => {
    let message = error.message;
    message = message.replace("GraphQL error: ", "");
    setErrorMessage(message);
  };

  const _confirm = (data: LoginMutation) => {
    const token = data?.tokenAuth?.token;
    if (!token) return;

    localStorage.setItem(AUTH_TOKEN, token);
    navigate("/");
  };

  return (
    <div className="login">
      <div className="sidenav">
        <div className="login-main-text">
          <h2>⚡FTL</h2>
          <p>Login or register from here to access.</p>
        </div>
      </div>
      <div className="main">
        <div className="col-md-6 col-sm-12">
          <div className="login-form">
            <div className="form-group">
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}

              <label>Username</label>

              <input
                value={form.username}
                name="username"
                className="form-control"
                onChange={(e) => setState(e)}
                type="text"
                placeholder="Your username"
              />
            </div>
            <div className="form-group">
              <label>Password</label>

              <input
                name="password"
                value={form.password}
                onChange={(e) => setState(e)}
                className="form-control"
                type="password"
                placeholder="Choose a safe password"
              />
            </div>

            <Mutation<LoginMutation, LoginMutationVariables>
              mutation={LOGIN}
              variables={form}
              onError={displayError}
              onCompleted={_confirm}
            >
              {(mutation) => (
                <button className="btn btn-primary" onClick={() => mutation()}>
                  Login
                </button>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Logout() {
  localStorage.removeItem(AUTH_TOKEN);
  return <div>You are now logged out. See you soon</div>;
}
