import { Route, Routes } from "react-router-dom";
import { Error404Page } from "../../components/Error404Page/Error404Page";
import { ConfigTester } from "./ConfigTester";

export const LiveUpdateRouting = () => (
  <Routes>
    <Route path="configTester" element={<ConfigTester />} />
    <Route path="*" element={<Error404Page />} />
  </Routes>
);
