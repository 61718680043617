import { HtmlUpdatesLogType } from "../gql/types.generated";

export const FIELDS = [
  { name: "url", label: "URL" },
  { name: "protocol", label: "Protocol" },
  { name: "host", label: "Host" },
  { name: "path", label: "Path" },
  { name: "query_string", label: "Query String" },
];

export const OPERATORS = [
  { name: "equals", label: "Equals" },
  { name: "!equals", label: "Not Equals" },
  { name: "startsWith", label: "Starts With" },
  { name: "endsWith", label: "Ends With" },
  { name: "contains", label: "Contains" },
  { name: "!startsWith", label: "Doesn't Starts With" },
  { name: "!endsWith", label: "Doesn't Ends With" },
  { name: "!contains", label: "Doesn't Contains" },
  { name: "regex", label: "Matches Regexp" },
  { name: "!regex", label: "Doesn't Matches Regexp" },
];

export const EMULATION_DEVICE_CHOICES = [
  ["DESKTOP", "Desktop"],
  ["LAPTOP", "Laptop"],
  ["IPHONE", "Iphone"],
  ["IPAD", "Ipad"],
  ["BOTIFY", "Botify"],
  ["BOTIFY_SMARTPHONE", "Botify Smartphone"],
  ["GOOGLE", "Google"],
  ["GOOGLE_SMARTPHONE", "Google Smartphone"],
];

export const SEARCH_ENGINES = ["Google", "Bing", "Yandex", "Baidu", "Unknown"];

export const UA_BY_DEVICE = [
  {
    name: "chrome",
    value:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.131 Safari/537.36",
  },
  {
    name: "iphone",
    value:
      "Mozilla/5.0 (iPhone; CPU iPhone OS 6_1_4 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10B350 Safari/8536.25",
  },
  {
    name: "botify",
    value: "Mozilla/5.0 (compatible; botify; http://botify.com)",
  },
  {
    name: "botify for smartphone",
    value: "Mozilla/5.0 Mobile (compatible; botify; http://botify.com)",
  },
];

export const FORBIDDEN_UA =
  /googlebot\/|googlebot-image\/|google-inspectiontool\/|googleother|bingbot\/|yandexbot\/|yandexmobilebot\/|baiduspider\/|baiduspider\+|applebot\/|yeti\/|botify-bot-sw-|gptbot\/|chatgpt-user\/|oai-searchbot\/|amazonbot\/|anthropic-ai|claudebot\/|claude-web|bytespider|ccbot\/|facebookbot\/|facebookexternalhit\/|facebookcatalog\/|perplexitybot\/|youbot\/|prerender/gim;

export const ACTIONS_TEMPLATES: Record<
  "added" | "changed" | "removed",
  Record<string, (args: HtmlUpdatesLogType & { type?: string }) => JSX.Element>
> = {
  added: {
    title: (args) => (
      <span>
        <b>Title</b> added with value{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    canonical: (args) => (
      <span>
        <b>Canonical</b> added to <span className="value">{args.value}</span>
      </span>
    ),
    hreflang: (args) => (
      <span>
        <b>Hreflang</b> added for lang {args.lang} to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    permanentRedirect: (args) => (
      <span>
        <b>Permanent Redirect</b> added to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    temporaryRedirect: (args) => (
      <span>
        <b>Temporary Redirect</b> added to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    htmlBlock: (args) => (
      <span>
        <b>Html Block</b> added to {args.selector} with {args.strategy} strategy
        and value <span className="value">{args.value}</span>
      </span>
    ),
  },
  changed: {
    element: (args) => (
      <span>
        <b>Element</b> `{args.selector}` changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    title: (args) => (
      <span>
        <b>Title</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    link: (args) => (
      <span>
        <b>Link</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    canonical: (args) => (
      <span>
        <b>Canonical</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    prev: (args) => (
      <span>
        <b>Rel=prev</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    next: (args) => (
      <span>
        <b>Rel=next</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    h1: (args) => (
      <span>
        <b>H1</b> changed from <span className="oldValue">{args.oldValue}</span>{" "}
        to <span className="value">{args.value}</span>
      </span>
    ),
    description: (args) => (
      <span>
        <b>Description</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    ogDescription: (args) => (
      <span>
        <b>og:description</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    hreflang: (args) => (
      <span>
        <b>Hreflang</b> for lang {args.lang} changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    selector: (args) => (
      <span>
        <b>
          Selector {args.selector}:{args.type}
        </b>{" "}
        changed from <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    permanentRedirect: (args) => (
      <span>
        <b>Permanent Redirect</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
    temporaryRedirect: (args) => (
      <span>
        <b>Temporary Redirect</b> changed from{" "}
        <span className="oldValue">{args.oldValue}</span> to{" "}
        <span className="value">{args.value}</span>
      </span>
    ),
  },
  removed: {
    canonical: (args) => (
      <span>
        <b>Canonical</b> to &nbsp;
        <span className="oldValue">{args.value}</span> removed
      </span>
    ),
  },
};

export const TEST_STATUSES = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  FAIL: "FAIL",
} as const;

export const STATUSES_ICONS = {
  SUCCESS: "✅",
  WARNING: "⚠️",
  FAIL: "❌",
} as const;
