import { useState } from "react";
import { Accordion, Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "../../../../common/hooks/useForm";
import { useSet } from "../../../../common/hooks/useSet";
import { SectionMode } from "../../../../gql/types.generated";
import { getStepLabel, VisibleStep } from "../../core/steps";
import { CodeValidation, ScriptEditor } from "../ScriptEditor";
import { JsPreviewForm } from "./JsPreviewForm";
import { JsPreviewResults } from "./JsPreviewResults";
import { codeByStepToFields, getInitialCodeByStep } from "./mappers";
import { IPreviewState } from "./preview";
import { ScriptSubmitFormProps, ScriptSubmitFormValues } from "./typing";

export const ScriptSubmitForm = ({
  base,
  submitting,
  onSubmit,
  sections,
  luConfigs = [],
}: ScriptSubmitFormProps) => {
  // Form handling
  const {
    register,
    formState: { isValid },
    getValues,
  } = useForm<ScriptSubmitFormValues>({
    mode: "onTouched",
    defaultValues: base,
  });
  const currentScript = getValues();

  // Toggles (excluded from react-hook-forms due to annoying validation
  // that turns the fields green)
  const [applyOnWebsite, setApplyOnWebsite] = useState(!!base.applyOnWebsite);
  const [active, setActive] = useState(!!base.active);
  const canSelectBehaviors = !applyOnWebsite;

  // Sections
  const selectedSections = useSet(base.sections);

  // JS Code
  const [codeByStep, setCodeByStep] = useState(getInitialCodeByStep(base));
  const [codeValidationByStep, setCodeValidationByStep] = useState<
    Partial<Record<VisibleStep, CodeValidation[]>>
  >({});
  const codeErrors = Object.values(codeValidationByStep).flat();

  const updateCodeValidationForStep = (
    step: VisibleStep,
    validations: CodeValidation[]
  ) => setCodeValidationByStep((prev) => ({ ...prev, [step]: validations }));

  // Preview
  const [preview, setPreview] = useState<IPreviewState | null>(null);

  // Form

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      ...getValues(), // fields handled by useForm (text inputs)
      ...codeByStepToFields(codeByStep), // onXxxCode fields
      sections: [...selectedSections.values()], // sections: checkboxes -> array
      active, // excluded from react-hook-form
      applyOnWebsite, // excluded from react-hook-form
    });
  };

  return (
    <Form
      onSubmit={submitForm}
      className="d-flex flex-column w-75 pb-4"
      style={{ gap: "16px" }}
    >
      <style>{`.form-label { font-weight: bold; }`}</style>
      {/* Name */}
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          {...register("name", {
            required: true,
            maxLength: 255,
          })}
          placeholder="Script name (required)"
        />
        <Form.Control.Feedback type="invalid">
          This field is required and limited to 255 characters.
        </Form.Control.Feedback>
      </Form.Group>

      {/* Description */}
      <Form.Group controlId="description">
        <Form.Label>Description (optional)</Form.Label>
        <Form.Control
          {...register("description", {
            maxLength: 255,
          })}
          placeholder="Short description"
        />
        <Form.Control.Feedback type="invalid">
          This field is limited to 255 characters.
        </Form.Control.Feedback>
      </Form.Group>

      {/* Active / paused */}
      <Form.Group controlId="active">
        <Form.Check
          type="switch"
          label="Active"
          checked={active}
          onChange={() => setActive((prev) => !prev)}
        />
      </Form.Group>

      {/* Sections */}
      <div>
        <Form.Label>Apply on behaviors</Form.Label>

        {/* Apply on website */}
        <Form.Group controlId="applyOnWebsite" className="mt-2 mb-3">
          <Form.Check
            type="switch"
            label="Apply on whole website"
            checked={applyOnWebsite}
            onChange={() => setApplyOnWebsite((prev) => !prev)}
          />
        </Form.Group>

        <Accordion activeKey={applyOnWebsite ? "" : "sections"}>
          <Accordion.Collapse eventKey="sections">
            <>
              {sections
                .filter(({ mode }) => mode === SectionMode.Allow)
                .map(({ id, name }) => (
                  <Form.Check
                    key={id}
                    id={id}
                    label={name}
                    checked={selectedSections.has(id)}
                    onChange={() => selectedSections.toggle(id)}
                    disabled={!canSelectBehaviors}
                    className="my-1"
                  />
                ))}
            </>
          </Accordion.Collapse>
        </Accordion>
      </div>

      {/* Code editor */}
      <Form.Group controlId="jsCode">
        <Form.Label>Edit JS code</Form.Label>
        <ScriptEditor
          luConfigs={luConfigs}
          codeByStep={codeByStep}
          onChange={setCodeByStep}
          onValidate={updateCodeValidationForStep}
        />
        {codeErrors && (
          <Form.Text>
            <ul>
              {codeErrors.map(({ step, line, text }, i) => (
                <li key={i}>
                  <code>{getStepLabel(step)}</code>
                  {line ? ` L${line}` : ""}: {text}
                </li>
              ))}
            </ul>
          </Form.Text>
        )}
      </Form.Group>

      <div style={{ display: "flex", gap: 8 }}>
        {/* JS Preview inputs */}
        <JsPreviewForm
          script={{
            codeByStep,
            id: currentScript.id,
            name: currentScript.name,
          }}
          setPreview={setPreview}
          disabled={submitting}
        />

        {/* Submit button */}
        <Button
          variant="primary"
          type="submit"
          disabled={!isValid || submitting}
        >
          {submitting ? <Spinner animation="border" size="sm" /> : "Save"}
        </Button>
      </div>

      {/* JS Preview results */}
      <JsPreviewResults preview={preview} />
    </Form>
  );
};
