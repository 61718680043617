import MonacoEditor, { useMonaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { ComponentProps, useEffect } from "react";
import { Context, getContextLibs } from "./context";

type Monaco = typeof monaco;

type MonacoEditorProps = ComponentProps<typeof MonacoEditor>;

export type Language = "json" | "javascript" | "html" | "plaintext";

export type JsonSchema = NonNullable<
  monaco.languages.json.DiagnosticsOptions["schemas"]
>[number];

export type OnValidate = MonacoEditorProps["onValidate"];

export type EditorProps = {
  /**
   * If you use a single Editor instance for several code values
   * (e.g. using tabs), use an unique path for each to ensure
   * the isolation of their cancelation context (ctrl + z).
   * Otherwise, cancelation after changing tab would set the current
   * code to the previous version of the previous tab's code.
   */
  path?: string;
  language: Language;
  value: string;
  onChange?: (value: string) => void;
  onValidate?: OnValidate;
  context?: "delivery" | "rendering";
  actions?: monaco.editor.IActionDescriptor[];
  jsonSchemas?: JsonSchema[];
  width?: string | number;
  height?: string | number;
  readonly?: boolean;
  wordWrap?: boolean;
};

export const Editor = ({
  path,
  language,
  value,
  onChange,
  onValidate,
  context,
  actions = [],
  jsonSchemas,
  width = "100%",
  height = "100%",
  readonly = false,
  wordWrap = false,
}: EditorProps) => {
  const monaco = useMonaco();

  useEffect(() => {
    if (monaco && language === "javascript") {
      setupMonacoForJavascript(monaco, context);
    }
    if (monaco && language === "json") {
      setupMonacoForJson(monaco, jsonSchemas);
    }
  }, [monaco, language, context, jsonSchemas]);

  const registerActions = (editor: monaco.editor.IStandaloneCodeEditor) =>
    actions.forEach((action) => editor.addAction(action));

  return (
    <MonacoEditor
      path={path}
      language={language}
      width={width}
      height={height}
      theme="vs-dark"
      value={value}
      onMount={registerActions}
      onChange={(value) => onChange?.(value ?? "")}
      onValidate={(markers) => onValidate?.(markers)}
      options={{
        wordWrap: wordWrap ? "on" : "off",
        minimap: { enabled: false },
        readOnly: readonly,
      }}
    />
  );
};

const setupMonacoForJson = (monaco: Monaco, schemas: JsonSchema[] = []) => {
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemaValidation: "error",
    schemas: schemas,
  });
};

const setupMonacoForJavascript = (
  monaco: Monaco,
  context: Context | undefined
) => {
  const javascriptDefaults = monaco?.languages.typescript.javascriptDefaults;
  if (!javascriptDefaults) return;

  const { lib, extraLibs } = getContextLibs(context);

  // Enable validation for javascript files
  javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSuggestionDiagnostics: false,
    noSyntaxValidation: false,
  });

  // Set compiler options for javascript files
  javascriptDefaults.setCompilerOptions({
    lib,
    checkJs: true,
    noImplicitAny: false,
    allowNonTsExtensions: true,
  });

  // Set extra libs for javascript files
  javascriptDefaults.setExtraLibs(extraLibs);
};
