export type RawFeedEntry = Record<string, string>;

export type KVStoreEntry = {
  key: string;
  price: number;
  sale_price: number;
  currency: string;
  availability: string;
};

export type PreviewIngestionResult = {
  readCount: number;
  readSample: (RawFeedEntry | null)[];
  ignoredCount: number;
  ignoredSample: { reason: string; value: RawFeedEntry | null }[];
  writtenCount: number;
  writtenSample: KVStoreEntry[];
  writtenBytes: number;
  sourceHash: string;
  ingestionDurationMs: number;
  error: string;
};

export type FeedColumn =
  | "key"
  | "base_price"
  | "sale_price"
  | "currency"
  | "availability";

export type MappingOptions = { column: string; defaultValue?: string } & (
  | { split: { separator: string; position: number } }
  | { mapping: Record<string, string> }
  | { regex: string }
  | {}
);

export type PreviewIngestionMapping = Partial<
  Record<FeedColumn, MappingOptions>
>;

export type PreviewIngestionParams = {
  source: string;
  mapping: PreviewIngestionMapping;
  separator: string;
  lazyQuotes: boolean;
};

export const previewIngestion = ({
  source,
  mapping,
  separator,
  lazyQuotes,
}: PreviewIngestionParams): PreviewIngestionResult => {
  if (!window.LiveUpdatePreviewIngestion) {
    throw new Error("LiveUpdatePreviewIngestion is not defined");
  }

  const result = window.LiveUpdatePreviewIngestion(
    source,
    JSON.stringify(mapping),
    separator,
    lazyQuotes
  );
  return JSON.parse(result);
};
