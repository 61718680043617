import { ReactNode } from "react";
import { Table } from "react-bootstrap";
import { type PreviewIngestionResult } from "../../../ftlwasm/liveupdate.wasm";

type ConfigTesterResultProps = {
  result: PreviewIngestionResult | null;
};

export const ConfigTesterResult = ({ result }: ConfigTesterResultProps) => {
  if (!result) {
    return <div>Run a preview to test the feed configuration</div>;
  }

  if (result.error) {
    return (
      <ResultSection title="Error">
        <pre>{result.error}</pre>
      </ResultSection>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Stats */}
      <ResultSection title="Stats">
        <StatsTable result={result} />
      </ResultSection>

      {/* Read sample */}
      <ResultSection
        title="Read entries sample"
        description="Parsed entries before transformation"
      >
        <SampleTable sample={result.readSample} />
      </ResultSection>

      {/* Ignored sample */}
      <ResultSection
        title="Ignored entries sample"
        description="Ignored entries either before transformation (parsing error) or after (missing fields)"
      >
        <SampleTable
          sample={result.ignoredSample.map(({ reason, value }) => ({
            // add ignored reason column with leading space to sort it first
            " ignored reason": reason,
            ...(value ?? {}),
          }))}
        />
      </ResultSection>

      {/* Ingested sample */}
      <ResultSection
        title="Ingested entries sample"
        description="Valid entries after successful transformation"
      >
        <SampleTable sample={result.writtenSample} />
      </ResultSection>
    </div>
  );
};

const ResultSection = ({
  children,
  title,
  description,
}: React.PropsWithChildren<{ title: string; description?: string }>) => (
  <section>
    <h3>{title}</h3>
    {description && (
      <p style={{ fontSize: 14, color: "#666" }}>{description}</p>
    )}
    <div className="mt-3">{children}</div>
  </section>
);

const StatsTable = ({ result }: { result: PreviewIngestionResult }) => (
  <Table className="w-auto" bordered>
    <tbody>
      <tr>
        <td className="bold">Ingested entries</td>
        <td>
          <Ratio value={result.writtenCount} total={result.readCount} />
        </td>
      </tr>
      <tr>
        <td>Ignored entries</td>
        <td>
          <Ratio value={result.ignoredCount} total={result.readCount} />
        </td>
      </tr>
      <tr>
        <td>Ingestion duration</td>
        <td>
          {result.ingestionDurationMs < 1 ? "< 1" : result.ingestionDurationMs}{" "}
          ms
        </td>
      </tr>
    </tbody>
  </Table>
);

const Ratio = ({ value, total }: { value: number; total: number }) => (
  <span>
    {value} / {total} ({((value / total) * 100).toFixed(0)}%)
  </span>
);

type Sample = (Record<string, ReactNode> | null)[];

type SampleTableProps = { sample: Sample };

const SampleTable = ({ sample }: SampleTableProps) => {
  const [columns, rows] = formatSampleTable(sample);
  return (
    <Table className="w-auto" striped>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {row.map((cell, i) => (
              <td key={i}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const formatSampleTable = (
  sample: Sample
): [columns: string[], rows: ReactNode[][]] => {
  const columns = Array.from(
    new Set(
      sample.reduce<string[]>(
        (columns, entry) => [...columns, ...Object.keys(entry ?? {})],
        []
      )
    )
  ).sort();

  const rows = sample.map((entry) => {
    const safeEntry = entry ?? {};
    return columns.map((column) =>
      safeEntry[column] !== undefined ? safeEntry[column] : ""
    );
  });

  return [columns, rows];
};
