import { useMutation, useQuery } from "@apollo/client";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import QueryBuilder, { RuleGroupType } from "react-querybuilder";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomRefreshRulesComponent } from "../../common/customRules";
import { DurationShortcuts } from "../../common/durationShortcuts";
import { FormControlElement } from "../../common/typing/form";
import { getNonNullList } from "../../common/typings";
import { DataHandler } from "../../components/DataHandler";
import {
  ErrorType,
  SectionIgnoreParametersCascading,
  SectionIgnoreParametersStrategy,
  SectionJsRedirectStatusCode,
  SectionLiveMode,
  SectionMode,
  SectionModeNotFoundStatusCode,
  SectionModeRedirectStatusCode,
  SectionModeRedirectStrategy,
  SectionType,
} from "../../gql/types.generated";
import { CREATE_SECTION, UPDATE_SECTION } from "../../gql/websites/section";
import {
  CreateSectionMutation,
  CreateSectionMutationVariables,
} from "../../gql/websites/section/createSection.gql.generated";
import {
  UpdateSectionMutation,
  UpdateSectionMutationVariables,
} from "../../gql/websites/section/updateSection.gql.generated";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../gql/websites/website";
import { ErrorRegExAlert } from "../components/feedback/ErrorRegExAlert";
import { FIELDS, OPERATORS } from "../constants";
import { validateRegExpInRules } from "../helpers";
import { filterScriptsOnSection } from "../scripts/core/scripts";
import { useScriptsQuery } from "../scripts/hooks/useScriptsQuery";
import { SectionIdParams, WebsiteIdParams } from "../types/routeParams";
import { UrlTester } from "../urlTester/urlTester";
import { BehaviorScripts } from "./components/BehaviorScripts";
import { RedirectCustomJsTester } from "./components/RedirectCustomJsTester";
import { SectionField } from "./components/SectionField";
import { getFinalValue, getInitialFormValues, IForm } from "./form";
import {
  getIgnoreParametersStrategyLabel,
  getLiveModeLabel,
  LiveScope,
  sortByRefreshPriority,
} from "./sections.core";

type SectionMutation = CreateSectionMutation | UpdateSectionMutation;

type SectionMutationVariables =
  | CreateSectionMutationVariables
  | UpdateSectionMutationVariables;

export function WebsiteSection() {
  const { websiteId, sectionId: sectionStableId } = useParams<
    WebsiteIdParams & SectionIdParams
  >() as WebsiteIdParams & SectionIdParams;
  const navigate = useNavigate();

  const {
    loading: loadingWebsite,
    error,
    data,
  } = useQuery<WebsiteQuery, WebsiteQueryVariables>(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  const { scripts, loading: loadingScripts } = useScriptsQuery({
    onError: (error) =>
      toast(`Failed to fetch behavior scripts: ${error.message}`, {
        type: "error",
      }),
  });

  const sectionScripts = filterScriptsOnSection(scripts, { sectionStableId });

  const website = data?.website;
  const draftVersion = website?.draftVersion;
  const formMode = sectionStableId ? "update" : "create";
  const fallbackSection = (draftVersion?.sections.find(
    (s) => s.fallbackSection
  ) ?? null) as SectionType | null;
  const section = (draftVersion?.sections?.find(
    (s) => s.stableId === sectionStableId
  ) ?? null) as SectionType | null;
  const isFallbackSection = !!section?.fallbackSection;

  const [form, setValues] = useState<IForm>(getInitialFormValues(section));

  useEffect(() => {
    // Form state may have been generated when data was no ready
    // to ensure that form values are correct we update the state
    // if source data is changed.
    setValues(getInitialFormValues(section));
  }, [section]);

  const rules = form.rules ? JSON.parse(form.rules) : null;

  const [sectionError, setSectionError] = useState("");
  const [regExRuleErrors, setRegExRuleErrors] = useState({
    hasLongRegEx: false,
    hasInvalidRegEx: false,
  });

  const query = sectionStableId ? UPDATE_SECTION : CREATE_SECTION;
  const [validated, setValidated] = useState(false);

  const scrollToTop = () => window.scrollTo(0, 0);

  const redirectToHome = () => {
    navigate(
      {
        pathname: "/website/" + websiteId,
      },
      {
        state: {
          message: {
            value: form.name + " is saved",
            status: "success",
          },
        },
      }
    );
  };

  const formatErrorMessagesFromRequest = (errors: ErrorType[]) => {
    return (
      <div>
        Errors:
        <br />
        <ul>
          {errors.map((error) => (
            <li>
              {error.field}:{" "}
              <ul>
                {error.messages.map((message) => (
                  <li>
                    {message}
                    <br />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const getUpdatedSection = (
    data: Maybe<CreateSectionMutation | UpdateSectionMutation>
  ) =>
    (data as CreateSectionMutation)?.internalCreateSection ??
    (data as UpdateSectionMutation)?.internalUpdateSection;

  const [submitSection, { data: dataUpdate, error: errorUpdate }] = useMutation<
    SectionMutation,
    SectionMutationVariables
  >(query, {
    onCompleted: (data) => {
      scrollToTop();
      const hasError = getUpdatedSection(data)?.errors?.length;
      if (!hasError) {
        redirectToHome();
      }
    },
  });

  const mutatedSection = getUpdatedSection(dataUpdate);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const currentForm = event.currentTarget;
    event.preventDefault();
    if (currentForm.checkValidity() === false) {
      scrollToTop();
      setSectionError("Form is invalid");
      event.stopPropagation();
      return;
    }

    if (regExRuleErrors.hasLongRegEx || regExRuleErrors.hasInvalidRegEx) {
      event.stopPropagation();
      return;
    }

    await submitSection({
      variables: {
        input: {
          ...form,
          website: websiteId,
          websiteVersion: draftVersion?.id ?? "",
        },
      },
    });

    setValidated(true);
  }

  const updateField = (e: React.ChangeEvent<FormControlElement>) => {
    const field = e.target.name as keyof IForm;
    const value = e.target.value;
    const updatedValues: Partial<IForm> = { ...form, [field]: value };

    if (field === "mode" && value === SectionMode.Redirect) {
      if (!form.modeRedirectStatusCode) {
        updatedValues.modeRedirectStatusCode =
          SectionModeRedirectStatusCode.Code_301;
      }
      if (!form.modeRedirectStrategy) {
        updatedValues.modeRedirectStrategy =
          SectionModeRedirectStrategy.SingleUrl;
      }
    }

    setValues({
      ...form,
      ...updatedValues,
    });
  };

  const updateFieldNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...form,
      [e.target.name]: e.target.valueAsNumber,
    });
  };

  const updateEntry = <K extends keyof IForm>(name: K, value: IForm[K]) => {
    setValues({
      ...form,
      [name]: value,
    });
  };

  const updateBoolField = (e: React.ChangeEvent<FormControlElement>) => {
    setValues({
      ...form,
      [e.target.name]: e.target.value === "true",
    });
  };

  const updateLiveScope = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prevForm) => {
      let selectedValues = prevForm.liveScope ? [...prevForm.liveScope] : [];
      if (e.target.checked) {
        selectedValues.push(e.target.value);
      } else {
        selectedValues = selectedValues.filter(
          (item) => item !== e.target.value
        );
      }
      return { ...prevForm, liveScope: selectedValues };
    });
  };

  const updateOverrideRenderingConfig = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const override = e.target.checked && e.target.value === "true";
    const newFields: Partial<IForm> = {
      overrideRenderConfigs: override,
    };
    if (!override) {
      newFields.desktopConfig = null;
      newFields.mobileConfig = null;
    }
    setValues({
      ...form,
      ...newFields,
    });
  };

  const updateRules = (ruleGroup: RuleGroupType) => {
    setRegExRuleErrors(validateRegExpInRules(ruleGroup));

    setValues({
      ...form,
      rules: JSON.stringify(ruleGroup),
    });
  };

  const errorMessage = (() => {
    if (errorUpdate) {
      return errorUpdate.message;
    }
    if (mutatedSection?.errors) {
      return formatErrorMessagesFromRequest(
        getNonNullList(mutatedSection.errors)
      );
    }
    if (sectionError) {
      return sectionError;
    }
    return null;
  })();

  if (error || loadingWebsite || !draftVersion || !fallbackSection) {
    return (
      <DataHandler
        error={error}
        loading={loadingWebsite}
        data={draftVersion && fallbackSection}
        expectData
      />
    );
  }

  if (formMode === "update" && !section)
    return (
      <>
        Behavior not found: <code>{sectionStableId}</code>
      </>
    );

  document.title = `${website.name} - ${
    formMode === "update" ? section?.name : "Create new Behavior"
  } | Botify Activation`;

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <h1>
          <Link to={`/website/${website.id}`}>{website.name}</Link>
          {" >"}
          Behaviors {"> "}
          {formMode === "create" ? "Create Behavior" : section?.name}
        </h1>

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        <Card>
          <Card.Header>Main Informations</Card.Header>
          <Card.Body>
            <Form.Group>
              <Form.Label>Behavior Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                value={form.name}
                onChange={updateField}
                placeholder="Enter Name"
                disabled={formMode === "update" && isFallbackSection}
                required
              />
              <Form.Text className="text-muted">Name of the behavior</Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Mode</Form.Label>

              <Form.Control name="mode" as="select" onChange={updateField}>
                <option
                  value={SectionMode.Allow}
                  selected={form.mode === SectionMode.Allow}
                >
                  Allow
                </option>
                <option
                  value={SectionMode.AllowAsResource}
                  selected={form.mode === SectionMode.AllowAsResource}
                >
                  Allow as resource (contains only static assets like css, js,
                  images...). No HTML edit will be applied.
                </option>
                <option
                  value={SectionMode.Block}
                  selected={form.mode === SectionMode.Block}
                >
                  Block
                </option>
                <option
                  value={SectionMode.NotFound}
                  selected={form.mode === SectionMode.NotFound}
                >
                  Not Found (404 or 410)
                </option>
                <option
                  value={SectionMode.Redirect}
                  selected={form.mode === SectionMode.Redirect}
                >
                  Redirect (301 or 302)
                </option>
                <option
                  value={SectionMode.CustomHtml}
                  selected={form.mode === SectionMode.CustomHtml}
                >
                  Custom HTML and Status Code
                </option>
              </Form.Control>

              <Form.Text className="text-muted">
                Allow : Pages will be served / Block : Pages won't be served
              </Form.Text>
            </Form.Group>

            {form.mode === SectionMode.Allow && (
              <>
                <Form.Group>
                  <Form.Label>
                    Max Speed (On Rendering Farm) in Pages/sec
                  </Form.Label>

                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="maxSpeed"
                    fallbackValue={
                      fallbackSection.maxSpeed || draftVersion.maxSpeed
                    }
                    formElement={
                      <Form.Control
                        name="maxSpeed"
                        type="number"
                        value={form.maxSpeed ?? undefined}
                        onChange={updateField}
                        placeholder="Enter Max Speed"
                      />
                    }
                  />
                  <Form.Text className="text-muted">
                    Value set at website level is {draftVersion.maxSpeed}p/s
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Live-Rendering Max Speed (On Rendering Farm) in Pages/sec
                  </Form.Label>

                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="liveMaxSpeed"
                    fallbackValue={
                      fallbackSection.liveMaxSpeed || draftVersion.liveMaxSpeed
                    }
                    formElement={
                      <Form.Control
                        name="liveMaxSpeed"
                        type="number"
                        value={form.liveMaxSpeed ?? undefined}
                        onChange={updateField}
                        placeholder="Enter Live Max Speed"
                        isValid={
                          Number.parseFloat(form.liveMaxSpeed ?? "0") <=
                          Number.parseFloat(form.maxSpeed ?? "0")
                        }
                      />
                    }
                  />
                  <Form.Text className="text-muted">
                    Value set at website level is {draftVersion.liveMaxSpeed}p/s
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Live Max-Speed has to be {"<="} Max-Speed
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Refresh Frequency (in minutes)</Form.Label>

                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="refreshFrequency"
                    formElement={
                      <Form.Control
                        name="refreshFrequency"
                        type="number"
                        value={form.refreshFrequency ?? undefined}
                        onChange={updateFieldNumber}
                        placeholder="Enter Refresh Frequency"
                      />
                    }
                  />

                  <Form.Text className="text-muted">
                    The desired number of minutes where the page should be
                    frozen
                  </Form.Text>
                  <Form.Text className="text-muted">
                    {form.refreshFrequency} minutes in days equal to{" "}
                    {+((form.refreshFrequency ?? 0) / 60 / 24).toFixed(1)} days
                  </Form.Text>
                  <DurationShortcuts
                    onClick={(durationInMinutes) =>
                      updateEntry("refreshFrequency", durationInMinutes)
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Refresh Queue Priority</Form.Label>

                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="refreshPriority"
                    mapping={{ 1: "High", 2: "Medium", 3: "Low" }}
                    formElement={
                      <Form.Control
                        name="refreshPriority"
                        as="select"
                        value={form.refreshPriority ?? undefined}
                        onChange={updateFieldNumber}
                      >
                        <option value={3}>Low</option>
                        <option value={2}>Medium</option>
                        <option value={1}>High</option>
                      </Form.Control>
                    }
                  />

                  <Form.Text className="text-muted">
                    Priority over all behaviors of your website. Values from 1
                    to 3 accepted.
                    <br />
                    The smallest value is the most prioritary.
                    <br />
                    {getNonNullList(draftVersion.sections)
                      .sort(sortByRefreshPriority)
                      .map((entry) => (
                        <div>
                          {entry.name} : {entry.refreshPriority}
                        </div>
                      ))}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Max Stale</Form.Label>
                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="maxStale"
                    formElement={
                      <Form.Control
                        name="maxStale"
                        type="number"
                        value={form.maxStale ?? undefined}
                        onChange={updateFieldNumber}
                        placeholder="Enter Max Stale"
                      />
                    }
                  />

                  <Form.Text className="text-muted">
                    In case the queue is overcrowded, Max time you accept to
                    return cached page after the "Refresh Priority" delta time.
                  </Form.Text>
                  <DurationShortcuts
                    onClick={(durationInMinutes) =>
                      updateEntry("maxStale", durationInMinutes)
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>JS Redirect Status Code</Form.Label>

                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="jsRedirectStatusCode"
                    formElement={
                      <Form.Control
                        name="jsRedirectStatusCode"
                        as="select"
                        onChange={updateField}
                      >
                        {[
                          {
                            value: SectionJsRedirectStatusCode.DoNothing,
                            label: "Do not redirect, sends CACHE MISS",
                          },
                          {
                            value: SectionJsRedirectStatusCode.Code_301,
                            label: "301",
                          },
                          {
                            value: SectionJsRedirectStatusCode.Code_302,
                            label: "302",
                          },
                        ].map(({ value, label }) => (
                          <option
                            value={value}
                            selected={form.jsRedirectStatusCode === value}
                          >
                            {label}
                          </option>
                        ))}
                      </Form.Control>
                    }
                  />

                  <Form.Text className="text-muted">
                    Set a specific redirects status code when the renderer
                    detects js redirections (pushstate, window.location...)
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>HTML Content Types</Form.Label>

                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="htmlContentTypes"
                    formElement={
                      <Form.Control
                        name="htmlContentTypes"
                        type="text"
                        value={form.htmlContentTypes ?? undefined}
                        onChange={updateField}
                        placeholder="Enter HTML Content Types"
                      />
                    }
                  />

                  <Form.Text className="text-muted">
                    Content Types should be splited by comma.
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Live Mode</Form.Label>

                  <SectionField
                    form={form}
                    setForm={setValues}
                    isFallbackSection={isFallbackSection}
                    fallbackSection={fallbackSection}
                    field="liveMode"
                    fallbackValue={SectionLiveMode.NoLive}
                    formElement={
                      <Form.Control
                        name="liveMode"
                        as="select"
                        onChange={updateField}
                      >
                        {Object.values(SectionLiveMode).map((value) => (
                          <option
                            value={value}
                            selected={form.liveMode === value}
                          >
                            {getLiveModeLabel(value)}
                          </option>
                        ))}
                      </Form.Control>
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Live Scope</Form.Label>

                  {LiveScope.map(([value, label], idx) => (
                    <Form.Check
                      type="checkbox"
                      onChange={updateLiveScope}
                      label={label}
                      value={value}
                      checked={form.liveScope?.includes(value)}
                      name="liveScope"
                    />
                  ))}
                </Form.Group>
              </>
            )}
          </Card.Body>
        </Card>

        {form.mode === SectionMode.Allow && (
          <Card>
            <Card.Header>Rendering Configs</Card.Header>
            <Card.Body>
              {(formMode === "create" || !section?.fallbackSection) && (
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    name="overrideRenderConfigs"
                    value="true"
                    label="Override Rendering Config from Main Behavior"
                    checked={form.overrideRenderConfigs}
                    onChange={updateOverrideRenderingConfig}
                  />

                  <Form.Text className="text-muted">
                    On Main Behavior : <br />
                    For Desktop :{" "}
                    {fallbackSection.desktopConfig?.id
                      ? fallbackSection.desktopConfig.name
                      : "Not Set"}
                    <br />
                    For Mobile :{" "}
                    {fallbackSection.mobileConfig?.id
                      ? fallbackSection.mobileConfig.name
                      : "Not Set"}
                  </Form.Text>
                </Form.Group>
              )}

              {(form.overrideRenderConfigs ||
                (formMode === "update" && section?.fallbackSection)) && (
                <>
                  <Form.Group>
                    <Form.Label>
                      Desktop : Processing Config To Apply
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      label="Do not process for desktop"
                      name="desktopConfig"
                      checked={form.desktopConfig === ""}
                      value=""
                      onChange={updateField}
                      required
                    />
                    {draftVersion.configs.map((config) => (
                      <Form.Check
                        type="radio"
                        label={config.name}
                        name="desktopConfig"
                        checked={form.desktopConfig === config.id}
                        value={config.id}
                        onChange={updateField}
                        required
                      />
                    ))}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Mobile : Processing Config To Apply</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Do not process for mobile"
                      name="mobileConfig"
                      checked={form.mobileConfig === ""}
                      value=""
                      onChange={updateField}
                      required
                    />
                    {draftVersion.configs.map((config) => (
                      <Form.Check
                        type="radio"
                        label={config.name}
                        name="mobileConfig"
                        checked={form.mobileConfig === config.id}
                        value={config.id}
                        onChange={updateField}
                        required
                      />
                    ))}
                  </Form.Group>
                </>
              )}
            </Card.Body>
          </Card>
        )}

        {form.mode === SectionMode.NotFound && (
          <Card>
            <Card.Header>Not Found Configuration</Card.Header>

            <Card.Body>
              <Form.Group>
                <Form.Label>Status Code</Form.Label>
                <Form.Control
                  name="modeNotFoundStatusCode"
                  as="select"
                  onChange={updateField}
                >
                  {[
                    {
                      value: SectionModeNotFoundStatusCode.Code_404,
                      label: "404",
                    },
                    {
                      value: SectionModeNotFoundStatusCode.Code_410,
                      label: "410",
                    },
                  ].map(({ value, label }) => (
                    <option
                      value={value}
                      selected={form.modeNotFoundStatusCode === value}
                    >
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>HTML content</Form.Label>
                <Form.Control
                  name="modeNotFoundHtml"
                  as="textarea"
                  value={form.modeNotFoundHtml ?? undefined}
                  onChange={updateField}
                  rows={10}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        )}

        {form.mode === SectionMode.Redirect && (
          <Card>
            <Card.Header>Redirect Configuration</Card.Header>

            <Card.Body>
              <Form.Group>
                <Form.Label>Status Code</Form.Label>
                <Form.Control
                  name="modeRedirectStatusCode"
                  as="select"
                  value={form.modeRedirectStatusCode ?? undefined}
                  onChange={updateField}
                >
                  {[
                    {
                      value: SectionJsRedirectStatusCode.Code_301,
                      label: "301",
                    },
                    {
                      value: SectionJsRedirectStatusCode.Code_302,
                      label: "302",
                    },
                  ].map(({ value, label }) => (
                    <option value={value}>{label}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Strategy</Form.Label>
                <Form.Control
                  name="modeRedirectStrategy"
                  as="select"
                  onChange={updateField}
                >
                  {[
                    {
                      value: SectionModeRedirectStrategy.SingleUrl,
                      label: "Single URL",
                    },
                    {
                      value: SectionModeRedirectStrategy.CustomJs,
                      label: "Custom JS",
                    },
                  ].map(({ value, label }) => (
                    <option
                      value={value}
                      selected={form.modeRedirectStrategy === value}
                    >
                      {label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {form.modeRedirectStrategy ===
                SectionModeRedirectStrategy.SingleUrl && (
                <Form.Group>
                  <Form.Label>URL Location</Form.Label>
                  <Form.Control
                    name="modeRedirectLocation"
                    type="text"
                    value={form.modeRedirectLocation ?? undefined}
                    onChange={updateField}
                  />
                </Form.Group>
              )}

              {form.modeRedirectStrategy ===
                SectionModeRedirectStrategy.CustomJs && (
                <>
                  {" "}
                  <Form.Group>
                    <Form.Label>Custom JS</Form.Label>
                    <Form.Control
                      name="modeRedirectJs"
                      as="textarea"
                      value={form.modeRedirectJs ?? undefined}
                      onChange={updateField}
                      style={{ height: "100px" }}
                    />
                    <Form.Text>
                      You have access to `url` argument. JS block should return
                      the new URL. Ex :{" "}
                      <pre>return url.substring(0, url.length -1);</pre>
                    </Form.Text>
                  </Form.Group>
                  <RedirectCustomJsTester
                    customJs={form.modeRedirectJs ?? ""}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        )}

        {form.mode === SectionMode.CustomHtml && (
          <Card>
            <Card.Header>Custom HTML Configuration</Card.Header>

            <Card.Body>
              <Form.Group>
                <Form.Label>Status Code</Form.Label>
                <Form.Control
                  name="modeCustomHtmlStatusCode"
                  type="number"
                  value={form.modeCustomHtmlStatusCode ?? undefined}
                  onChange={updateFieldNumber}
                  placeholder="Enter Status Code"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>HTML content</Form.Label>
                <Form.Control
                  name="modeCustomHtmlHtml"
                  as="textarea"
                  value={form.modeCustomHtmlHtml ?? undefined}
                  onChange={updateField}
                  rows={10}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        )}

        {form.mode === SectionMode.Allow && (
          <Card>
            <Card.Header>Ignore Parameters</Card.Header>

            <Card.Body>
              <Form.Group>
                <Form.Label>Parameters to ignore</Form.Label>
                <Form.Control
                  name="ignoreParameters"
                  type="text"
                  value={form.ignoreParameters ?? undefined}
                  onChange={updateField}
                  placeholder="Enter parameters"
                />
                <Form.Text>
                  Split parameters by comma. Wildcards are allowed : ex utc* for
                  utm_parameters, utm_campaign...
                </Form.Text>
              </Form.Group>

              {!isFallbackSection && (
                <Form.Group>
                  <Form.Label>Cascade Parameters from Main Behavior</Form.Label>
                  <Form.Control
                    name="ignoreParametersCascading"
                    as="select"
                    onChange={updateField}
                  >
                    {[
                      {
                        value: SectionIgnoreParametersCascading.Append,
                        label:
                          "Merge ignored parameters from this behavior with main behaviors parameters",
                      },
                      {
                        value: SectionIgnoreParametersCascading.Replace,
                        label:
                          "Take only ignored parameters from this behavior",
                      },
                    ].map(({ value, label }) => (
                      <option
                        value={value}
                        selected={form.ignoreParametersCascading === value}
                      >
                        {label}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Text>
                    Main Behavior parameters :{" "}
                    {fallbackSection.ignoreParameters}
                  </Form.Text>
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>
                  Strategy when delivering a page with ignored parameters
                </Form.Label>

                <SectionField
                  form={form}
                  setForm={setValues}
                  isFallbackSection={isFallbackSection}
                  fallbackSection={fallbackSection}
                  field="ignoreParametersStrategy"
                  formElement={
                    <>
                      {Object.values(SectionIgnoreParametersStrategy).map(
                        (value) => (
                          <Form.Check
                            type="radio"
                            name="ignoreParametersStrategy"
                            label={getIgnoreParametersStrategyLabel(value)}
                            value={value}
                            checked={form.ignoreParametersStrategy === value}
                            onChange={updateField}
                          />
                        )
                      )}
                    </>
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Remove ignored parameters on links, canonicals and hreflang
                  found on any page
                </Form.Label>

                <SectionField
                  form={form}
                  setForm={setValues}
                  isFallbackSection={isFallbackSection}
                  fallbackSection={fallbackSection}
                  field="ignoreParametersReplaceLinks"
                  formElement={
                    <>
                      <Form.Check
                        type="radio"
                        label="Yes"
                        name="ignoreParametersReplaceLinks"
                        checked={form.ignoreParametersReplaceLinks === true}
                        value={`${true}`}
                        onChange={updateBoolField}
                      />
                      <Form.Check
                        type="radio"
                        label="No"
                        name="ignoreParametersReplaceLinks"
                        checked={form.ignoreParametersReplaceLinks === false}
                        value={`${false}`}
                        onChange={updateBoolField}
                      />
                    </>
                  }
                />

                <Form.Text className="text-muted">
                  This will improve crawl budget by reducing the number of
                  duplicated URLs found by search engines during their crawl.
                </Form.Text>
              </Form.Group>
            </Card.Body>
          </Card>
        )}

        {[SectionMode.Allow, SectionMode.CustomHtml].includes(form.mode) &&
          formMode === "update" && (
            <BehaviorScripts
              loading={loadingScripts}
              scripts={sectionScripts}
            />
          )}

        {form.mode === SectionMode.Allow && (
          <Card>
            <Card.Header>Custom Refresh Rules</Card.Header>
            <Card.Body>
              <CustomRefreshRulesComponent
                data={JSON.parse(form.customRefreshRules)}
                setData={(data) =>
                  updateEntry("customRefreshRules", JSON.stringify(data))
                }
                defaults={{
                  maxStale: getFinalValue({
                    field: "maxStale",
                    form,
                    fallbackSection,
                    isFallbackSection,
                  }),
                  refreshFrequency: getFinalValue({
                    field: "refreshFrequency",
                    form,
                    fallbackSection,
                    isFallbackSection,
                  }),
                  priority: getFinalValue({
                    field: "refreshPriority",
                    form,
                    fallbackSection,
                    isFallbackSection,
                  }),
                }}
                inputs={draftVersion.inputs ?? []}
              />
            </Card.Body>
          </Card>
        )}

        <Card>
          <Card.Header>Scoping Rules</Card.Header>
          <Card.Body>
            <ErrorRegExAlert {...regExRuleErrors} />
            <QueryBuilder
              fields={FIELDS}
              operators={OPERATORS}
              onQueryChange={updateRules}
              query={rules ?? undefined}
              controlElements={{
                // Prevent adding nested groups. Only 1 root group is supported.
                addGroupAction: () => null,
              }}
            />
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Testing rules</Card.Header>
          <Card.Body>
            <UrlTester websiteId={websiteId} section={form} />
          </Card.Body>
        </Card>

        <Container>
          <Col>
            <Button type="submit" className="float-right">
              Submit
            </Button>
          </Col>
        </Container>
      </Form>
    </div>
  );
}
