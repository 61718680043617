import { gql } from "@apollo/client";
import { WEBSITE_SCRIPT_FRAGMENT } from "./websiteScriptFragment.gql";

export const GET_WEBSITE_SCRIPTS = gql`
  ${WEBSITE_SCRIPT_FRAGMENT}
  query WebsiteScripts($websiteId: String!) {
    website(id: $websiteId) {
      id
      name
      draftVersion {
        id
        jsCodes {
          ...WebsiteScriptFragment
        }
        sections {
          id
          stableId
          name
          mode
        }
      }
    }

    liveupdate {
      configurations(websiteId: $websiteId) {
        stableId
        friendlyName
      }
    }
  }
`;
