import { useState } from "react";

import { Button, ButtonGroup } from "react-bootstrap";
import {
  Context,
  Editor,
  EditorProps,
  OnValidate,
} from "../../../components/Editor";
import {
  JsCodeStep,
  LiveUpdateConfigurationType,
} from "../../../gql/types.generated";
import { getVisibleSteps, VisibleStep } from "../core/steps";
import { createLiveUpdateSnippet } from "./snippets";

type ScriptEditorProps = {
  codeByStep: CodeByStep;
  onChange: (codeByStep: CodeByStep) => void;
  onValidate?: (step: VisibleStep, validations: CodeValidation[]) => void;
  luConfigs: Pick<LiveUpdateConfigurationType, "stableId" | "friendlyName">[];
};

export type CodeValidation = {
  step: VisibleStep;
  line: number | undefined;
  text: string;
};

export type CodeByStep = Record<VisibleStep, string>;

export const ScriptEditor = ({
  codeByStep,
  onChange,
  onValidate,
  luConfigs,
}: ScriptEditorProps) => {
  const [activeStep, setActiveStep] = useState<VisibleStep>(JsCodeStep.Init);
  const context: Context =
    activeStep === JsCodeStep.PreBeamResponse ? "delivery" : "rendering";

  const stepCode = codeByStep[activeStep];

  const handleActiveStepCodeChange = (value: string) =>
    onChange({ ...codeByStep, [activeStep]: value });

  const handleActiveStepValidation: OnValidate = (markers) =>
    onValidate?.(
      activeStep,
      markers
        .filter(({ severity }) => severity >= 8)
        .map((m) => ({
          step: activeStep,
          line: m.startLineNumber,
          text: m.message,
        }))
    );

  const actions = luConfigs.map(({ stableId, friendlyName }) => ({
    id: `implement-lu-${stableId}`,
    label: `Live Update: create implementation for ${stableId} (${friendlyName})`,
    run: (editor) => {
      setActiveStep(JsCodeStep.PreBeamResponse);
      onChange({
        ...codeByStep,
        [JsCodeStep.PreBeamResponse]: createLiveUpdateSnippet(stableId).concat(
          editor.getValue()
        ),
      });
    },
  })) satisfies EditorProps["actions"];

  return (
    <div>
      <StepSelectionTabs
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <Editor
        path={`${activeStep}.js`}
        language="javascript"
        context={context}
        value={stepCode}
        onChange={handleActiveStepCodeChange}
        onValidate={handleActiveStepValidation}
        actions={actions}
        height="800px"
      />
    </div>
  );
};

type StepSelectionTabsProps = {
  activeStep: VisibleStep;
  setActiveStep: (step: VisibleStep) => void;
};

const StepSelectionTabs = ({
  activeStep,
  setActiveStep,
}: StepSelectionTabsProps) => (
  <ButtonGroup className="mb-4">
    {getVisibleSteps().map(({ id, executionContext, label }) => (
      <Button
        key={id}
        variant={id === activeStep ? "primary" : "secondary"}
        onClick={() => setActiveStep(id)}
      >
        <span className="small">{executionContext}</span>
        <br />
        {label}
      </Button>
    ))}
  </ButtonGroup>
);
